import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { DialogService } from '../dialog/dialog.service';
import { SnackbarService } from '../snackbar/snackbar.service';
import { OriginalObservable } from '../../model/api-call-extension.model';
import { catchError, delay, tap, finalize, mergeMap } from 'rxjs/operators';
import { PROGRESS_DIALOG_OFFSET_MS } from '../../constants/dialog.constants';

@Injectable({
  providedIn: 'root',
})
export class ApiCallExtensionService<T = unknown> {
  constructor(
    private dialogService: DialogService,
    private snackbarService: SnackbarService
  ) {}

  extendApiCall(
    originalObservable: OriginalObservable<T>,
    dialogMainTextI18n: string
  ): Observable<T> {
    return of(null).pipe(
      tap(() => {
        this.dialogService.openProgressDialog({
          mainText: dialogMainTextI18n,
        });
      }),
      delay(PROGRESS_DIALOG_OFFSET_MS),
      mergeMap(() =>
        originalObservable().pipe(
          tap({
            next: () => {
              this.showApiCallResultFeedback(true);
            },
            error: (error: unknown) => {
              this.showApiCallResultFeedback(false);
              console.error('extendApiCall error', error);
            },
          })
        )
      )
    );
  }

  private showApiCallResultFeedback(isOperationSuccessul: boolean): void {
    this.dialogService.closeProgressDialog();
    this.showSnackbar(isOperationSuccessul);
  }

  private showSnackbar(isOperationSuccessul: boolean): void {
    this.snackbarService.openSnackbar(
      isOperationSuccessul
        ? this.snackbarService.getSuccessSnackbarData()
        : this.snackbarService.getErrorSnackbarData()
    );
  }
}
