import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { OtherPagesService } from '../../services/orther-pages/other-pages.service';

@Component({
  selector: 'app-weather',
  templateUrl: './weather.component.html',
  styleUrls: ['./weather.component.scss'],
})
export class WeatherComponent implements OnInit {
  private readonly EVENT_ID = parseInt(localStorage.getItem('eventId'), 10);
  formGroup: FormGroup;
  data: any;
  loading = false;

  constructor(
    private formBuilder: FormBuilder,
    private otherPagesService: OtherPagesService,
    private router: Router
  ) {
    this.formGroup = this.formBuilder.group({
      title: [''],
      raceTitle: [''],
      image: [''],
    });
  }

  ngOnInit(): void {}

  navigate(): void {
    this.router.navigate(['other-pages', 'list']);
  }

  deleteImage(): void {}

  save(): void {
    this.otherPagesService
      .uploadWeather(this.EVENT_ID, this.formGroup.value)
      .subscribe((weather) => {
        this.navigate();
      });
  }
}
