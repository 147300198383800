import { Component, OnInit, HostListener } from '@angular/core';
import { IType } from 'src/app/news/model/type.model';
import { filterData } from 'src/app/shared/utils/filter.util';
import { ProgramService } from '../../services/program/program.service';
import { TypesService } from '../../services/types/types.service';
import { IProgram, IProgramRaceEvent } from '../../model/program.model';
import { ActivatedRoute, Router } from '@angular/router';
import { FormGroup, FormBuilder } from '@angular/forms';
import { BreakpointObserver } from '@angular/cdk/layout';
import { IPermisson } from 'src/app/api/model/permisson.model';
import { PermissonService } from 'src/app/api/service/permission/permisson.service';
import { LanguageService } from 'src/app/shared/services/language/language.service';

@Component({
  templateUrl: './programs.page.html',
  styleUrls: ['./programs.page.scss'],
})
export class ProgramsPage implements OnInit {
  selectedValue: string;
  pageWidth: number;
  isSmall = false;
  types: IType[] = [];
  program: IProgram;
  data: IProgramRaceEvent[];
  formGroup: FormGroup;
  searchValue = '';
  scheduleType: string;

  readonly columns = ['title', 'time', 'date', 'type', 'edit', 'delete'];
  private readonly EVENT_ID = parseInt(localStorage.getItem('eventId'), 10);

  constructor(
    private programService: ProgramService,
    private typesService: TypesService,
    private router: Router,
    private formBuilder: FormBuilder,
    private activatedRoute: ActivatedRoute,
    private breakpointObserver: BreakpointObserver,
    private permissonService: PermissonService,
    private languageService: LanguageService
  ) {
    this.formGroup = this.formBuilder.group({
      programDate: [''],
    });

    this.formGroup.get('programDate').valueChanges.subscribe((date) => {
      this.searchProgram();
    });
  }

  onSearch(searchInput: string): void {
    this.searchValue = searchInput;
    this.searchProgram();
  }

  @HostListener('window:resize', ['$event'])
  onResize(): void {
    this.isSmall = this.breakpointObserver.isMatched('(max-width: 1400px)');
  }

  searchProgram(): void {
    const searchInput = this.searchValue;
    const activeLanguageCode = this.languageService.getActiveLanguage();
    const date = this.formGroup.get('programDate').value;
    if (searchInput || date) {
      this.programService
        .transformedProgramList(
          this.EVENT_ID,
          this.scheduleType,
          activeLanguageCode
        )
        .subscribe((list) => {
          this.data = filterData(list, 'title', searchInput, date);
        });
    } else {
      this.initProgram();
    }
  }

  deleteProgram(event): void {
    if (event) {
      this.programService
        .deleteProgram([event.id], event.type)
        .subscribe(() => {
          this.initProgram();
        });
    }
  }

  createProgram(typeId: number): void {
    const navigationExtras = {
      queryParams: {
        type: typeId,
        scheduleType: this.scheduleType,
      },
    };
    this.router.navigate(['program', 'detail', 0], navigationExtras);
  }

  hasPermission(): boolean {
    const permision: IPermisson = {
      part: 'program',
      type: 2,
      action: 'create',
    };
    return this.permissonService.hasPermission(permision);
  }

  ngOnInit(): void {
    this.initProgram();
    this.isSmall = this.breakpointObserver.isMatched('(max-width: 1400px)');
    this.types = this.typesService.get();
  }

  initProgram(): void {
    const activeLanguageCode = this.languageService.getActiveLanguage();
    this.scheduleType = this.activatedRoute.snapshot.paramMap.get('type');
    this.programService
      .transformedProgramList(
        this.EVENT_ID,
        this.scheduleType,
        activeLanguageCode
      )
      .subscribe((program) => {
        this.data = program;
      });
  }
}
