import {
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
  AfterViewInit,
  ChangeDetectorRef,
  HostListener,
} from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthorizationService } from '../../service/authorization/authorization.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild('loginInputElement') loginInput: ElementRef;

  // tslint:disable-next-line: quotemark
  readonly backgroungImagePath = "url('assets/image/login.jpg')";
  formGroup: FormGroup;
  error = false;
  private subs = new Subscription();

  isLoginButtonDisabled(): boolean {
    return this.formGroup.valid;
  }

  constructor(
    private formBuilder: FormBuilder,
    private authorizationService: AuthorizationService,
    private router: Router,
    private changeDetector: ChangeDetectorRef
  ) {
    this.formGroup = this.formBuilder.group({
      username: ['', [Validators.required]],
      password: ['', [Validators.required]],
    });
  }

  ngOnInit(): void {}

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  ngAfterViewInit(): void {
    this.loginInput.nativeElement.focus();
    this.changeDetector.detectChanges();
  }

  @HostListener('keydown.enter')
  onPressEnter(): void {
    if (this.formGroup.valid) {
      this.onLogin();
    }
  }

  onLogin(): void {
    if (this.formGroup.valid) {
      const loginValues = this.formGroup.value;

      this.subs.add(
        this.authorizationService
          .login({
            username: loginValues?.username,
            password: loginValues?.password,
          })
          .subscribe({
            next: () => {
              this.router.navigateByUrl('/');
            },
            error: () => {
              this.error = true;
            },
          })
      );
    }
  }
}
