<div class="app-game-body-wrapper">
  <div
    class="app-game-body flex justify-center items-center"
    [ngStyle]="{ backgroundImage: backgroungImagePath }"
  >
    <div *ngIf="settings.start">
      <div class="flex justify-center mb4">
        <h1 class="app-game-text">{{ "GAME.NEW" | translate }}</h1>
      </div>
      <div class="flex justify-center">
        <img src="../../assets/image/gameSports.svg" />
      </div>
    </div>

    <div *ngIf="!settings.start && game">
      <div class="flex justify-center mb4">
        <h1 class="app-game-text">{{ game.name }}</h1>
      </div>

      <div *ngIf="!settings.answer" class="flex justify-center">
        <img src="../../assets/image/question.svg" />
      </div>

      <div class="flex justify-center">
        <img *ngIf="settings.answer" [src]="getAnswerImage()" />
      </div>
    </div>
  </div>
  <div class="app-game-body-footer mr4 ml4">
    <div class="flex justify-between items-center app-game-body-row">
      <div>
        <div
          *ngIf="!settings.start"
          class="app-cancel-icon"
          (click)="cancelGame()"
        >
          <mat-icon class="mr1">cancel</mat-icon>
          {{ "GAME.BUTTON.CANCEL" | translate }}
        </div>
      </div>
      <div>
        <button class="app-game-button" mat-flat-button (click)="change()">
          <div class="app-game-button-content flex items-center">
            <mat-icon class="app-game-icon">keyboard_arrow_right</mat-icon>
          </div>
        </button>
      </div>
    </div>
  </div>
</div>
