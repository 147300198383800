import { Component, OnInit } from '@angular/core';
import { NewsService } from '../../services/news/news.service';
import { IType } from 'src/app/api/model/news-type.interface';
import { TypesService } from '../../services/types/types.service';
import { filterDataBySearchInput } from 'src/app/shared/utils/filter.util';
import { Router } from '@angular/router';

@Component({
  selector: 'app-news-list',
  templateUrl: './news-list.component.html',
  styleUrls: ['./news-list.component.scss'],
})
export class NewsListComponent implements OnInit {
  types: IType[] = [];
  selected: string | number;
  data = [];
  readonly columns = ['title', 'type', 'date', 'delete'];

  constructor(
    private newsService: NewsService,
    private typesService: TypesService,
    private router: Router
  ) {}

  onTypeChange(type): void {
    if (type.value === 0) {
      this.initData();
    } else {
      this.newsService.newsList().subscribe((list) => {
        this.data = list.filter((news) => news.type === type.value);
      });
    }
  }

  onSearch(searchInput): void {
    if (searchInput) {
      this.newsService.newsList().subscribe((list) => {
        this.data = filterDataBySearchInput(list, 'title', searchInput);
      });
    } else {
      this.initData();
    }
  }

  ngOnInit(): void {
    this.initData();
    this.types = this.typesService.get();
    this.selected = this.types[0].id;
  }

  initData(): void {
    this.newsService.newsList().subscribe((list) => {
      this.data = list;
    });
  }
}
