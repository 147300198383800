import { ILanguages } from 'src/app/api/model/languages.model';

export interface IProgramType {
  id: number;
  value: string;
}

export enum Program {
  USER = 'program',
  ORGANIZER = 'schedule',
  event = 'schedule_event',
  race = 'schedule_race',
  EVENT_SCHEDULE = 'eventSchedule',
  RACE_SCHEDULE = 'raceSchedule',
}

export interface IProgram {
  raceSchedule: IProgramRacePost[];
  eventSchedule: IProgramEventPost[];
}

export interface IProgramRaceEvent {
  id: number;
  title: string;
  time: string;
  date: string;
  type: number;
}

export interface IProgramEventPost {
  about: ILanguages;
  active: boolean;
  dateTimeFrom: Date;
  dateTimeTo: Date;
  eventId: number;
  fbLink: ILanguages;
  mapsLink: ILanguages;
  igLink: ILanguages;
  stravaLink: ILanguages;
  importantInfoUrl: ILanguages;
  id: number;
  inserted: Date;
  place: ILanguages;
  sponsorImg: ILanguages;
  title: ILanguages;
  updated: Date;
  type: string;
}

export interface IProgramEventPostTranslate {
  about: string;
  active: boolean;
  dateTimeFrom: Date;
  dateTimeTo: Date;
  eventId: number;
  fbLink: string;
  importantInfoUrl: string;
  id: number;
  inserted: Date;
  place: string;
  sponsorImg: string;
  title: string;
  updated: Date;
  gpsLat: number;
  gpsLon: number;
  type: string;
}

export interface IProgramEventPostTypeId extends IProgramEventPost {
  id: number;
}

export interface IProgramRacePost {
  active: boolean;
  dateTimeFrom: Date;
  dateTimeTo: Date;
  eventId: number;
  fbLink: ILanguages;
  mapsLink: ILanguages;
  igLink: ILanguages;
  stravaLink: ILanguages;
  heightProfileImg: ILanguages;
  id: number;
  inserted: Date;
  mapImg: ILanguages;
  place: ILanguages;
  sponsorImg: ILanguages;
  startNumEmission: ILanguages;
  startType: ILanguages;
  startWaves: ILanguages;
  technique: ILanguages;
  title: ILanguages;
  updated: Date;
  raceCode: ILanguages;
  type: string;
  urlInfoNumRel: ILanguages;
  importantInfoUrl: ILanguages;
}

export interface IProgramRacePostTranslate {
  active: boolean;
  dateTimeFrom: Date;
  dateTimeTo: Date;
  eventId: number;
  fbLink: string;
  heightProfileImg: string;
  id: number;
  inserted: Date;
  mapImg: string;
  place: string;
  sponsorImg: string;
  startNumEmission: string;
  startType: string;
  startWaves: string;
  technique: string;
  title: string;
  updated: Date;
  raceCode: string;
  gpsLat: number;
  gpsLon: number;
  type: string;
  urlInfoNumRel: string;
  importantInfoUrl: string;
}

export interface IImageGallery {
  id: number;
  image: string;
  inserted: Date;
  orderBy: number;
  type: string;
  updated: Date;
}

export interface IProgramRacePostTypeId extends IProgramRacePost {
  id: number;
}

export interface IRaceCode {
  title: string;
  type: string;
  topic: string;
}

export interface IType {
  id: number;
  value: string;
}

export interface IImage {
  name: string;
  url: string;
}

// tslint:disable-next-line: no-empty-interface
export interface ITabs extends IType {}
