import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-dialog-question',
  templateUrl: './dialog-question.component.html',
  styleUrls: ['./dialog-question.component.scss'],
})
export class DialogQuestionComponent implements OnInit {
  @Input() question: string;
  @Input() eventTitle = '';
  constructor() {}

  ngOnInit(): void {}
}
