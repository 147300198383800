import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { ImagesService } from 'src/app/api/service/images/images.service';
import { FileExtension } from '../../utils/validation.util';
import { TranslateService } from '@ngx-translate/core';
import { marker } from '@biesbjerg/ngx-translate-extract-marker';
import { SnackbarService } from '../../services/snackbar/snackbar.service';
import {
  SnackbarHorizontalPosition,
  SnackbarVerticalPosition,
} from '../../model/snackbar.model';

@Component({
  selector: 'app-image-input',
  templateUrl: './image-input.component.html',
  styleUrls: ['./image-input.component.scss'],
})
export class ImageInputComponent implements OnInit {
  @Input() title: string;
  @Input() width = 24;
  @Input() height = 24;
  @Input() type = 'map';
  @Input() control: FormControl;
  added = new FormControl('', FileExtension(['jpg', 'png']));

  @Output() delete = new EventEmitter<void>();
  image: string | ArrayBuffer;

  @ViewChild('FileSelectInputDialog') FileSelectInputDialog: ElementRef;

  constructor(
    private imagesService: ImagesService,
    private translate: TranslateService,
    private snackbarService: SnackbarService
  ) {}

  openAddFilesDialog(): void {
    this.FileSelectInputDialog.nativeElement.click();
  }

  getValue(): any {
    return this.getFullImagePath(this.control.value);
  }

  openInvalidFileSnackbar(): void {
    this.snackbarService.openSnackbar(
      {
        success: false,
        icon: 'close',
        mainText: this.translate.instant(marker('CORE.ERROR.IMAGE.TITLE')),
      },
      SnackbarVerticalPosition.Bottom,
      SnackbarHorizontalPosition.Center
    );
  }

  onChange(event: Event): void {
    const file = (event.target as HTMLInputElement).files[0];
    if (!file) {
      return;
    }

    this.added.setValue([file]);
    if (this.added.invalid) {
      this.openInvalidFileSnackbar();
      this.added.setValue('');
      return;
    }

    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      const img = new Image();
      img.src = reader.result as string;
      img.onload = () => {
        this.image = reader.result;
        this.control.setValue(file);
      };
    };
  }

  getFullImagePath(path: string): string {
    return this.imagesService.getImageUrl(path);
  }

  getIcon(): string {
    return this.control.value ? 'delete' : 'add';
  }

  deleteItem(): void {
    this.control.setValue(undefined);
  }

  ngOnInit(): void {
    this.image = this.getFullImagePath(this.control.value);
  }
}
