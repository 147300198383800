import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormArray, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-basic-form-content',
  templateUrl: './basic-form-content.component.html',
  styleUrls: ['./basic-form-content.component.scss'],
})
export class BasicFormContentComponent implements OnInit {
  @Input() formGroup: FormGroup;
  @Output() addGalleryImage = new EventEmitter<void>();
  @Output() deleteGalleryImage = new EventEmitter<number>();

  get galleryFormArray(): FormArray {
    return this.formGroup.get('basicContentGallery') as FormArray;
  }

  constructor() {}

  ngOnInit(): void {}
}
