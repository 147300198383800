<div *ngIf="data">
  <app-back-panel
    [title]="'OTHER-PAGES.PAGES.BACK' | translate"
  ></app-back-panel>
  <app-list-top-card [title]="'OTHER-PAGES.WAXES.TITLE' | translate">
    <app-search-box
      [label]="'OTHER-PAGES.FILTERS.SEARCH' | translate"
      [value]="searchValue"
      (search)="onSearch($event)"
    ></app-search-box>
  </app-list-top-card>

  <app-dynamic-table
    (changeVisibility)="changeVisibilityState($event)"
    [isReadOnly]="true"
    [data]="data"
    [type]="'waxes'"
    [columns]="columns"
    [preferences]="preferences"
  ></app-dynamic-table>
</div>
<app-spinner *ngIf="!data"></app-spinner>
