import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { forkJoin, Observable, of, race } from 'rxjs';
import {
  INotification,
  INotificationRoute,
  INotificationScreenPaths,
  NotificationRoute,
} from '../../model/notification/notification.model';
import { environment } from 'src/environments/environment';
import { defaultIfEmpty, map } from 'rxjs/operators';
import { ProgramService } from 'src/app/program/services/program/program.service';
import { marker } from '@biesbjerg/ngx-translate-extract-marker';
import { TranslateService } from '@ngx-translate/core';
import { EventsService } from 'src/app/api/service/events/events.service';

@Injectable({
  providedIn: 'root',
})
export class NotificationsService {
  private readonly basePath = environment.apiUrl;

  constructor(
    private httpClient: HttpClient,
    private programService: ProgramService,
    private eventsService: EventsService,
    private translate: TranslateService
  ) {}

  createOrUpdateNotification(body: INotification): Observable<INotification> {
    return this.httpClient.post<INotification>(
      `${this.basePath}/notification`,
      body
    );
  }

  deleteNotification(notificationIds: number[]): Observable<void> {
    return this.httpClient.delete<void>(`${this.basePath}/notification`, {
      params: {
        ids: notificationIds.map((id) => id.toString()),
      },
    });
  }

  getNotificationsDetailWithRaceCodes(
    notificationId: number,
    activeLanguageCode: string
  ): Observable<any[]> {
    return forkJoin([
      this.notificationDetail(notificationId, activeLanguageCode),
      this.eventsService.getTopics(),
    ]).pipe(defaultIfEmpty([]));
  }

  getNotificationList(
    activeLanguageCode: string,
    future = true
  ): Observable<INotification[]> {
    return this.httpClient.get<INotification[]>(
      `${this.basePath}/notifications/${activeLanguageCode}`,
      {
        params: {
          futureOnly: future.toString(),
        },
      }
    );
  }

  getScreenPath(
    eventId: number,
    raceCode: string
  ): Observable<INotificationScreenPaths[]> {
    return raceCode.startsWith('org_')
      ? this.getOrganizerscreenPaths(eventId, raceCode)
      : this.getNotificationscreenPaths(eventId, raceCode);
  }

  getNotificationscreenPaths(
    eventId: number,
    raceCode: string
  ): Observable<INotificationScreenPaths[]> {
    return this.httpClient.get<INotificationScreenPaths[]>(
      `${this.basePath}/screenPaths`,
      {
        params: {
          eventId: eventId.toString(),
          raceCode,
        },
      }
    );
  }

  getOrganizerscreenPaths(
    eventId: number,
    raceCode: string
  ): Observable<INotificationScreenPaths[]> {
    return this.httpClient.get<INotificationScreenPaths[]>(
      `${this.basePath}/organizerScreenPaths`,
      {
        params: {
          eventId: eventId.toString(),
          raceCode,
        },
      }
    );
  }

  getNotificationRoute(): INotificationRoute[] {
    const notificationRoute: INotificationRoute[] = [
      {
        flag: NotificationRoute.basic,
        translation: this.translate.instant(
          marker('NOTIFICATION-ROUTES.BASIC')
        ),
      },
      {
        flag: NotificationRoute.eventDetail,
        translation: this.translate.instant(
          marker('NOTIFICATION-ROUTES.EVENT-DETAIL')
        ),
      },
      {
        flag: NotificationRoute.game,
        translation: this.translate.instant(marker('NOTIFICATION-ROUTES.GAME')),
      },
      {
        flag: NotificationRoute.informations,
        translation: this.translate.instant(
          marker('NOTIFICATION-ROUTES.INFORMATIONS')
        ),
      },
      {
        flag: NotificationRoute.registration,
        translation: this.translate.instant(
          marker('NOTIFICATION-ROUTES.REGISTRATION')
        ),
      },
      {
        flag: NotificationRoute.results,
        translation: this.translate.instant(
          marker('NOTIFICATION-ROUTES.RESULTS')
        ),
      },
      {
        flag: NotificationRoute.sponsor,
        translation: this.translate.instant(
          marker('NOTIFICATION-ROUTES.SPONSOR')
        ),
      },
      {
        flag: NotificationRoute.waxes,
        translation: this.translate.instant(
          marker('NOTIFICATION-ROUTES.WAXES')
        ),
      },
    ];

    return notificationRoute;
  }

  notificationDetail(
    notificationId: number,
    activeLanguageCode: string
  ): Observable<INotification> {
    return this.getNotificationList(activeLanguageCode).pipe(
      map((notifications) => {
        return notifications.find(
          (notification) => notification.id === notificationId
        );
      })
    );
  }
}
