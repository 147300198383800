import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ProgramDetailComponent } from '../program/components/program-detail/program-detail.component';
import { ProgramsPage } from '../program/pages/programs/programs.page';

const routes: Routes = [
  {
    path: 'list/:type',
    pathMatch: 'full',
    component: ProgramsPage,
  },
  {
    path: 'detail/:programId',
    pathMatch: 'full',
    component: ProgramDetailComponent,
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class ScheduleRoutingModule {}
