import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ProgramDetailComponent } from './components/program-detail/program-detail.component';
import { ProgramListPage } from './pages/program-list/program-list.page';
import { CanDeactiveGuard } from 'src/app/core/guard/can-deactive.guard';

const ROUTES: Routes = [
  {
    path: 'list/:type',
    pathMatch: 'full',
    component: ProgramListPage,
  },
  {
    path: 'detail/:programId',
    pathMatch: 'full',
    component: ProgramDetailComponent,
    canDeactivate: [CanDeactiveGuard],
  },
];
@NgModule({
  imports: [RouterModule.forChild(ROUTES)],
  exports: [RouterModule],
})
export class ProgramsRoutingModule {}
