<app-dialog-layout [showClose]="data.showClose" [showActions]="true">
  <ng-container content>
    <div class="text-large bold mb3">
      {{ "SETTINGS.TITLE" | translate }}
    </div>

    <div class="flex flex-column mb3 mt2">
      <div class="text-medium bold mb2">
        {{ "SETTINGS.ORDER_EVENTS" | translate }}
      </div>

      <div>
        <app-sorting-settings
          [events]="events"
          (eventsChange)="onEventsChange($event)"
          (visibilityChange)="onVisibilityChange($event)"
        ></app-sorting-settings>
      </div>
    </div>

    <div class="mb3">
      <div class="text-medium bold mb2">
        {{ "SETTINGS.UPDATE_APP.TITLE" | translate }}
      </div>

      <div class="flex justify-between items-center">
        <div class="mb2">
          {{
            "SETTINGS.UPDATE_APP.INFO"
              | translate : { version: mobileAppVerion }
          }}
        </div>

        <div>
          <mat-slide-toggle
            (change)="onSetMobileAppVersion($event)"
            color="primary"
            [checked]="buildVerionResponse?.active"
            [disabled]="!buildVerionResponse?.buildVersion"
          >
          </mat-slide-toggle>
        </div>
      </div>
    </div>
  </ng-container>

  <ng-container actions>
    <app-prompt-buttons
      [cancelText]="'CORE.BUTTON.CANCEL' | translate"
      [confirmText]="'CORE.BUTTON.SUBMIT' | translate"
      (cancel)="onCloseDialog(true)"
      (confirm)="onCloseDialog()"
    >
    </app-prompt-buttons>
  </ng-container>
</app-dialog-layout>
