import { Injectable } from '@angular/core';
import { forkJoin, Observable } from 'rxjs';
import { defaultIfEmpty, map, mergeMap } from 'rxjs/operators';
import { ImagesService } from 'src/app/api/service/images/images.service';
import { IContactGroup } from 'src/app/api/model/important-contact.model';
import { OrganizerContactService } from 'src/app/api/services/organizer-contact/organizer-contact.service';
import { OrganizerPageService } from 'src/app/api/services/organizer-page/organizer-page.service';
import { IBasicFormContentInfo } from 'src/app/shared/model/basic-form-content-info.model';
import { EventService } from 'src/app/api/services/event/event.service';
import {
  ITranslatedImportantContact,
  ITranslatedContactGroup,
} from '../../model/important-contact-translate.model';
import { ImageType } from 'src/app/api/model/image.model';
import { OrganizerPage } from '../../model/organizer-page.model';

@Injectable({
  providedIn: 'root',
})
export class OrganizersService {
  constructor(
    private eventService: EventService,
    private imagesService: ImagesService,
    private organizerContactService: OrganizerContactService,
    private organizerPageService: OrganizerPageService
  ) {}

  saveImportantContact(
    eventId: number,
    contactGroups: IContactGroup[],
    ids: number[],
    activeLanguageCode: string
  ): Observable<any> {
    return forkJoin([
      this.organizerContactService.deleteImportantContacts(ids),
      this.organizerContactService.createOrUpdateImportantContacts(
        eventId,
        contactGroups,
        activeLanguageCode
      ),
    ]).pipe(defaultIfEmpty([]));
  }

  transformImportantContactsFromAPI(
    contacts: ITranslatedImportantContact[]
  ): ITranslatedContactGroup[] {
    const uniqueContactGroupNames = this.getUniqueContactGroupNames(contacts);

    return uniqueContactGroupNames.map((group) => {
      return {
        groupName: group,
        contacts: contacts.filter((contact) => contact.groupName === group),
      };
    });
  }

  getUniqueContactGroupNames(
    contacts: ITranslatedImportantContact[]
  ): string[] {
    return contacts.reduce<string[]>((uniqueGroups, contact) => {
      if (!uniqueGroups.includes(contact.groupName)) {
        return uniqueGroups.concat(contact.groupName);
      }
      return uniqueGroups;
    }, []);
  }

  getUniqueContactGroupsWithEmptyContacts(
    contacts: ITranslatedImportantContact[]
  ): ITranslatedContactGroup[] {
    return this.getUniqueContactGroupNames(contacts).map((group) => {
      return {
        groupName: group,
        contacts: [],
      };
    });
  }

  getBaseMap(eventId: number): Observable<any> {
    return this.eventService.getEventList().pipe(
      map((events) => {
        return {
          baseMap: events.find((event) => event.id === eventId)?.organizerMap,
        };
      })
    );
  }

  createOrUpdateInfo(
    eventId: number,
    info: IBasicFormContentInfo,
    infoType: OrganizerPage,
    galleryFiles: File[],
    deletedImagesId: number[],
    activeLanguageCode: string
  ): Observable<any> {
    return this.organizerPageService
      .createOrUpdatePage(eventId, info, infoType)
      .pipe(
        mergeMap((response) => {
          return forkJoin([
            this.imagesService.uploadImages(
              eventId,
              response.id,
              ImageType.OrganizersInfo,
              galleryFiles,
              activeLanguageCode
            ),
            this.imagesService.deleteImages(eventId, deletedImagesId),
          ]);
        })
      );
  }

  getOrganizersInfoByType(
    eventId: number,
    type: string
  ): Observable<IBasicFormContentInfo> {
    return this.organizerPageService.getPage(eventId).pipe(
      map((organizers) => {
        return organizers?.find((organizer) => organizer?.type === type);
      })
    );
  }
}
