import { dateFormat } from './dateFormatter.util';
import { deaccent } from './deaccent.util';

export function filterDataBySearchInput<T = unknown>(
  data: T[],
  property: string,
  searchInput: string
): T[] {
  return data.filter((result) => {
    const value = deaccent(result[property]).toLowerCase();
    return value.includes(deaccent(searchInput.toLowerCase()));
  });
}

export function filterDataByDate<T = unknown>(data: T[], date: Date): T[] {
  return data.filter((result) => {
    {
      if (result['date'] === dateFormat(date)) {
        return result;
      }
    }
  });
}

export function filterData<T = unknown>(
  data: T[],
  property: string,
  searchInput: string,
  date: Date
): T[] {
  let searchResult: T[];
  if (searchInput && !date) {
    searchResult = filterDataBySearchInput(data, property, searchInput);
  } else if (!searchInput && date) {
    searchResult = filterDataByDate(data, date);
  } else if (searchInput && date) {
    searchResult = filterDataBySearchInput(data, property, searchInput);
    searchResult = filterDataByDate(searchResult, date);
  }
  return searchResult;
}
