import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { IEvent } from 'src/app/api/model/events.model';
import { DialogComponent } from 'src/app/shared/component/dialogs/dialog/dialog.component';
import { ISettingsDialogData } from '../../model/settings.model';
import { IEventVisibility } from '../../model/event.model';
import { TranslateService } from '@ngx-translate/core';
import { Observable, Subscription, of } from 'rxjs';
import { ApiCallExtensionService } from 'src/app/shared/services/api-call-extension/api-call-extension.service';
import { PROGRESS_DIALOG_ACTION_SAVING } from 'src/app/shared/constants/dialog.constants';
import { AuthentificationService } from '../../../api/services/authentification/authentification.service';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { IBuildVerion } from 'src/app/api/model/authentification.model';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss'],
})
export class SettingsComponent implements OnInit, OnDestroy {
  events: IEvent[] = [];
  reorderedEvents: IEvent[] = [];
  private subs = new Subscription();
  buildVerionResponse: IBuildVerion | null;

  get mobileAppVerion(): number | string {
    return this.buildVerionResponse?.buildVersion ?? '';
  }

  constructor(
    public dialogRef: MatDialogRef<DialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ISettingsDialogData,
    private translate: TranslateService,
    private apiCallExtensionService: ApiCallExtensionService,
    private authentificationService: AuthentificationService
  ) {}

  ngOnInit(): void {
    this.events = [...this.data.events];

    this.subs.add(
      this.authentificationService.getBuildVersion().subscribe({
        next: (buildVerionResponse) => {
          this.buildVerionResponse = buildVerionResponse[0] ?? null;
        },
      })
    );
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  onCloseDialog(isCanceled = false): void {
    this.dialogRef.close(isCanceled ? null : this.events);
  }

  onEventsChange(reorderedEvents: IEvent[]): void {
    const updatedEvents = this.updateEventsVisibility(reorderedEvents);
    this.events = [...updatedEvents];
  }

  private updateEventsVisibility(reorderedEvents: IEvent[]): IEvent[] {
    return [...reorderedEvents].map((reorderedEvent) => {
      const foundEvent = this.events.find(
        (event) => event.id === reorderedEvent.id
      );

      return {
        ...reorderedEvent,
        showEvent: foundEvent.showEvent,
      };
    });
  }

  onSetMobileAppVersion(event: MatSlideToggleChange): void {
    this.subs.add(
      this.apiCallExtensionService
        .extendApiCall(
          () =>
            this.authentificationService.setActiveVersion({
              id: this.buildVerionResponse?.id,
              active: event.checked,
            }),
          this.translate.instant(PROGRESS_DIALOG_ACTION_SAVING)
        )
        .subscribe()
    );
  }

  onVisibilityChange(eventVisibility: IEventVisibility): void {
    this.events = [...this.events].map((event) => {
      if (event.id === eventVisibility.event.id) {
        return { ...event, showEvent: eventVisibility.isVisible };
      }
      return event;
    });
  }
}
