export interface ISnackbarData {
  icon: string;
  mainText?: string;
  success: boolean;
  classes?: string[];
}

export enum SnackbarVerticalPosition {
  Top = 'top',
  Bottom = 'bottom',
  Center = 'center',
}

export enum SnackbarHorizontalPosition {
  Right = 'right',
  Left = 'left',
  Center = 'center',
}
