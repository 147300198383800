import { ILink } from '../model/link.model';
import { marker } from '@biesbjerg/ngx-translate-extract-marker';
import { UserType } from '../model/user-type.model';

export const LINKS: ILink[] = [
  {
    name: marker('CORE.MAIN_MENU.BANNER'),
    url: '/general',
  },
  {
    name: marker('CORE.MAIN_MENU.PROGRAM'),
    url: `/program/list/${UserType.USER}`,
  },
  {
    name: marker('CORE.MAIN_MENU.ORTHER_PAGES'),
    url: '/other-pages',
  },
  {
    name: marker('CORE.MAIN_MENU.NOTIFICATIONS'),
    url: '/notifications',
  },
  {
    name: marker('CORE.MAIN_MENU.ORGANIZERS'),
    url: '/organizers',
  },
  {
    name: marker('CORE.MAIN_MENU.SCHEDULE'),
    url: `/schedule/list/${UserType.ORGANIZER}`,
  },
];
