import { Injectable } from '@angular/core';
import { marker } from '@biesbjerg/ngx-translate-extract-marker';
import { IType } from 'src/app/api/model/news-type.interface';

@Injectable({
  providedIn: 'root',
})
export class TypesService {
  private readonly types: IType[] = [
    {
      id: 0,
      value: marker('PROGRAM.TYPES.ALL'),
    },
    {
      id: 2,
      value: marker('PROGRAM.TYPES.RACE'),
    },
    {
      id: 1,
      value: marker('PROGRAM.TYPES.EVENT'),
    },
  ];

  constructor() {}

  get(): IType[] {
    return this.types;
  }
}
