import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { tap } from 'rxjs/operators';
import { IUser, ILoginProperties } from '../../model/auth.model';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AuthorizationService {
  private readonly basePath = environment.apiUrl;
  constructor(private httpClient: HttpClient) {}

  login(properties: ILoginProperties): Observable<IUser> {
    let params = new HttpParams();
    params = params.append('name', properties.username);
    params = params.append('password', properties.password);

    return this.httpClient
      .post<IUser>(`${this.basePath}/authentication/generateToken`, params)
      .pipe(
        tap((loggedUser) => {
          localStorage.setItem('token', loggedUser?.token);
          localStorage.setItem('role', loggedUser?.roles[0]);
        })
      );
  }
}
