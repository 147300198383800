import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { FormControl } from '@angular/forms';

import { IProgramImage } from 'src/app/api/model/image.model';
import { ImagesService } from 'src/app/api/service/images/images.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { marker } from '@biesbjerg/ngx-translate-extract-marker';
import { FileExtension } from '../../utils/validation.util';

@Component({
  selector: 'app-image-panel',
  templateUrl: './image-panel.component.html',
  styleUrls: ['./image-panel.component.scss'],
})
export class ImagePanelComponent implements OnInit {
  images: IProgramImage[];
  @Output() add = new EventEmitter<void>();
  @Output() delete = new EventEmitter<void>();
  @ViewChild('Input') Input: ElementRef;

  @Input() loadedImagesControl: FormControl;
  @Input() addedImagesControl: FormControl;
  added = new FormControl('', FileExtension(['jpg', 'png']));

  files: any[] = [];

  constructor(
    private imagesService: ImagesService,
    private matSnackBar: MatSnackBar,
    private translate: TranslateService
  ) {}

  openAddFilesDialog(): void {
    const e: HTMLElement = this.Input.nativeElement;
    e.click();
  }

  onDelete(image): void {
    this.images = this.images.filter((img) => img.image !== image.image);
    this.deleteAddedImage(image);
    this.deleteLoadedImages(image);
  }

  deleteLoadedImages(image): void {
    let loadedImg = this.loadedImagesControl.value;
    loadedImg = loadedImg.filter((img) => img.id !== image.id);
    this.loadedImagesControl.setValue(loadedImg);
  }

  deleteAddedImage(image): void {
    const addFiles = this.addedImagesControl.value;
    if (addFiles) {
      addFiles.map((file) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
          if (reader.result === image.image) {
            let values = this.addedImagesControl.value;
            values = values.filter((value) => value !== file) || [];
            this.addedImagesControl.setValue(values);
          }
        };
      });
    }
  }

  openSnackBar(message: string, action: string): void {
    this.matSnackBar.open(message, action, {
      duration: 2000,
    });
  }

  ngOnInit(): void {
    this.images = this.loadedImagesControl.value;
  }

  getImageUrl(image: IProgramImage): string {
    if (image?.id !== 0) {
      return this.imagesService.getImageUrl(image?.image);
    }
    return image?.image;
  }

  onChange(event): void {
    const file = event.target.files[0];
    this.added.setValue([file]);
    if (this.added.invalid || !this.isImageSizeValid(file)) {
      this.openSnackBar(
        this.translate.instant(marker('CORE.ERROR.IMAGE.TITLE')),
        this.translate.instant(marker('CORE.ERROR.IMAGE.ACTION'))
      );
      this.removeFromAddedImages(file);
      return;
    }
    this.files.push(file);
    this.addedImagesControl.setValue(this.files);
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      this.images.push({
        inserted: new Date(),
        updated: new Date(),
        id: 0,
        orderBy: 0,
        type: '',
        image: reader.result,
      });
    };
  }

  removeFromAddedImages(addedFile: File): void {
    this.files = this.files.filter((file) => file !== addedFile) || [];
    this.addedImagesControl.setValue(this.files);
  }

  isImageSizeValid(file: File): boolean {
    const MAX_IMAGE_SIZE_MB = 5;
    return file?.size / Math.pow(1000, 2) < MAX_IMAGE_SIZE_MB;
  }
}
