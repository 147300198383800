import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ProgramsRoutingModule } from './programs-routing.module';
import { ProgramListPage } from './pages/program-list/program-list.page';
import { SharedModule } from 'src/app/shared/shared.module';
import { SortingEventResultsDialogComponent } from './components/sorting-event-result-dialog/sorting-event-results-dialog.component';
import { SortingEventResultsComponent } from './components/sorting-event-results/sorting-event-results.component';
import { ProgramDetailComponent } from './components/program-detail/program-detail.component';
import { ProgramEventDetailComponent } from './components/program-event-detail/program-event-detail.component';
import { ProgramRaceDetailComponent } from './components/program-race-detail/program-race-detail.component';
import { ProgramListTableComponent } from './components/program-list-table/program-list-table.component';

@NgModule({
  declarations: [
    ProgramListPage,
    SortingEventResultsDialogComponent,
    SortingEventResultsComponent,
    ProgramDetailComponent,
    ProgramEventDetailComponent,
    ProgramRaceDetailComponent,
    ProgramListTableComponent,
  ],
  imports: [CommonModule, ProgramsRoutingModule, SharedModule],
})
export class ProgramsModule {}
