import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { IBasicFormContentInfo } from '../../../shared/model/basic-form-content-info.model';

@Injectable({
  providedIn: 'root',
})
export class OrganizerPageService {
  private readonly basePath = environment.apiUrl;

  constructor(private httpClient: HttpClient) {}

  getPage(eventId: number): Observable<IBasicFormContentInfo[]> {
    return this.httpClient.get<IBasicFormContentInfo[]>(
      `${this.basePath}/event/${eventId}/organizerPages`
    );
  }

  createOrUpdatePage(
    eventId: number,
    info: IBasicFormContentInfo,
    infoType: string
  ): Observable<IBasicFormContentInfo> {
    return this.httpClient.post<IBasicFormContentInfo>(
      `${this.basePath}/event/${eventId}/organizerPage`,
      info,
      {
        params: {
          type: infoType.toUpperCase(),
        },
      }
    );
  }
}
