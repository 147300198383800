import { Pipe, PipeTransform } from '@angular/core';
import { SUPPORTED_LANGUAGES_CODES } from 'src/app/shared/constants/supported-languages.constant';

@Pipe({
  name: 'getLanguageIcon',
})
export class GetLanguageIconPipe implements PipeTransform {
  transform(languageCode: string): string {
    return `flag-icon flag-icon-${SUPPORTED_LANGUAGES_CODES[languageCode]}`;
  }
}
