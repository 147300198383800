<div *ngIf="programEventAttributesGroup">
  <div class="text-medium bold mb2">
    {{ "PROGRAM_EVENT_ATTRIBUTES.TITLE" | translate }}
  </div>

  <div [formGroup]="programEventAttributesGroup">
    <div class="mb3 flex fit justify-between">
      <mat-form-field appearance="fill" class="app-detail-race-form-field">
        <mat-label>{{ "CORE.FACEBOOK.LINK" | translate }}</mat-label>

        <input formControlName="fbLink" matInput />
      </mat-form-field>

      <mat-form-field appearance="fill" class="app-detail-race-form-field">
        <mat-label>{{ "CORE.INSTAGRAM.LINK" | translate }}</mat-label>

        <input formControlName="igLink" matInput />
      </mat-form-field>
    </div>

    <div class="mb3 flex fit justify-between">
      <mat-form-field appearance="fill" class="app-detail-race-form-field">
        <mat-label>{{ "CORE.MAPS.LINK" | translate }}</mat-label>

        <input formControlName="mapsLink" matInput />
      </mat-form-field>

      <mat-form-field appearance="fill" class="app-detail-race-form-field">
        <mat-label>{{ "CORE.DIET.LINK" | translate }}</mat-label>

        <input formControlName="stravaLink" matInput />
      </mat-form-field>
    </div>

    <div class="mb3 flex fit justify-between">
      <mat-form-field appearance="fill">
        <mat-label>{{ "CORE.DATE_FROM" | translate }}</mat-label>

        <input
          matInput
          [matDatepicker]="picker"
          formControlName="dateTimeFrom"
        />

        <mat-datepicker-toggle matSuffix [for]="picker">
          <mat-icon matDatepickerToggleIcon>date_range</mat-icon>
        </mat-datepicker-toggle>

        <mat-datepicker #picker></mat-datepicker>
      </mat-form-field>

      <mat-form-field appearance="fill">
        <mat-label>{{ "CORE.DATE_TO" | translate }}</mat-label>

        <input
          matInput
          [matDatepicker]="pickerTo"
          formControlName="dateTimeTo"
        />

        <mat-datepicker-toggle matSuffix [for]="pickerTo">
          <mat-icon matDatepickerToggleIcon>date_range</mat-icon>
        </mat-datepicker-toggle>

        <mat-datepicker #pickerTo></mat-datepicker>
      </mat-form-field>
    </div>
  </div>
</div>
