<div *ngIf="!loading">
  <app-list-top-card [title]="'OTHER-PAGES.TITLE' | translate">
    <app-search-box
      class="mr2"
      [label]="'OTHER-PAGES.FILTERS.SEARCH' | translate"
      [value]="searchValue"
      (search)="onSearch($event)"
    ></app-search-box>
  </app-list-top-card>

  <app-dynamic-table
    (changeVisibility)="changeVisibilityState($event)"
    [data]="otherPages"
    [type]="'other-pages'"
    [columns]="columns"
    [preferences]="preferences"
  ></app-dynamic-table>
</div>
<app-spinner *ngIf="loading"></app-spinner>
