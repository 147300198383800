<div class="mb4">
  <app-detail-card>
    <mat-tab-group class="app-tab-group">
      <mat-tab
        class="app-tab-group"
        [label]="'PROGRAM.TABS.PROPOSITION' | translate | uppercase"
      >
        <div class="mt2">
          <div class="mb3" [formGroup]="formGroup.get('race')">
            <mat-form-field
              appearance="fill"
              class="app-detail-race-form-field"
            >
              <mat-label>{{ "PROGRAM.RACE.TITLE" | translate }}</mat-label>
              <input formControlName="title" matInput />
            </mat-form-field>
          </div>

          <div class="mb3" [formGroup]="formGroup.get('race')">
            <mat-form-field
              appearance="fill"
              class="app-detail-race-form-field"
            >
              <mat-label>{{ "PROGRAM.RACE.RACE-CODE" | translate }}</mat-label>
              <input formControlName="raceCode" matInput />
            </mat-form-field>
          </div>

          <div class="mb3" [formGroup]="formGroup.get('race')">
            <app-image-input
              [control]="formGroup.get('race').get('sponsorImg')"
              [title]="'PROGRAM.RACE.IMAGE.SPONSOR' | translate"
              (delete)="deleteImage('sponsor')"
            ></app-image-input>
          </div>

          <div class="mb3" [formGroup]="formGroup.get('race')">
            <app-image-input
              [control]="formGroup.get('race').get('mapImg')"
              [title]="'PROGRAM.RACE.IMAGE.MAP' | translate"
              [width]="375"
              [height]="96"
              (delete)="deleteImage('mapImage')"
            ></app-image-input>
          </div>

          <div class="mb3 flex fit justify-between">
            <div [formGroup]="formGroup.get('race')">
              <mat-form-field appearance="fill">
                <mat-label>{{
                  "PROGRAM.RACE.DATE_FROM" | translate
                }}</mat-label>
                <input
                  matInput
                  [matDatepicker]="picker"
                  formControlName="dateTimeFrom"
                />
                <mat-datepicker-toggle matSuffix [for]="picker">
                  <mat-icon matDatepickerToggleIcon>date_range</mat-icon>
                </mat-datepicker-toggle>
                <mat-datepicker #picker></mat-datepicker>
              </mat-form-field>
            </div>

            <div [formGroup]="formGroup.get('time')">
              <mat-form-field appearance="fill">
                <mat-label>{{ "PROGRAM.RACE.TIME" | translate }}</mat-label>
                <input
                  formControlName="timeFrom"
                  matInput
                  (focusout)="validate()"
                />
              </mat-form-field>
              <div class="pl1 mb1">
                <mat-error *ngIf="formGroup.get('time').hasError('timeFrom')">
                  {{ "CORE.ERROR.TIME-FORMAT" | translate }}
                </mat-error>
              </div>
            </div>
          </div>

          <div class="mb3 flex fit justify-between">
            <div [formGroup]="formGroup.get('race')">
              <mat-form-field appearance="fill">
                <mat-label>{{ "PROGRAM.RACE.DATE_TO" | translate }}</mat-label>
                <input
                  matInput
                  [matDatepicker]="dateTimeToPicker"
                  formControlName="dateTimeTo"
                />
                <mat-datepicker-toggle matSuffix [for]="dateTimeToPicker">
                  <mat-icon matDatepickerToggleIcon>date_range</mat-icon>
                </mat-datepicker-toggle>
                <mat-datepicker #dateTimeToPicker></mat-datepicker>
              </mat-form-field>
            </div>

            <div [formGroup]="formGroup.get('time')">
              <mat-form-field appearance="fill">
                <mat-label>{{ "PROGRAM.RACE.TIME" | translate }}</mat-label>
                <input
                  formControlName="timeTo"
                  matInput
                  (focusout)="validate()"
                />
              </mat-form-field>
              <div class="pl1 mb1">
                <mat-error *ngIf="formGroup.get('time').hasError('timeTo')">
                  {{ "CORE.ERROR.TIME-FORMAT" | translate }}
                </mat-error>
              </div>
            </div>
          </div>

          <div
            class="mb3 flex fit justify-between"
            [formGroup]="formGroup.get('race')"
          >
            <mat-form-field
              appearance="fill"
              class="app-detail-race-form-field"
            >
              <mat-label>{{ "PROGRAM.RACE.PLACE" | translate }}</mat-label>
              <input formControlName="place" matInput />
            </mat-form-field>
          </div>

          <div
            class="mb3 flex fit justify-between"
            [formGroup]="formGroup.get('race')"
          >
            <mat-form-field appearance="fill">
              <mat-label>{{ "PROGRAM.RACE.TECHNIQUE" | translate }}</mat-label>
              <input formControlName="technique" matInput />
            </mat-form-field>

            <mat-form-field appearance="fill">
              <mat-label>{{ "PROGRAM.RACE.START_TYPE" | translate }}</mat-label>
              <input formControlName="startType" matInput />
            </mat-form-field>
          </div>

          <div
            class="mb3 flex fit justify-between"
            [formGroup]="formGroup.get('race')"
          >
            <mat-form-field appearance="fill">
              <mat-label>{{ "CORE.GPS.LATITUDE" | translate }}</mat-label>
              <input
                formControlName="gpsLat"
                matInput
                placeholder="50.185789"
                type="number"
              />
            </mat-form-field>

            <mat-form-field appearance="fill">
              <mat-label>{{ "CORE.GPS.LONGITUDE" | translate }}</mat-label>
              <input
                formControlName="gpsLon"
                matInput
                placeholder="14.3043336"
                type="number"
              />
            </mat-form-field>
          </div>

          <div class="mb3" [formGroup]="formGroup.get('race')">
            <app-image-input
              [control]="formGroup.get('race').get('heightProfileImg')"
              [title]="'PROGRAM.RACE.HEIGHT_PROFILE' | translate"
              [width]="375"
              [height]="96"
              (delete)="deleteImage('heightProfileImage')"
            ></app-image-input>
          </div>

          <div class="mb4">
            <mat-label>{{ "PROGRAM.RACE.NUMBERS" | translate }}</mat-label>
            <app-text-editor
              [formGroup]="formGroup.get('race')"
              editorControl="startNumEmission"
            ></app-text-editor>
          </div>

          <div
            class="mb3 flex fit justify-between app-text-area-waves mt4"
            [formGroup]="formGroup.get('race')"
          >
            <mat-form-field
              appearance="fill"
              class="app-detail-race-form-field"
            >
              <mat-label>{{ "PROGRAM.RACE.WAVES" | translate }}</mat-label>
              <textarea
                formControlName="startWaves"
                class="app-textarea-text app-text-area-waves"
                matInput
                md-no-autogrow
              ></textarea>
            </mat-form-field>
          </div>

          <div class="mb3 mt4" [formGroup]="formGroup.get('race')">
            <mat-form-field
              appearance="fill"
              class="app-detail-race-form-field"
            >
              <mat-label>{{ "CORE.FACEBOOK.LINK" | translate }}</mat-label>
              <input formControlName="fbLink" matInput />
            </mat-form-field>
          </div>

          <div class="mb3" [formGroup]="formGroup.get('race')">
            <mat-form-field
              appearance="fill"
              class="app-detail-race-form-field"
            >
              <mat-label>{{
                "PROGRAM.RACE.INFO-NUMBERS.LINK" | translate
              }}</mat-label>
              <input formControlName="urlInfoNumRel" matInput />
            </mat-form-field>
          </div>

          <div class="mb3" [formGroup]="formGroup.get('race')">
            <mat-form-field
              appearance="fill"
              class="app-detail-race-form-field"
            >
              <mat-label>{{
                "PROGRAM.RACE.INFO-RACE.LINK" | translate
              }}</mat-label>
              <input formControlName="urlInfoRace" matInput />
            </mat-form-field>
          </div>
        </div>
      </mat-tab>
      <mat-tab [label]="'PROGRAM.TABS.PHOTO' | translate | uppercase">
        <app-image-panel
          *ngIf="loadedImages.value"
          (delete)="deleteGalleryImage($event)"
          [loadedImagesControl]="loadedImages"
          [addedImagesControl]="addedImages"
        ></app-image-panel>
      </mat-tab>

      <mat-tab
        *ngIf="scheduleType !== 'ORGANIZER'"
        [label]="'PROGRAM.TABS.START_INSTRUCTIONS' | translate | uppercase"
      >
        <app-basic-form-content
          [formGroup]="formGroup"
          (deleteBasicFormImage)="onDeleteBasicFormImage($event)"
        ></app-basic-form-content>
      </mat-tab>
    </mat-tab-group>
  </app-detail-card>
  <app-footer (save)="save()" [loading]="loading"></app-footer>
</div>
