<div class="mb4">
  <div class="flex justify-between">
    <div class="mb2 app-other-pages-title">
      {{ "OTHER-PAGES.TRANSPORT.PARKING.SECTION" | translate }}
      {{ index + 1 }}
    </div>
    <div>
      <app-icon-title
        (click)="getIndex.emit(index)"
        icon="delete"
      ></app-icon-title>
    </div>
  </div>

  <div class="mt2">
    <div class="mb3" [formGroup]="formGroup">
      <mat-form-field appearance="fill" class="app-form-field-full-width">
        <mat-label>{{
          "OTHER-PAGES.TRANSPORT.PARKING.TITLE" | translate
        }}</mat-label>
        <input formControlName="name" matInput />
      </mat-form-field>
    </div>
  </div>

  <div class="mt2">
    <div class="mb3" [formGroup]="formGroup">
      <mat-form-field appearance="fill" class="app-form-field-full-width">
        <mat-label>{{
          "OTHER-PAGES.TRANSPORT.PARKING.DESCRIPTION" | translate
        }}</mat-label>
        <input formControlName="description" matInput />
      </mat-form-field>
    </div>
  </div>

  <div class="mb3 flex fit justify-between" [formGroup]="formGroup">
    <mat-form-field appearance="fill">
      <mat-label>{{ "CORE.GPS.LATITUDE" | translate }}</mat-label>
      <input
        formControlName="gpsLat"
        matInput
        placeholder="50.185789"
        type="number"
      />
    </mat-form-field>

    <mat-form-field appearance="fill">
      <mat-label>{{ "CORE.GPS.LONGITUDE" | translate }}</mat-label>
      <input
        formControlName="gpsLon"
        matInput
        placeholder="14.3043336"
        type="number"
      />
    </mat-form-field>
  </div>
</div>
