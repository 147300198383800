import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { OtherPagesRoutingModule } from './other-pages-routing.module';
import { OtherPagesListComponent } from './components/other-pages-list/other-pages-list.component';
import { OtherPagesDetailComponent } from './components/other-pages-detail/other-pages-detail.component';

import { SharedModule } from '../shared/shared.module';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { BaseMapComponent } from './components/base-map/base-map.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { WeatherComponent } from './components/weather/weather.component';
import { TransportComponent } from './components/transport/transport.component';
import { EnervitComponent } from './components/enervit/enervit.component';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatIconModule } from '@angular/material/icon';
import { TransportBusComponent } from './components/transport-bus/transport-bus.component';
import { TransportParkingComponent } from './components/transport-parking/transport-parking.component';
import { TransportParkingPlaceComponent } from './components/transport-parking-place/transport-parking-place.component';
import { EnervitProductsComponent } from './components/enervit-products/enervit-products.component';
import { EnervitInfoComponent } from './components/enervit-info/enervit-info.component';
import { TransportTaxiComponent } from './components/transport-taxi/transport-taxi.component';
import { WaxesComponent } from './components/waxes/waxes.component';
import { TransportParkingInfoComponent } from './components/transport-parking-info/transport-parking-info.component';

@NgModule({
  declarations: [
    OtherPagesListComponent,
    OtherPagesDetailComponent,
    BaseMapComponent,
    WeatherComponent,
    TransportComponent,
    EnervitComponent,
    TransportBusComponent,
    TransportParkingComponent,
    TransportParkingPlaceComponent,
    EnervitProductsComponent,
    EnervitInfoComponent,
    TransportTaxiComponent,
    WaxesComponent,
    TransportParkingInfoComponent,
  ],
  imports: [
    CommonModule,
    OtherPagesRoutingModule,
    SharedModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    FormsModule,
    ReactiveFormsModule,
    MatSnackBarModule,
    MatTableModule,
    MatTabsModule,
    MatIconModule,
  ],
})
export class OtherPagesModule {}
