<div class="mb4">
  <div class="flex justify-between">
    <div class="mb2 app-other-pages-title">
      {{ "OTHER-PAGES.ENERVIT.INFO.SECTION" | translate }}
      {{ index + 1 }}
    </div>
    <div>
      <app-icon-title
        (click)="getIndex.emit(index)"
        icon="delete"
      ></app-icon-title>
    </div>
  </div>

  <div class="mt2">
    <div class="mb3" [formGroup]="formGroup">
      <mat-form-field appearance="fill" class="app-form-field-full-width">
        <mat-label>{{
          "OTHER-PAGES.ENERVIT.INFO.TITLE" | translate
        }}</mat-label>
        <input formControlName="title" matInput />
      </mat-form-field>
    </div>
  </div>

  <div class="mt2">
    <div class="mb3" [formGroup]="formGroup">
      <mat-form-field appearance="fill" class="app-form-field-full-width">
        <mat-label>{{
          "OTHER-PAGES.ENERVIT.INFO.SUBTITLE" | translate
        }}</mat-label>
        <input formControlName="subtitle" matInput />
      </mat-form-field>
    </div>
  </div>

  <div [formGroup]="formGroup">
    <div class="mb4">
      <mat-label>{{
        "OTHER-PAGES.ENERVIT.INFO.DESCRIPTION" | translate
      }}</mat-label>

      <app-text-editor
        [formGroup]="formGroup"
        editorControl="description"
      ></app-text-editor>
    </div>
  </div>
</div>
