import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { IComponentCanDeactivate } from 'src/app/core/model/can-deactive-guard.model';
import { PROGRESS_DIALOG_ACTION_SAVING } from 'src/app/shared/constants/dialog.constants';
import { ApiCallExtensionService } from 'src/app/shared/services/api-call-extension/api-call-extension.service';
import { LanguageService } from 'src/app/shared/services/language/language.service';
import { OtherPagesService } from '../../services/orther-pages/other-pages.service';

@Component({
  selector: 'app-base-map',
  templateUrl: './base-map.component.html',
  styleUrls: ['./base-map.component.scss'],
})
export class BaseMapComponent implements OnInit, IComponentCanDeactivate {
  private readonly EVENT_ID = parseInt(localStorage.getItem('eventId'), 10);
  formGroup: FormGroup;
  loading = false;
  originalValue: string;
  private isSaveClicked = false;

  constructor(
    private formBuilder: FormBuilder,
    private otherPagesService: OtherPagesService,
    private router: Router,
    private languageService: LanguageService,
    private translate: TranslateService,
    private apiCallExtensionService: ApiCallExtensionService
  ) {
    this.formGroup = this.formBuilder.group({
      baseMap: [''],
    });
  }

  ngOnInit(): void {
    this.init();
  }

  private init(): void {
    this.initBaseMap();
    this.translate.onLangChange.subscribe((language: LangChangeEvent) => {
      this.languageService.setActiveLanguage(language.lang);
      this.initBaseMap();
    });
  }

  canDeactivate(): boolean {
    return (
      this.originalValue === this.formGroup.get('baseMap').value ||
      this.isSaveClicked
    );
  }

  deleteImage(): Observable<void> {
    const activeLanguageCode = this.languageService.getActiveLanguage();
    return this.otherPagesService.deleteBaseMap(
      this.EVENT_ID,
      activeLanguageCode
    );
  }

  initBaseMap(): void {
    this.loading = true;
    const activeLanguageCode = this.languageService.getActiveLanguage();
    this.otherPagesService.getBaseMap(this.EVENT_ID).subscribe((baseMap) => {
      const url =
        baseMap[activeLanguageCode] !== 'null'
          ? baseMap[activeLanguageCode]
          : '';
      this.loading = false;
      this.formGroup.get('baseMap').setValue(url);
      this.originalValue = url;
    });
  }

  onCancelClick(): void {
    this.navigate();
  }

  navigate(): void {
    this.router.navigate(['other-pages', 'list']);
  }

  onSaveClick(): void {
    this.isSaveClicked = true;
    const obsever = this.getObserver();

    if (obsever) {
      this.apiCallExtensionService
        .extendApiCall(
          () => obsever,
          this.translate.instant(PROGRESS_DIALOG_ACTION_SAVING)
        )
        .subscribe({
          next: () => {
            this.navigate();
          },
        });
    } else {
      this.navigate();
    }
  }

  private getObserver(): Observable<void> | null {
    const activeLanguageCode = this.languageService.getActiveLanguage();
    const value = this.formGroup.get('baseMap').value;

    return !value
      ? this.deleteImage()
      : value instanceof File
      ? this.otherPagesService.uploadBaseMap(
          this.EVENT_ID,
          value,
          activeLanguageCode
        )
      : null;
  }
}
