import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';
import { IGame } from '../model/game.model';

@Injectable({
  providedIn: 'root',
})
export class GameService {
  private readonly basePath = environment.apiUrl;

  constructor(private httpClient: HttpClient) {}

  getGameItem(itemCount: number = 100000000): Observable<IGame[]> {
    return this.httpClient
      .get<IGame[]>(`${this.basePath}/gameItems`, {
        params: {
          count: itemCount.toString(),
        },
      })
      .pipe(
        map((items) => {
          return items;
        })
      );
  }
}
