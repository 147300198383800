import { Component, Input, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-dialog-layout',
  templateUrl: './dialog-layout.component.html',
  styleUrls: ['./dialog-layout.component.scss'],
})
export class DialogLayoutComponent implements OnInit {
  @Input() showClose: boolean | undefined = false;
  @Input() title: string;
  @Input() showActions = true;
  @Input() alignActions: 'start' | 'center' | 'end' = 'end';

  constructor(public dialogRef: MatDialogRef<Component>) {}

  ngOnInit(): void {
    // empty
  }

  onClose(): void {
    this.dialogRef.close();
  }
}
