<div class="mb4">
  <div class="flex justify-between">
    <div class="mb2 app-other-pages-title" [formGroup]="formGroup">
      <mat-label>{{ formGroup.get("name").value }}</mat-label>
    </div>
    <div>
      <app-icon-title
        (click)="getIndex.emit(index)"
        icon="delete"
      ></app-icon-title>
    </div>
  </div>

  <div class="mt2">
    <div class="mb3" [formGroup]="formGroup">
      <mat-form-field appearance="fill" class="app-form-field-full-width">
        <mat-label>{{
          "OTHER-PAGES.TRANSPORT.BUS.TITLE" | translate
        }}</mat-label>
        <input formControlName="name" matInput />
      </mat-form-field>
    </div>
  </div>

  <div class="mt2">
    <div class="mb3" [formGroup]="formGroup">
      <mat-form-field appearance="fill" class="app-form-field-full-width">
        <mat-label>{{
          "OTHER-PAGES.TRANSPORT.BUS.ROUTE_NAME" | translate
        }}</mat-label>
        <input formControlName="routeName" matInput />
      </mat-form-field>
    </div>
  </div>

  <div class="mt2">
    <div class="mb3">
      <mat-label>{{
        "OTHER-PAGES.TRANSPORT.BUS.DIRECTION-THERE" | translate
      }}</mat-label>
    </div>
  </div>

  <div class="mt2">
    <div class="mb3" [formGroup]="formGroup">
      <mat-form-field appearance="fill" class="app-form-field-full-width">
        <mat-label>{{
          "OTHER-PAGES.TRANSPORT.BUS.NAME-THERE" | translate
        }}</mat-label>
        <input formControlName="nameThere" matInput />
      </mat-form-field>
    </div>
  </div>

  <div class="mt2">
    <div class="mb3" [formGroup]="formGroup">
      <mat-form-field appearance="fill" class="app-form-field-full-width">
        <mat-label>{{
          "OTHER-PAGES.TRANSPORT.BUS.STOPNAME" | translate
        }}</mat-label>
        <input formControlName="stopNameThere" matInput />
      </mat-form-field>
    </div>
  </div>

  <div class="mb3 flex fit justify-between" [formGroup]="formGroup">
    <mat-form-field appearance="fill">
      <mat-label>{{ "CORE.GPS.LATITUDE" | translate }}</mat-label>
      <input
        formControlName="gpsLatThere"
        matInput
        placeholder="50.185789"
        type="number"
      />
    </mat-form-field>

    <mat-form-field appearance="fill">
      <mat-label>{{ "CORE.GPS.LONGITUDE" | translate }}</mat-label>
      <input
        formControlName="gpsLonThere"
        matInput
        placeholder="14.3043336"
        type="number"
      />
    </mat-form-field>
  </div>

  <div class="mb1">
    <app-icon-title
      icon="add"
      [title]="'OTHER-PAGES.TRANSPORT.BUS.ADD_CONNECTION' | translate"
      (click)="onAddConnectionThere()"
    >
    </app-icon-title>
  </div>

  <div *ngFor="let line of getConnectionsThere().controls; let j = index">
    <div class="full-width mb1 flex justify-end">
      <app-icon-title
        (click)="onDeleteConnectionThere(j)"
        icon="delete"
      ></app-icon-title>
    </div>

    <div class="mb3 flex fit justify-between">
      <div [formGroup]="line">
        <mat-form-field appearance="fill">
          <mat-label>{{
            "OTHER-PAGES.TRANSPORT.BUS.CONNECTION.DATE" | translate
          }}</mat-label>
          <input
            matInput
            [matDatepicker]="dateTimeToPicker"
            formControlName="date"
          />
          <mat-datepicker-toggle matSuffix [for]="dateTimeToPicker">
            <mat-icon matDatepickerToggleIcon>date_range</mat-icon>
          </mat-datepicker-toggle>
          <mat-datepicker #dateTimeToPicker></mat-datepicker>
        </mat-form-field>
      </div>

      <div [formGroup]="line">
        <mat-form-field appearance="fill">
          <mat-label>{{
            "OTHER-PAGES.TRANSPORT.BUS.CONNECTION.TIME" | translate
          }}</mat-label>
          <input formControlName="time" matInput type="time" />
        </mat-form-field>
      </div>
    </div>
  </div>

  <div class="mt2">
    <div class="mb3" [formGroup]="formGroup">
      <mat-form-field appearance="fill" class="app-form-field-full-width">
        <mat-label>{{
          "OTHER-PAGES.TRANSPORT.BUS.LINK-THERE" | translate
        }}</mat-label>
        <input formControlName="linkThere" matInput />
      </mat-form-field>
    </div>
  </div>

  <div class="mt2">
    <div class="mb3">
      <mat-label>{{
        "OTHER-PAGES.TRANSPORT.BUS.DIRECTION-BACK" | translate
      }}</mat-label>
    </div>
  </div>

  <div class="mt2">
    <div class="mb3" [formGroup]="formGroup">
      <mat-form-field appearance="fill" class="app-form-field-full-width">
        <mat-label>{{
          "OTHER-PAGES.TRANSPORT.BUS.NAME-BACK" | translate
        }}</mat-label>
        <input formControlName="nameBack" matInput />
      </mat-form-field>
    </div>
  </div>

  <div class="mt2">
    <div class="mb3" [formGroup]="formGroup">
      <mat-form-field appearance="fill" class="app-form-field-full-width">
        <mat-label>{{
          "OTHER-PAGES.TRANSPORT.BUS.STOPNAME" | translate
        }}</mat-label>
        <input formControlName="stopNameBack" matInput />
      </mat-form-field>
    </div>
  </div>

  <div class="mb3 flex fit justify-between" [formGroup]="formGroup">
    <mat-form-field appearance="fill">
      <mat-label>{{ "CORE.GPS.LATITUDE" | translate }}</mat-label>
      <input
        formControlName="gpsLatBack"
        matInput
        placeholder="50.185789"
        type="number"
      />
    </mat-form-field>

    <mat-form-field appearance="fill">
      <mat-label>{{ "CORE.GPS.LONGITUDE" | translate }}</mat-label>
      <input
        formControlName="gpsLonBack"
        matInput
        placeholder="14.3043336"
        type="number"
      />
    </mat-form-field>
  </div>

  <div class="mb1">
    <app-icon-title
      icon="add"
      [title]="'OTHER-PAGES.TRANSPORT.BUS.ADD_CONNECTION' | translate"
      (click)="onAddConnectionBack()"
    >
    </app-icon-title>
  </div>

  <div *ngFor="let line of getConnectionsBack().controls; let k = index">
    <div class="full-width mb1 flex justify-end">
      <app-icon-title
        (click)="onDeleteConnectionBack(k)"
        icon="delete"
      ></app-icon-title>
    </div>

    <div class="mb3 flex fit justify-between">
      <div [formGroup]="line">
        <mat-form-field appearance="fill">
          <mat-label>{{
            "OTHER-PAGES.TRANSPORT.BUS.CONNECTION.DATE" | translate
          }}</mat-label>
          <input
            matInput
            [matDatepicker]="dateTimeToPicker"
            formControlName="date"
          />
          <mat-datepicker-toggle matSuffix [for]="dateTimeToPicker">
            <mat-icon matDatepickerToggleIcon>date_range</mat-icon>
          </mat-datepicker-toggle>
          <mat-datepicker #dateTimeToPicker></mat-datepicker>
        </mat-form-field>
      </div>

      <div [formGroup]="line">
        <mat-form-field appearance="fill">
          <mat-label>{{
            "OTHER-PAGES.TRANSPORT.BUS.CONNECTION.TIME" | translate
          }}</mat-label>
          <input formControlName="time" matInput type="time" />
        </mat-form-field>
      </div>
    </div>
  </div>

  <div class="mt2">
    <div class="mb3" [formGroup]="formGroup">
      <mat-form-field appearance="fill" class="app-form-field-full-width">
        <mat-label>{{
          "OTHER-PAGES.TRANSPORT.BUS.LINK-BACK" | translate
        }}</mat-label>
        <input formControlName="linkBack" matInput />
      </mat-form-field>
    </div>
  </div>
</div>
