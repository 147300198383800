import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-prompt-buttons',
  templateUrl: './prompt-buttons.component.html',
  styleUrls: ['./prompt-buttons.component.scss'],
})
export class PromptButtonsComponent implements OnInit {
  @Input() cancelText: string;
  @Input() confirmText: string;
  @Input() cancelDisabled = false;
  @Input() confirmDisabled = false;
  @Input() danger = false;
  @Output() confirm = new EventEmitter<void>();
  @Output() cancel = new EventEmitter<void>();

  constructor() {}

  ngOnInit(): void {
    // empty
  }
}
