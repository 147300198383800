import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  Input,
  OnInit,
} from '@angular/core';

@Component({
  selector: 'app-custom-button',
  templateUrl: './custom-button.component.html',
  styleUrls: ['./custom-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CustomButtonComponent implements OnInit {
  @Input() weight: 'primary' | 'secondary' | 'tertiary' = 'primary';
  @Input() @HostBinding('class.app-button--disabled') disabled = false;
  @Input() isSlim = false;
  @Input() danger = false;
  @Input() underline = true;

  constructor() {}

  ngOnInit(): void {
    // empty
  }
}
