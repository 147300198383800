import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-icon-title',
  templateUrl: './icon-title.component.html',
  styleUrls: ['./icon-title.component.scss'],
})
export class IconTitleComponent implements OnInit {
  @Input() title: string;
  @Input() icon: string;
  constructor() {}

  ngOnInit(): void {}
}
