import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { RouterModule } from '@angular/router';
import { SharedModule } from '../shared/shared.module';
import { MainLayoutComponent } from './components/main-layout/main-layout.component';
import { NavigationComponent } from './components/navigation/navigation.component';
import { SettingsComponent } from './components/settings/settings.component';
import { SortingSettingsComponent } from './components/sorting-settings/sorting-settings.component';
import { GetEventLogoByCodePipe } from './pipes/get-event-logo-by-code.pipe';
import { GetLanguageIconPipe } from './pipes/get-language-icon.pipe';

@NgModule({
  declarations: [MainLayoutComponent, NavigationComponent, SettingsComponent, SortingSettingsComponent, GetEventLogoByCodePipe, GetLanguageIconPipe],
  imports: [
    CommonModule,
    SharedModule,
    RouterModule,
    MatTabsModule,
    MatToolbarModule,
    MatListModule,
    MatMenuModule,
    MatButtonModule,
    MatIconModule,
  ],
  exports: [],
})
export class CoreModule {}
