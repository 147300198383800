import { Pipe, PipeTransform } from '@angular/core';
import { EVENT_LOGO_LIST } from '../constants/event-logo.constant';
import { EventCode, IEventLogo } from '../model/event.model';
import { EVENT_SHORT_CODE_LS_KEY } from 'src/app/shared/constants/storage.constant';
import { StorageService } from 'src/app/shared/services/storage/storage.service';

@Pipe({
  name: 'getEventLogoByCode',
})
export class GetEventLogoByCodePipe implements PipeTransform {
  constructor(private storageService: StorageService) {}

  transform(eventCode: string | undefined): string {
    return eventCode
      ? EVENT_LOGO_LIST.find((event) => event.code === eventCode)?.path ||
          EVENT_LOGO_LIST.find((event) => event.code === EventCode.Default)
            ?.path
      : EVENT_LOGO_LIST.find(
          (event) => event.code === this.getActiveEventLogo()
        )?.path ||
          EVENT_LOGO_LIST.find((event) => event.code === EventCode.Default)
            ?.path;
  }

  private getActiveEventLogo(): string {
    return this.storageService.getData(EVENT_SHORT_CODE_LS_KEY) as string;
  }
}
