import { Component, OnInit, OnDestroy } from '@angular/core';
import { IEvent } from 'src/app/api/model/events.model';
import { EventService } from '../../../api/services/event/event.service';
import { ILink } from '../../model/link.model';
import { LanguageService } from 'src/app/shared/services/language/language.service';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { LINKS } from '../../constants/link.constant';
import { SUPPORTED_LANGUAGES } from '../../../shared/constants/supported-languages.constant';
import { StorageService } from 'src/app/shared/services/storage/storage.service';
import {
  EVENT_EXT_ID_LS_KEY,
  EVENT_ID_LS_KEY,
  EVENT_SHORT_CODE_LS_KEY,
} from 'src/app/shared/constants/storage.constant';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { SettingsComponent } from '../settings/settings.component';
import { filter, mergeMap } from 'rxjs/operators';
import { forkJoin, Observable, of, Subscription } from 'rxjs';
import { ApiCallExtensionService } from 'src/app/shared/services/api-call-extension/api-call-extension.service';
import { PROGRESS_DIALOG_ACTION_SAVING } from 'src/app/shared/constants/dialog.constants';
import packageJson from '../../../../../package.json';
import {
  formatDateTime,
  getChangedEvents,
  hasEventOrderChanged,
  mapEventsToIds,
} from '../../utils/navigation.util';

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss'],
})
export class NavigationComponent implements OnInit, OnDestroy {
  events: IEvent[];
  links: ILink[] = LINKS;
  supportedLanguages = SUPPORTED_LANGUAGES;
  private subs = new Subscription();

  constructor(
    private eventService: EventService,
    public languageService: LanguageService,
    private router: Router,
    private translate: TranslateService,
    private storageService: StorageService,
    private dialog: MatDialog,
    private apiCallExtensionService: ApiCallExtensionService
  ) {}

  get activeEvent(): IEvent {
    return this.events?.find(
      (eventElement) =>
        eventElement.id === this.storageService.getData(EVENT_ID_LS_KEY)
    );
  }

  get version(): string {
    return packageJson?.version ?? '';
  }

  get userRole(): string {
    return localStorage.getItem('role') || 'admin';
  }

  ngOnInit(): void {
    this.eventService.getSortedEventList().subscribe((events) => {
      this.events = events;
      if (!this.storageService.getData(EVENT_ID_LS_KEY) && events[0]?.id) {
        this.storageService.saveData(EVENT_ID_LS_KEY, events[0]?.id);
        this.storageService.saveData(
          EVENT_SHORT_CODE_LS_KEY,
          events[0]?.eventShortCode.toString()
        );
      }

      if (
        !this.storageService.getData(EVENT_EXT_ID_LS_KEY) &&
        events[0]?.extId
      ) {
        this.storageService.saveData(EVENT_EXT_ID_LS_KEY, events[0]?.extId);
      }
    });
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  onSettingsClick(): void {
    const dialogRef = this.openSettingsDialog();

    this.subs.add(
      dialogRef
        .afterClosed()
        .pipe(
          filter((confirmed) => confirmed),
          mergeMap((reorderedEvents) =>
            this.apiCallExtensionService.extendApiCall(
              () => this.updateSettings(reorderedEvents),
              this.translate.instant(PROGRESS_DIALOG_ACTION_SAVING)
            )
          )
        )
        .subscribe({
          next: (reorderedEvents: IEvent[]) => {
            this.events = reorderedEvents;
          },
        })
    );
  }

  private updateSettings(reorderedEvents: IEvent[]): Observable<IEvent[]> {
    const observables: Observable<unknown>[] = [];
    if (!hasEventOrderChanged(this.events, reorderedEvents)) {
      observables.push(
        this.eventService.changeEventsOrder(mapEventsToIds(reorderedEvents))
      );
    }

    const changedEvents = getChangedEvents(reorderedEvents, this.events);

    changedEvents.forEach((changedEvent) => {
      const event = {
        ...changedEvent,
        startTimestamp: formatDateTime(changedEvent),
      };

      observables.push(
        this.eventService.updateGeneral(
          event,
          this.languageService.getActiveLanguage()
        )
      );
    });

    return !!observables.length
      ? forkJoin(observables).pipe(
          mergeMap(() => this.eventService.getSortedEventList())
        )
      : of(reorderedEvents);
  }

  openSettingsDialog(): MatDialogRef<SettingsComponent> {
    return this.dialog.open(SettingsComponent, {
      minWidth: '60%',
      minHeight: '80%',
      data: {
        showClose: true,
        events: this.events,
      },
    });
  }

  onEventChange(eventId: number, eventCode: string, eventExtId: number): void {
    this.storageService.saveData(EVENT_ID_LS_KEY, eventId);
    this.storageService.saveData(EVENT_SHORT_CODE_LS_KEY, eventCode);
    this.storageService.saveData(EVENT_EXT_ID_LS_KEY, eventExtId);
    location.reload();
  }

  onLanguageChange(languageCode: string): void {
    this.translate.use(languageCode);
    this.languageService.setActiveLanguage(languageCode);
    location.reload();
  }

  logout(): void {
    localStorage.removeItem('token');
    localStorage.removeItem('role');
    this.router.navigateByUrl('/login');
  }
}
