import {
  setDateWithTime,
  timeFormat,
} from 'src/app/shared/utils/dateFormatter.util';
import { IEvent } from '../../api/model/events.model';
import { arrayEquals } from 'src/app/shared/utils/array.util';

export function getChangedEvents(
  reorderedEvents: IEvent[],
  events: IEvent[]
): IEvent[] {
  const changedEvents: IEvent[] = [];
  reorderedEvents.forEach((reorderedEvent) => {
    const originalEvent = events.find(
      (event) => event.id === reorderedEvent.id
    );

    if (!originalEvent) {
      throw new Error(`original event not found ${reorderedEvent}`);
    }

    if (reorderedEvent.showEvent !== originalEvent.showEvent) {
      changedEvents.push(reorderedEvent);
    }
  });

  return changedEvents;
}

export function mapEventsToIds(events: IEvent[]): number[] {
  return events.map((event) => event.id);
}

export function formatDateTime(changedEvent: IEvent): string {
  const date = new Date();
  const time = timeFormat(date);
  return changedEvent.startTimestamp === ''
    ? (setDateWithTime(date, time) as unknown as string)
    : changedEvent.startTimestamp;
}

export function hasEventOrderChanged(
  events: IEvent[],
  reorderedEvents: IEvent[]
): boolean {
  return arrayEquals(mapEventsToIds(events), mapEventsToIds(reorderedEvents));
}
