<div *ngIf="!loading">
  <app-list-top-card [title]="'NOTIFICATIONS.TITLE' | translate">
    <app-search-box
      class="mr2"
      [label]="'NOTIFICATIONS.FILTERS.SEARCH' | translate"
      [value]="searchValue"
      (search)="onSearch($event)"
    ></app-search-box>

    <app-button
      [title]="'NOTIFICATIONS.ADD' | translate | uppercase"
      [icon]="'add'"
      class="app-element-margin"
      (click)="createNotification()"
    ></app-button>
  </app-list-top-card>

  <app-dynamic-table
    [data]="notifications"
    [type]="'notifications'"
    [columns]="columns"
    (deleteItem)="onDeleteNotificationClick($event)"
  ></app-dynamic-table>
</div>

<app-spinner *ngIf="loading"></app-spinner>
