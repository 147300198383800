<div class="app-search-box full-width">
  <mat-form-field
    #searchBoxRef
    [class.mat-form-field-responsive]="isScreenSmall"
    appearance="fill"
  >
    <mat-label>{{ label }}</mat-label>

    <input
      #searchInput
      matInput
      [placeholder]="placeholder"
      [value]="value"
      (focus)="searchBoxRef.updateOutlineGap()"
    />

    <mat-icon matPrefix>search</mat-icon>

    <button
      *ngIf="value"
      matSuffix
      mat-icon-button
      (click)="clearSearchValue()"
    >
      <mat-icon>close</mat-icon>
    </button>
  </mat-form-field>
</div>
