import { Component, Input, OnInit } from '@angular/core';
import { FormGroup, FormArray } from '@angular/forms';
import { ProgramService } from '../../services/program.service';
import { IProgramEventPost } from '../../models/program.model';
import { ActivatedRoute, Router } from '@angular/router';
import {
  setDateWithTime,
  timeFormat,
} from 'src/app/shared/utils/dateFormatter.util';
import { ImagesService } from 'src/app/api/service/images/images.service';
import { ImageType } from 'src/app/api/model/image.model';
import { LanguageService } from 'src/app/shared/services/language/language.service';
import { ProgramFormGroupBuildersService } from 'src/app/shared/services/form-group-builders/program-form-group-builders.service';
import { forkJoin, Observable, of } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { PROGRESS_DIALOG_ACTION_SAVING } from 'src/app/shared/constants/dialog.constants';
import { ApiCallExtensionService } from 'src/app/shared/services/api-call-extension/api-call-extension.service';

@Component({
  selector: 'app-program-event-detail',
  templateUrl: './program-event-detail.component.html',
  styleUrls: ['./program-event-detail.component.scss'],
})
export class ProgramEventDetailComponent implements OnInit {
  @Input() event: IProgramEventPost;
  @Input() formGroup: FormGroup;

  private readonly EVENT_ID = parseInt(localStorage.getItem('eventId'), 10);
  private programId: string;
  private scheduleType: string;

  deletedImagesId: number[] = [];
  mapUrl: string;
  sponzorUrl: string;
  time: string;
  loading = false;

  constructor(
    private programService: ProgramService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private imagesService: ImagesService,
    private languageService: LanguageService,
    private programFormGroupBuildersService: ProgramFormGroupBuildersService,
    private translate: TranslateService,
    private apiCallExtensionService: ApiCallExtensionService
  ) {}

  ngOnInit(): void {
    this.programId = this.activatedRoute.snapshot.paramMap.get('programId');
    this.initEvent();

    this.scheduleType =
      this.activatedRoute.snapshot.queryParamMap.get('scheduleType');

    this.initImageGallery();
  }

  initEvent(): void {
    if (this.event) {
      this.initEventTime();
      this.initEventFormGroup();
    }
  }

  private initEventTime(): void {
    if (parseInt(this.programId, 10) !== 0) {
      const eventTimeFrom = timeFormat(new Date(this.event.dateTimeFrom));
      const eventTimeTo = timeFormat(new Date(this.event.dateTimeTo));
      this.formGroup.get('time').get('timeFrom').setValue(eventTimeFrom);
      this.formGroup.get('time').get('timeTo').setValue(eventTimeTo);
    } else {
      this.formGroup.get('time').get('timeFrom').setValue('');
      this.formGroup.get('time').get('timeTo').setValue('');
    }
  }

  initEventFormGroup(): void {
    const languageCode = this.languageService.getActiveLanguage();
    if (this.event) {
      const sponsorImg =
        this.event.sponsorImg[languageCode] !== 'null'
          ? this.event.sponsorImg[languageCode]
          : '';

      const eventFormGroup = this.formGroup.get('event');

      eventFormGroup.get('about').setValue(this.event.about[languageCode]);
      eventFormGroup.get('active').setValue(this.event.active);
      eventFormGroup.get('dateTimeFrom').setValue(this.event.dateTimeFrom);
      eventFormGroup.get('dateTimeTo').setValue(this.event.dateTimeTo);
      eventFormGroup.get('eventId').setValue(this.event.eventId);
      eventFormGroup.get('fbLink').setValue(this.event.fbLink[languageCode]);
      eventFormGroup
        .get('importantInfoUrl')
        .setValue(this.event.importantInfoUrl[languageCode]);
      eventFormGroup.get('igLink').setValue(this.event.igLink[languageCode]);
      eventFormGroup
        .get('mapsLink')
        .setValue(this.event.mapsLink[languageCode]);
      eventFormGroup
        .get('stravaLink')
        .setValue(this.event.stravaLink[languageCode]);
      eventFormGroup.get('id').setValue(this.event.id);
      eventFormGroup.get('place').setValue(this.event.place[languageCode]);
      eventFormGroup.get('sponsorImg').setValue(sponsorImg);
      eventFormGroup.get('title').setValue(this.event.title[languageCode]);
      eventFormGroup.get('updated').setValue(this.event.updated);
      eventFormGroup.get('type').setValue(this.event.type);
    }
  }

  onNavBack(): void {
    this.router.navigate(['program', 'list', this.scheduleType]);
  }

  deleteImage(type: string): void {
    const activeLanguageCode = this.languageService.getActiveLanguage();
    this.programService
      .deleteRaceImage(
        parseInt(this.programId, 10),
        type,
        1,
        activeLanguageCode
      )
      .subscribe();
  }

  initImageGallery(): void {
    const activeLanguageCode = this.languageService.getActiveLanguage();
    this.loading = true;
    this.imagesService
      .getImages(
        parseInt(this.programId, 10),
        ImageType.ScheduleEvent,
        activeLanguageCode
      )
      .subscribe((images) => {
        images.forEach(() => {
          this.addFormGroup('gallery');
        });
        this.formGroup.get('gallery').setValue(images);
        this.loading = false;
      });
  }

  addFormGroup(type: string): void {
    (this.formGroup.get(type) as FormArray).push(
      this.programFormGroupBuildersService.createProgramEventDetailImageGalleryFormGroup()
    );
  }

  changeLoadingState(): void {
    this.loading = !this.loading;
  }

  getFormArray(type: string): FormArray {
    return this.formGroup.get(type) as FormArray;
  }

  deleteFormGroup(type: string, index: number): void {
    (this.formGroup.get(type) as FormArray).removeAt(index);
  }

  deleteIndex(index: number, type: string): void {
    const result = this.formGroup.get(type).value;
    const filteredResult = result.filter((info) => info !== result[index]);
    this.addDeleteIdByType(result[index]?.id, type);
    this.deleteFormGroup(type, index);
    this.formGroup.get(type).setValue(filteredResult);
  }

  addDeleteIdByType(id: number, type: string): void {
    switch (type) {
      case 'gallery':
        this.deletedImagesId.push(id);
        break;
    }
  }

  transformEventDetailObj(): void {
    const eventFormGroup = this.formGroup.get('event');
    const activeLanguageCode = this.languageService.getActiveLanguage();

    this.event.about[activeLanguageCode] = eventFormGroup.get('about').value;
    this.event.active = eventFormGroup.get('active').value;
    this.event.dateTimeFrom = eventFormGroup.get('dateTimeFrom').value;
    this.event.dateTimeTo = eventFormGroup.get('dateTimeTo').value;
    this.event.eventId = eventFormGroup.get('eventId').value;
    this.event.fbLink[activeLanguageCode] = eventFormGroup.get('fbLink').value;
    this.event.importantInfoUrl[activeLanguageCode] =
      eventFormGroup.get('importantInfoUrl').value;
    this.event.mapsLink[activeLanguageCode] =
      eventFormGroup.get('mapsLink').value;
    this.event.igLink[activeLanguageCode] = eventFormGroup.get('igLink').value;
    this.event.stravaLink[activeLanguageCode] =
      eventFormGroup.get('stravaLink').value;
    this.event.id = eventFormGroup.get('id').value;
    this.event.inserted = eventFormGroup.get('inserted').value;
    this.event.place[activeLanguageCode] = eventFormGroup.get('place').value;
    this.event.sponsorImg[activeLanguageCode] =
      eventFormGroup.get('sponsorImg').value;
    this.event.title[activeLanguageCode] = eventFormGroup.get('title').value;
    this.event.type = eventFormGroup.get('type').value;
    this.event.updated = eventFormGroup.get('updated').value;
  }

  createOrUpdateProgramEvent(): Observable<unknown> {
    this.formGroup.markAsUntouched();
    const activeLanguageCode = this.languageService.getActiveLanguage();
    const imageFile = this.getImageFileFromFormGroup();
    const galleryFiles = this.getImageGalleryFiles();
    this.prepareProgramEvent();

    if (this.EVENT_ID !== 0) {
      this.imagesService
        .deleteImages(this.EVENT_ID, this.deletedImagesId)
        .subscribe();
    }

    return this.programService
      .createOrUpdateProgramEvent(
        this.EVENT_ID,
        this.event,
        imageFile,
        'uploadSponsor',
        1,
        activeLanguageCode
      )
      .pipe(
        mergeMap((program) =>
          forkJoin([
            this.saveImage(program.id, 'sponsorImg', 'uploadSponsor'),
            this.imagesService.uploadImages(
              this.EVENT_ID,
              program.id,
              ImageType.ScheduleEvent,
              galleryFiles,
              activeLanguageCode
            ),
          ])
        )
      );
  }

  onSaveClick(): void {
    this.apiCallExtensionService
      .extendApiCall(
        () => this.createOrUpdateProgramEvent(),
        this.translate.instant(PROGRESS_DIALOG_ACTION_SAVING)
      )
      .subscribe({
        next: () => {
          this.onNavBack();
        },
      });
  }

  onCancelClick(): void {
    this.onNavBack();
  }

  private getImageGalleryFiles(): File[] {
    const galleryImages: File[] = [];

    this.formGroup.get('gallery').value.forEach((galleryImage) => {
      if (galleryImage.image instanceof File) {
        galleryImages.push(galleryImage.image);
      }
    });

    return galleryImages;
  }

  private getImageFileFromFormGroup(): File | null | undefined {
    const sponsorImg = this.formGroup.get('event').get('sponsorImg').value;

    return sponsorImg instanceof File
      ? sponsorImg
      : sponsorImg === undefined
      ? undefined
      : null;
  }

  private prepareProgramEvent(): void {
    this.setDateTimeFormControl('dateTimeFrom', 'timeFrom');
    this.setDateTimeFormControl('dateTimeTo', 'timeTo');
    this.transformEventDetailObj();
  }

  private saveImage(
    programId: number,
    value: string,
    type: string
  ): Observable<unknown> {
    const activeLanguageCode = this.languageService.getActiveLanguage();
    const prop = this.formGroup.get('event').get(value).value;

    if (prop instanceof File) {
      return this.programService.uploadImage(
        programId,
        prop,
        type,
        1,
        activeLanguageCode
      );
    }

    return of(null);
  }

  setDateTimeFormControl(dateType: string, timeType: string): void {
    const DATE = this.formGroup.get('event').get(dateType).value;
    const TIME = this.formGroup.get('time').get(timeType).value;
    const formatedDate = setDateWithTime(DATE, TIME);
    this.formGroup.get('event').get(dateType).setValue(formatedDate);
  }
}
