<div class="table-container">
  <table *ngIf="program.length !== 0" mat-table [dataSource]="program">
    <!-- Title column -->
    <ng-container matColumnDef="title">
      <th mat-header-cell *matHeaderCellDef>
        {{ "PROGRAM.TITLE-EVENT" | translate | uppercase }}
      </th>
      <td mat-cell *matCellDef="let program">
        {{ program.title | truncate }}
      </td>
    </ng-container>

    <!-- Time column -->
    <ng-container matColumnDef="time">
      <th mat-header-cell *matHeaderCellDef>
        {{ "TABLE.COLUMNS.TIME" | translate | uppercase }}
      </th>
      <td mat-cell *matCellDef="let program">{{ program.time }}</td>
    </ng-container>

    <!-- Type Column -->
    <ng-container matColumnDef="type">
      <th mat-header-cell *matHeaderCellDef>
        {{ "TABLE.COLUMNS.TYPE" | translate | uppercase }}
      </th>
      <td mat-cell *matCellDef="let program">
        {{ typeName(program.type) | translate }}
      </td>
    </ng-container>

    <!-- Date Column -->
    <ng-container matColumnDef="date">
      <th mat-header-cell *matHeaderCellDef>
        {{ "TABLE.COLUMNS.DATE" | translate | uppercase }}
      </th>
      <td mat-cell *matCellDef="let program">{{ program.date }}</td>
    </ng-container>

    <!-- Edit Column -->
    <ng-container matColumnDef="edit">
      <th mat-header-cell *matHeaderCellDef></th>
      <td mat-cell *matCellDef="let program">
        <div class="flex justify-end">
          <mat-icon>edit</mat-icon>
        </div>
      </td>
    </ng-container>

    <!-- Delete Column -->
    <ng-container matColumnDef="delete">
      <th mat-header-cell *matHeaderCellDef></th>
      <td mat-cell class="app-table-icons" *matCellDef="let program">
        <div class="flex justify-end">
          <button mat-icon-button>
            <mat-icon
              (click)="$event.stopPropagation()"
              (click)="delete.emit(program)"
              >delete</mat-icon
            >
          </button>
        </div>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="columns"></tr>
    <tr
      mat-row
      *matRowDef="let row; columns: columns"
      (click)="navigate(row)"
    ></tr>
  </table>

  <app-empty-result *ngIf="program.length === 0"></app-empty-result>
</div>
