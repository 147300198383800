<button
  *ngIf="secondary"
  class="app-button app-button--secondary"
  [class.app-button-slim]="slim"
  mat-button
  [disabled]="loading"
>
  <div class="flex items-center">
    <mat-icon *ngIf="loading"
      ><mat-spinner diameter="20" color="'#fff'"> </mat-spinner
    ></mat-icon>
    <mat-icon *ngIf="icon" class="mr1">{{ icon }}</mat-icon>
    {{ title }}
  </div>
</button>

<button
  *ngIf="!secondary"
  class="app-button app-button--primary"
  [class.app-button-slim]="slim"
  mat-flat-button
  color="primary"
  [disabled]="loading"
>
  <div class="flex items-center">
    <mat-icon *ngIf="loading"
      ><mat-spinner diameter="20" color="'#fff'"> </mat-spinner
    ></mat-icon>
    <mat-icon *ngIf="icon" class="mr1">{{ icon }}</mat-icon>
    {{ title }}
  </div>
</button>
