import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { IEvent } from 'src/app/api/model/events.model';
import { EventService } from 'src/app/api/services/event/event.service';
import { Observable, Subscription, forkJoin } from 'rxjs';
import { getValueOrEmpty } from 'src/app/core/utils/helper.util';
import { LanguageService } from 'src/app/shared/services/language/language.service';
import {
  setDateWithTime,
  timeFormat,
} from 'src/app/shared/utils/dateFormatter.util';
import { TIME_REGEX } from 'src/app/shared/constants/time.constants';
import { marker } from '@biesbjerg/ngx-translate-extract-marker';
import { IComponentCanDeactivate } from 'src/app/core/model/can-deactive-guard.model';
import { ApiCallExtensionService } from 'src/app/shared/services/api-call-extension/api-call-extension.service';
import { PROGRESS_DIALOG_ACTION_SAVING } from 'src/app/shared/constants/dialog.constants';
import {
  IProgramAttributes,
  IProgramAttributesApi,
  IProgramAttributesPost,
} from 'src/app/shared/model/program-event-attributes.model';

@Component({
  selector: 'app-general',
  templateUrl: './general.page.html',
  styleUrls: ['./general.page.scss'],
})
export class GeneralPage implements OnInit, OnDestroy, IComponentCanDeactivate {
  formGroup: FormGroup;
  activeEvent: IEvent;
  loading = false;

  private eventId: number;
  private activeLanguageCode: string;
  private subs = new Subscription();
  sharedPreferences: IProgramAttributesApi | null;

  constructor(
    private eventService: EventService,
    private formBuilder: FormBuilder,
    private languageService: LanguageService,
    private translate: TranslateService,
    private apiCallExtensionService: ApiCallExtensionService
  ) {
    this.formGroup = this.formBuilder.group({
      time: this.formBuilder.group({
        time: ['', [Validators.pattern(TIME_REGEX), Validators.required]],
      }),
      event: this.formBuilder.group({
        inserted: [''],
        updated: [''],
        id: [''],
        name: [''],
        extId: [''],
        dateFrom: [''],
        dateTo: [''],
        baseMap: [''],
        banner1: [''],
        banner1Link: [''],
        banner2: [''],
        banner2Link: [''],
        eventShortCode: [''],
        landingImage: [''],
        organizerMap: [''],
        startTimestamp: ['', [Validators.required]],
      }),
      programEventAttributesGroup: this.formBuilder.group({
        fbLink: [''],
        igLink: [''],
        mapsLink: [''],
        stravaLink: [''],
        dateTimeFrom: [''],
        dateTimeTo: [''],
      }),
    });
  }

  ngOnInit(): void {
    this.init();
  }

  init(): void {
    this.eventId = this.eventService.getEventId();
    this.activeLanguageCode = this.languageService.getActiveLanguage();
    this.loading = true;

    this.subs.add(
      forkJoin([
        this.eventService.getEventList(),
        this.eventService.getEventSharedPreferences(this.eventId),
      ]).subscribe({
        next: ([events, sharedPreferences]) => {
          this.activeEvent = events.find((event) => event.id === this.eventId);
          this.initFormGroup();
          this.setStartTime();
          this.initSharedPreferences(sharedPreferences);
          this.loading = false;
        },
      })
    );
  }

  private createPostSharedPreferencesObj(): IProgramAttributesPost {
    const sharedPreferences = this.formGroup.get('programEventAttributesGroup')
      ?.value as IProgramAttributes;

    return this.sharedPreferences
      ? {
          ...sharedPreferences,
          eventId: this.eventId,
          id: this.sharedPreferences.id,
        }
      : {
          ...sharedPreferences,
          eventId: this.eventId,
        };
  }

  private initSharedPreferences(
    sharedPreferences: IProgramAttributesApi
  ): void {
    this.sharedPreferences = sharedPreferences;
    if (sharedPreferences) {
      this.formGroup
        .get('programEventAttributesGroup')
        .setValue(this.mapApiSharedPreferencestoFormObj(sharedPreferences));
    }
  }

  private mapApiSharedPreferencestoFormObj(
    sharedPreferences: IProgramAttributesApi
  ): IProgramAttributes {
    return {
      fbLink: sharedPreferences.fbLink,
      igLink: sharedPreferences.igLink,
      dateTimeFrom: sharedPreferences.dateTimeFrom,
      dateTimeTo: sharedPreferences.dateTimeTo,
      mapsLink: sharedPreferences.mapsLink,
      stravaLink: sharedPreferences.stravaLink,
    };
  }

  canDeactivate(): boolean {
    return this.formGroup.untouched;
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  initFormGroup(): void {
    if (this.activeEvent) {
      const eventFormGroup = this.formGroup.get('event');
      eventFormGroup.get('inserted').setValue(this.activeEvent.inserted || '');
      eventFormGroup.get('updated').setValue(this.activeEvent.updated || '');
      eventFormGroup.get('id').setValue(this.activeEvent.id || '');
      eventFormGroup.get('name').setValue(this.activeEvent.name || '');
      eventFormGroup.get('extId').setValue(this.activeEvent.extId || '');
      eventFormGroup.get('dateFrom').setValue(this.activeEvent.dateFrom || '');
      eventFormGroup.get('dateTo').setValue(this.activeEvent.dateTo || '');
      eventFormGroup
        .get('baseMap')
        .setValue(
          getValueOrEmpty(this.activeEvent.baseMap[this.activeLanguageCode])
        );
      eventFormGroup
        .get('banner1')
        .setValue(
          getValueOrEmpty(this.activeEvent.banner1[this.activeLanguageCode])
        );
      eventFormGroup
        .get('banner1Link')
        .setValue(
          getValueOrEmpty(this.activeEvent.banner1Link[this.activeLanguageCode])
        );
      eventFormGroup
        .get('banner2')
        .setValue(
          getValueOrEmpty(this.activeEvent.banner2[this.activeLanguageCode])
        );
      eventFormGroup
        .get('banner2Link')
        .setValue(
          getValueOrEmpty(this.activeEvent.banner2Link[this.activeLanguageCode])
        );
      eventFormGroup
        .get('eventShortCode')
        .setValue(this.activeEvent.eventShortCode || '');
      eventFormGroup
        .get('landingImage')
        .setValue(
          getValueOrEmpty(
            this.activeEvent.landingImage[this.activeLanguageCode]
          )
        );
      eventFormGroup
        .get('organizerMap')
        .setValue(
          getValueOrEmpty(
            this.activeEvent.organizerMap[this.activeLanguageCode]
          )
        );
      eventFormGroup
        .get('startTimestamp')
        .setValue(this.activeEvent.startTimestamp || '');
    }
  }

  setStartTime(): void {
    const DATE =
      this.formGroup.get('event').get('startTimestamp').value || new Date();

    const eventTime = timeFormat(new Date(DATE));
    this.formGroup.get('event').get('startTimestamp').setValue(DATE);
    this.formGroup.get('time').get('time').setValue(eventTime);
  }

  formatDateTime(): void {
    const DATE = this.formGroup.get('event').get('startTimestamp').value;
    const TIME = this.formGroup.get('time').get('time').value;
    const formatedDate = setDateWithTime(DATE, TIME);
    this.formGroup.get('event').get('startTimestamp').setValue(formatedDate);
  }

  setGeneralObject(): void {
    const eventFormGroup = this.formGroup.get('event');
    const activeLanguageCode = this.languageService.getActiveLanguage();
    this.activeEvent.banner1[activeLanguageCode] =
      eventFormGroup.get('banner1').value;
    this.activeEvent.banner1Link[activeLanguageCode] =
      eventFormGroup.get('banner1Link').value;
    this.activeEvent.banner2[activeLanguageCode] =
      eventFormGroup.get('banner2').value;
    this.activeEvent.banner2Link[activeLanguageCode] =
      eventFormGroup.get('banner2Link').value;
    this.activeEvent.baseMap[activeLanguageCode] =
      eventFormGroup.get('baseMap').value;
    this.activeEvent.landingImage[activeLanguageCode] =
      eventFormGroup.get('landingImage').value;
    this.activeEvent.organizerMap[activeLanguageCode] =
      eventFormGroup.get('organizerMap').value;
    this.activeEvent.startTimestamp =
      eventFormGroup.get('startTimestamp').value;
  }

  onSaveClick(): void {
    this.formatDateTime();
    this.setGeneralObject();
    this.formGroup.markAsUntouched();

    this.subs.add(
      this.apiCallExtensionService
        .extendApiCall(
          () =>
            forkJoin([
              this.eventService.postEventSharedPreferences(
                this.createPostSharedPreferencesObj()
              ),
              this.updateGeneral(),
            ]),
          this.translate.instant(marker(PROGRESS_DIALOG_ACTION_SAVING))
        )
        .subscribe({
          next: () => {
            this.init();
          },
        })
    );
  }

  updateGeneral(): Observable<IEvent> {
    const activeLanguageCode = this.languageService.getActiveLanguage();
    return this.eventService.updateGeneral(
      this.activeEvent,
      activeLanguageCode
    );
  }
}
