<ng-container>
  <mat-form-field [class.mat-form-field-responsive]="isSmall" appearance="fill">
    <mat-label>{{ title }}</mat-label>
    <input matInput [formControl]="searchControl" />
    <mat-icon *ngIf="!searchControl.value" matSuffix>search</mat-icon>
    <mat-icon *ngIf="searchControl.value" (click)="restartInput()" matSuffix
      >close</mat-icon
    >
  </mat-form-field>
</ng-container>
