import { BrowserModule } from '@angular/platform-browser';
import { LOCALE_ID, NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SharedModule } from './shared/shared.module';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NewsModule } from './news/news.module';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import {
  HttpClient,
  HttpClientModule,
  HTTP_INTERCEPTORS,
} from '@angular/common/http';
import { ProgramModule } from './program/program.module';
import { OtherPagesModule } from './other-pages/other-pages.module';
import { NotificationsModule } from './notifications/notifications.module';
import { VolunteersModule } from './volunteers/volunteers.module';
import { LoginModule } from './login/login.module';
import { registerLocaleData } from '@angular/common';
import localeCs from '@angular/common/locales/cs';
import localeEn from '@angular/common/locales/en';
import { AuthInterceptor } from 'src/app/core/interceptors/auth.interceptor';
import { HttpErrorInterceptor } from 'src/app/core/interceptors/http-error.interceptor';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { GameModule } from './game/game.module';
import { DialogWindowComponent } from 'src/app/shared/component/dialog-window/dialog-window.component';
import { OrganizersModule } from './modules/organizers/organizers.module';
import { ScheduleModule } from './schedule/schedule.module';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { CoreModule } from './core/core.module';
import { GeneralModule } from './modules/general/general.module';
import { LanguageService } from './shared/services/language/language.service';
import { ProgramsModule } from './modules/programs/programs.module';

registerLocaleData(localeCs);
registerLocaleData(localeEn);

export function createTranslateLoader(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    SharedModule,
    BrowserAnimationsModule,
    MatTabsModule,
    MatToolbarModule,
    NewsModule,
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient],
      },
    }),
    ProgramModule,
    OtherPagesModule,
    NotificationsModule,
    VolunteersModule,
    LoginModule,
    MatProgressSpinnerModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatSnackBarModule,
    GameModule,
    OrganizersModule,
    ScheduleModule,
    CKEditorModule,
    CoreModule,
    GeneralModule,
    ProgramsModule,
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpErrorInterceptor,
      multi: true,
    },
    {
      provide: MAT_DATE_LOCALE,
      deps: [LanguageService],
      useFactory: (languageService) => languageService.getActiveLanguage(),
    },
    {
      provide: LOCALE_ID,
      deps: [LanguageService],
      useFactory: (languageService) => languageService.getActiveLanguage(),
    },
  ],
  bootstrap: [AppComponent],
  entryComponents: [DialogWindowComponent],
})
export class AppModule {}
