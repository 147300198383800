import { Injectable } from '@angular/core';
import { marker } from '@biesbjerg/ngx-translate-extract-marker';
import { ITableColumn } from '../../model/tableColumns.model';

@Injectable({
  providedIn: 'root',
})
export class TableColumnsService {
  private readonly columns = {
    title: 'title',
    type: 'type',
    date: 'date',
    name: 'name',
    time: 'time',
  };
  constructor() {}

  getTableColumns(type: string): string[] {
    const news = [this.columns.title, this.columns.type, this.columns.date];
    const program = [
      this.columns.name,
      this.columns.time,
      this.columns.date,
      this.columns.type,
    ];
    switch (type) {
      case 'news':
        return news;
      case 'program':
        return program;
      default:
        return [];
    }
  }
}
