<!-- <div class="app-detail-card-wrapper">
  <div class="app-detail-card-content">
    <ng-content></ng-content>
  </div>
</div> -->

<div class="app-detail-card-wrapper">
  <div class="app-detail-card-content" [style.width.px]="width">
    <ng-content></ng-content>
  </div>
</div>
