import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { TableColumnsService } from './services/table-columns/table-columns.service';
import { ActivatedRoute, Router } from '@angular/router';
import { IType } from 'src/app/news/model/type.model';
import { marker } from '@biesbjerg/ngx-translate-extract-marker';
import { PermissonService } from 'src/app/api/service/permission/permisson.service';
import { IPermisson } from 'src/app/api/model/permisson.model';
import { dateFormat } from 'src/app/shared/utils/dateFormatter.util';
import { DialogWindowComponent } from '../dialog-window/dialog-window.component';
import { DialogQuestionComponent } from '../dialog-question/dialog-question.component';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { IPreference } from 'src/app/api/model/preference.model';
import { LanguageService } from '../../services/language/language.service';
import { ConfirmDialogComponent } from '../dialogs/confirm-dialog/confirm-dialog.component';
import { TranslateService } from '@ngx-translate/core';
import { DialogService } from '../../services/dialog/dialog.service';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-dynamic-table',
  templateUrl: './dynamic-table.component.html',
  styleUrls: ['./dynamic-table.component.scss'],
})
export class DynamicTableComponent implements OnInit {
  @Input() data: any;
  @Input() isReadOnly = false;
  @Input() type: string;
  @Input() scheduleType: string;
  @Input() types: IType[];
  @Input() columns: string[];
  @Input() preferences: IPreference[];
  @Output() delete = new EventEmitter<void>();
  @Output() id = new EventEmitter<any>();
  @Output() deleteItem = new EventEmitter<any>();
  @Output() changeVisibility = new EventEmitter<any>();
  dataSource: MatTableDataSource<any>;
  displayedColumns: string[] = [];

  constructor(
    private tableColumnsService: TableColumnsService,
    private router: Router,
    private permissonService: PermissonService,
    private dialog: MatDialog,
    private activatedRoute: ActivatedRoute,
    private languageService: LanguageService,
    private translate: TranslateService,
    private dialogService: DialogService
  ) {}

  ngOnInit(): void {
    this.initTableData();
  }

  canCheck(value): boolean {
    if (value !== undefined) {
      return value;
    }
    return true;
  }

  initTableData(): void {
    this.displayedColumns = this.tableColumnsService.getTableColumns(this.type);
  }

  isDisplayed(key: string | number, id: string | number): boolean {
    const activeLanguageCode = this.languageService.getActiveLanguage();
    const type = this.activatedRoute.snapshot.queryParamMap.get('type');

    if (!(type === 'wax_skate' || type === 'wax_classic')) {
      key = `${activeLanguageCode};${key}`;
    }

    let RESULT: string | number;
    if (key === undefined && Number(id) && type) {
      RESULT = type + '_' + id;
    } else {
      RESULT = key;
    }
    return this.preferences.some((preference) => preference.key === RESULT);
  }

  typeName(id: number): string {
    return this.types.find((type) => type.id === id).value;
  }

  openDeleteProgramDialog(
    programTitle: string
  ): MatDialogRef<ConfirmDialogComponent> {
    return this.dialogService.openConfirmDialog({
      danger: true,
      mainText: this.translate.instant(marker('CORE.ALERT.DELETE_ITEM.TITLE')),
      subText: this.translate.instant(
        marker('CORE.ALERT.DELETE_ITEM.SUBTITLE'),
        {
          itemTitle: programTitle,
        }
      ),
      cancelText: this.translate.instant(marker('CORE.BUTTON.BACK')),
      confirmText: this.translate.instant(marker('CORE.DELETE')),
      showClose: true,
      imgPath: 'assets/image/delete.svg',
    });
  }

  public onDeleteRow(data: any): void {
    const dialogRef = this.openDeleteProgramDialog(data.title);

    dialogRef
      .afterClosed()
      .pipe(filter((confirmed) => confirmed))
      .subscribe({
        next: () => {
          this.deleteItem.emit(data);
        },
      });
  }

  public onVisibilityChange(statePage: any, element: any): void {
    this.changeVisibility.emit({ ...element, state: statePage });
  }

  hasPermission(actionType: string, element: any): boolean {
    const permision: IPermisson = {
      part: this.type,
      type: element.type || 1,
      action: actionType,
    };
    return this.permissonService.hasPermission(permision);
  }

  navigate({ id, type }): void {
    if (this.isReadOnly) {
      return;
    }
    const navigationExtras = {
      queryParams: {
        type: type ? type : null,
        scheduleType: this.scheduleType ? this.scheduleType : null,
      },
    };
    this.router.navigate([this.type, 'detail', id], navigationExtras);
  }

  convertToDateString(date: Date): string {
    return dateFormat(date);
  }

  getTitle(type: string): string {
    switch (type) {
      case 'news':
        return marker('NEWS.DETAIL.TITLE');
      case 'program':
        return marker('PROGRAM.TITLE-EVENT');
      case 'other-pages':
        return marker('OTHER-PAGES.TITLE');
      case 'waxes':
        return marker('OTHER-PAGES.TITLE-WAXES');
      default:
        return marker('');
    }
  }
}
