import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { InfoService } from '../../services/info/info.service';
import { IInfo, IInfoTranslate } from '../../model/other-pages.model';
import { OtherPagesService } from '../../services/orther-pages/other-pages.service';
import { ImagesService } from 'src/app/api/service/images/images.service';
import { ImageType } from 'src/app/api/model/image.model';
import { LanguageService } from 'src/app/shared/services/language/language.service';

@Component({
  selector: 'app-other-pages-detail',
  templateUrl: './other-pages-detail.component.html',
  styleUrls: ['./other-pages-detail.component.scss'],
})
export class OtherPagesDetailComponent implements OnInit {
  private readonly EVENT_ID = parseInt(localStorage.getItem('eventId'), 10);
  private PAGE_TYPE: string;
  private deletedImagesId: number[] = [];
  private otherPage: IInfo;

  formGroup: FormGroup;
  loading = false;

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private infoService: InfoService,
    private otherPagesService: OtherPagesService,
    private activatedRoute: ActivatedRoute,
    private imagesService: ImagesService,
    private languageService: LanguageService
  ) {
    this.formGroup = this.formBuilder.group({
      gallery: this.formBuilder.array([]),
      page: this.formBuilder.group({
        inserted: [''],
        updated: [''],
        id: [''],
        type: [''],
        title: [''],
        description: [''],
        link: [''],
      }),
    });
  }

  ngOnInit(): void {
    this.initOtherPage();
  }

  initOtherPage(): void {
    this.PAGE_TYPE = this.activatedRoute.snapshot.paramMap.get('id');
    const activeLanguageCode = this.languageService.getActiveLanguage();
    const translatedPage: IInfoTranslate = this.getInitialTranslatedObject();
    const defaultPage = this.getInitialObject();

    this.infoService.getInfoPages(this.EVENT_ID).subscribe((pages) => {
      const foundPage = pages.find((page) => page.type === this.PAGE_TYPE);

      this.otherPage = foundPage ?? defaultPage;

      if (foundPage) {
        translatedPage.id = foundPage.id;
        translatedPage.inserted = foundPage.inserted;
        translatedPage.updated = foundPage.updated;
        translatedPage.type = foundPage.type;
        translatedPage.description = foundPage.description[activeLanguageCode];
        translatedPage.link = foundPage.link[activeLanguageCode];
        translatedPage.title = foundPage.title[activeLanguageCode];
      } else {
        translatedPage.id = defaultPage.id;
        translatedPage.inserted = defaultPage.inserted;
        translatedPage.updated = defaultPage.updated;
        translatedPage.type = defaultPage.type;
        translatedPage.description =
          defaultPage.description[activeLanguageCode];
        translatedPage.link = defaultPage.link[activeLanguageCode];
        translatedPage.title = defaultPage.title[activeLanguageCode];
      }

      this.formGroup.get('page').setValue(translatedPage);
      this.initImageGallery();
    });
  }

  navigate(): void {
    this.router.navigate(['other-pages', 'list']);
  }

  setOtherPageObj(): void {
    const activeLanguageCode = this.languageService.getActiveLanguage();
    const info = this.formGroup.get('page').value as IInfoTranslate;

    this.otherPage.id = info.id;
    this.otherPage.inserted = info.inserted;
    this.otherPage.updated = info.updated;
    this.otherPage.type = info.type;
    this.otherPage.description[activeLanguageCode] = info.description;
    this.otherPage.link[activeLanguageCode] = info.link;
    this.otherPage.title[activeLanguageCode] = info.title;
  }

  save(): void {
    const activeLanguageCode = this.languageService.getActiveLanguage();
    this.setOtherPageObj();
    this.changeLoadingState();

    this.infoService
      .createOrUpdate(
        this.EVENT_ID,
        this.otherPage,
        this.PAGE_TYPE,
        this.getUploadedImages(),
        ImageType.OtherInfo,
        this.deletedImagesId,
        activeLanguageCode
      )
      .subscribe(() => {
        this.changeLoadingState();
        this.navigate();
      });
  }

  getUploadedImages(): File[] {
    const galleryFiles: File[] = [];
    this.formGroup.get('gallery').value.forEach((value) => {
      if (value.image instanceof File) {
        galleryFiles.push(value.image);
      }
    });
    return galleryFiles;
  }

  initImageGallery(): void {
    const activeLanguageCode = this.languageService.getActiveLanguage();
    this.loading = true;
    const PAGE = this.formGroup.get('page').value;
    this.imagesService
      .getImages(PAGE?.id, ImageType.OtherInfo, activeLanguageCode)
      .subscribe((images) => {
        images.forEach(() => {
          this.addFormGroup('gallery');
        });
        this.formGroup.get('gallery').setValue(images);
        this.loading = false;
      });
  }

  addFormGroup(type: string): void {
    (this.formGroup.get(type) as FormArray).push(this.createGroup(type));
  }

  createGroup(type: string): FormGroup {
    switch (type) {
      case 'gallery':
        return this.formBuilder.group({
          inserted: [''],
          updated: [''],
          id: [''],
          lang: [''],
          orderBy: [''],
          type: [''],
          image: [''],
        });
    }
  }

  changeLoadingState(): void {
    this.loading = !this.loading;
  }

  getFormArray(type: string): FormArray {
    return this.formGroup.get(type) as FormArray;
  }

  deleteFormGroup(type: string, index: number): void {
    (this.formGroup.get(type) as FormArray).removeAt(index);
  }

  deleteIndex(index: number, type: string): void {
    const result = this.formGroup.get(type).value;
    const filteredResult = result.filter((info) => info !== result[index]);
    this.addDeleteIdByType(result[index]?.id, type);
    this.deleteFormGroup(type, index);
    this.formGroup.get(type).setValue(filteredResult);
  }

  addDeleteIdByType(id: number, type: string): void {
    switch (type) {
      case 'gallery':
        this.deletedImagesId.push(id);
        break;
    }
  }

  getPageTitle(): string {
    return this.otherPagesService.getPagesTitles(this.PAGE_TYPE);
  }

  getInitialTranslatedObject(): IInfoTranslate {
    const DEFAULT_VALUES = {
      inserted: '',
      updated: '',
      id: 0,
      type: this.PAGE_TYPE,
      title: '',
      description: '',
      link: '',
    };
    return DEFAULT_VALUES;
  }

  getInitialObject(): IInfo {
    const DEFAULT_VALUES = {
      inserted: '',
      updated: '',
      id: 0,
      type: this.PAGE_TYPE,
      title: {
        cs: '',
        en: '',
      },
      description: {
        cs: '',
        en: '',
      },
      link: {
        cs: '',
        en: '',
      },
    };
    return DEFAULT_VALUES;
  }
}
