import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import {
  IBuildVerion,
  IBuildVerionPost,
} from '../../model/authentification.model';

@Injectable({
  providedIn: 'root',
})
export class AuthentificationService {
  private readonly basePath = environment.apiUrl;

  constructor(private httpClient: HttpClient) {}

  getBuildVersion(): Observable<IBuildVerion[]> {
    return this.httpClient.get<IBuildVerion[]>(
      `${this.basePath}/version/getBuildVersion`
    );
  }

  setActiveVersion(buildVerion: IBuildVerionPost): Observable<IBuildVerion> {
    return this.httpClient.post<IBuildVerion>(
      `${this.basePath}/version/setActiveVersion`,
      buildVerion
    );
  }
}
