import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormGroup, FormBuilder, FormArray, Validators } from '@angular/forms';
import { TransportService } from '../../services/transport/transport.service';
import { FormsService } from 'src/app/shared/services/forms/forms.service';
import {
  IDeletedTransportItem,
  ITransportBus,
  ITransportBusTranslate,
  ITransportParkingDetail,
  ITransportParkingDetailTranslate,
  ITransportParkingInfo,
  ITransportParkingInfoTranslate,
  ITransportTaxi,
  ITransportTaxiTranslate,
} from '../../model/other-pages.model';
import { LanguageService } from 'src/app/shared/services/language/language.service';
import { TranslateService } from '@ngx-translate/core';
import { PROGRESS_DIALOG_ACTION_SAVING } from 'src/app/shared/constants/dialog.constants';
import { IComponentCanDeactivate } from 'src/app/core/model/can-deactive-guard.model';
import { ApiCallExtensionService } from 'src/app/shared/services/api-call-extension/api-call-extension.service';
import {
  dateFormat,
  dateTimeFormat,
  setDateWithTime,
  timeFormat,
} from 'src/app/shared/utils/dateFormatter.util';
import { IBusConnectionGroupIndex } from '../../model/bus.model';
import { IDateTimeForm } from 'src/app/shared/model/date-time-from.model';
import { TIME_REGEX } from 'src/app/shared/constants/time.constants';

@Component({
  selector: 'app-transport',
  templateUrl: './transport.component.html',
  styleUrls: ['./transport.component.scss'],
})
export class TransportComponent implements OnInit, IComponentCanDeactivate {
  private readonly EVENT_ID = parseInt(localStorage.getItem('eventId'), 10);
  data = false;
  loading = false;
  formGroup: FormGroup;

  parkingDetails: ITransportParkingDetail[] = [];
  taxiDetails: ITransportTaxi[] = [];
  parkingInfoDetails: ITransportParkingInfo[] = [];
  busInfoDetails: ITransportBus[] = [];

  deletedItems: IDeletedTransportItem[] = [
    {
      name: 'bus',
      deletedId: [],
    },
    {
      name: 'parking',
      deletedId: [],
    },
    {
      name: 'taxi',
      deletedId: [],
    },
    {
      name: 'parkingInfo',
      deletedId: [],
    },
  ];

  deletedParkingId: number[] = [];
  deletedParkingInfoId: number[] = [];
  deletedTaxiId: number[] = [];
  deletedBusId: number[] = [];

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private transportService: TransportService,
    private formsService: FormsService,
    private languageService: LanguageService,
    private translate: TranslateService,
    private apiCallExtensionService: ApiCallExtensionService
  ) {
    this.formGroup = this.formBuilder.group({
      parking: this.formBuilder.array([]),
      imageParking: [''],
      taxi: this.formBuilder.array([]),
      bus: this.formBuilder.array([]),
      parkingInfo: this.formBuilder.array([]),
    });
  }

  ngOnInit(): void {
    this.initParking();
    this.initTaxi();
    this.initBus();
    this.initParkingInfo();
  }

  canDeactivate(): boolean {
    return this.formGroup.untouched;
  }

  changeLoadingState(): void {
    this.loading = !this.loading;
  }

  onSaveClick(): void {
    this.formGroup.markAsUntouched();
    const activeLanguageCode = this.languageService.getActiveLanguage();
    const PARKING = this.getParkingObj();
    const PARKING_INFO = this.getParkingInfoObj();
    const TAXI = this.getTaxiObj();
    const BUS = this.getBusObj();
    const PARKING_MAP = this.formGroup.get('imageParking').value;
    this.transportService.deleteTransport(this.deletedItems).subscribe();

    this.apiCallExtensionService
      .extendApiCall(
        () =>
          this.transportService.createOrUpdate(
            this.EVENT_ID,
            PARKING,
            TAXI,
            BUS,
            PARKING_INFO,
            PARKING_MAP,
            activeLanguageCode
          ),
        this.translate.instant(PROGRESS_DIALOG_ACTION_SAVING)
      )
      .subscribe({
        next: () => {
          this.navigate();
        },
      });
  }

  formatDateTime(dateTimes: any[]): string[] {
    return dateTimes.map((dateTime) =>
      dateTimeFormat(setDateWithTime(dateTime.date, dateTime.time))
    );
  }

  onCancelClick(): void {
    this.navigate();
  }

  getBusObj(): ITransportBus[] {
    const activeLanguageCode = this.languageService.getActiveLanguage();
    const buses = this.formGroup.get('bus').value as ITransportBusTranslate[];
    const busInfoDetail: ITransportBus[] = [];

    this.busInfoDetails.forEach((busValue) => {
      const value = buses.find((bus) => bus.id === busValue.id && bus.id !== 0);
      if (value) {
        busInfoDetail.push({
          id: busValue.id,
          gpsLatBack: value.gpsLatBack,
          gpsLatThere: value.gpsLatThere,
          gpsLonBack: value.gpsLonBack,
          gpsLonThere: value.gpsLonThere,
          inserted: busValue.inserted,
          routeName: {
            cs:
              activeLanguageCode === 'cs'
                ? value.routeName
                : busValue.routeName.cs,
            en:
              activeLanguageCode === 'en'
                ? value.routeName
                : busValue.routeName.en,
          },
          updated: busValue.updated,
          connectionsBack: this.formatDateTime(value.connectionsBack),
          connectionsThere: this.formatDateTime(value.connectionsThere),
          linkBack: {
            cs:
              activeLanguageCode === 'cs'
                ? value.linkBack
                : busValue.linkBack.cs,
            en:
              activeLanguageCode === 'en'
                ? value.linkBack
                : busValue.linkBack.en,
          },
          linkThere: {
            cs:
              activeLanguageCode === 'cs'
                ? value.linkThere
                : busValue.linkThere.cs,
            en:
              activeLanguageCode === 'en'
                ? value.linkThere
                : busValue.linkThere.en,
          },
          name: {
            cs: activeLanguageCode === 'cs' ? value.name : busValue.name.cs,
            en: activeLanguageCode === 'en' ? value.name : busValue.name.en,
          },
          nameBack: {
            cs:
              activeLanguageCode === 'cs'
                ? value.nameBack
                : busValue.nameBack.cs,
            en:
              activeLanguageCode === 'en'
                ? value.nameBack
                : busValue.nameBack.en,
          },
          nameThere: {
            cs:
              activeLanguageCode === 'cs'
                ? value.nameThere
                : busValue.nameThere.cs,
            en:
              activeLanguageCode === 'en'
                ? value.nameThere
                : busValue.nameThere.en,
          },
          stopNameThere: {
            cs:
              activeLanguageCode === 'cs'
                ? value.stopNameThere
                : busValue.stopNameThere.cs,
            en:
              activeLanguageCode === 'en'
                ? value.stopNameThere
                : busValue.stopNameThere.en,
          },
          stopNameBack: {
            cs:
              activeLanguageCode === 'cs'
                ? value.stopNameBack
                : busValue.stopNameBack.cs,
            en:
              activeLanguageCode === 'en'
                ? value.stopNameBack
                : busValue.stopNameBack.en,
          },
        });
      }
    });

    buses.forEach((bus) => {
      if (bus.id === 0) {
        busInfoDetail.push({
          id: bus.id,
          gpsLatBack: bus.gpsLatBack,
          gpsLatThere: bus.gpsLatThere,
          gpsLonBack: bus.gpsLonBack,
          gpsLonThere: bus.gpsLonThere,
          inserted: bus.inserted,
          updated: bus.updated,
          connectionsBack: this.formatDateTime(bus.connectionsBack),
          connectionsThere: this.formatDateTime(bus.connectionsThere),
          linkBack: {
            cs: activeLanguageCode === 'cs' ? bus.linkBack : '',
            en: activeLanguageCode === 'en' ? bus.linkBack : '',
          },
          linkThere: {
            cs: activeLanguageCode === 'cs' ? bus.linkThere : '',
            en: activeLanguageCode === 'en' ? bus.linkThere : '',
          },
          name: {
            cs: activeLanguageCode === 'cs' ? bus.name : '',
            en: activeLanguageCode === 'en' ? bus.name : '',
          },
          nameBack: {
            cs: activeLanguageCode === 'cs' ? bus.nameBack : '',
            en: activeLanguageCode === 'en' ? bus.nameBack : '',
          },
          nameThere: {
            cs: activeLanguageCode === 'cs' ? bus.nameThere : '',
            en: activeLanguageCode === 'en' ? bus.nameThere : '',
          },
          stopNameBack: {
            cs: activeLanguageCode === 'cs' ? bus.stopNameBack : '',
            en: activeLanguageCode === 'en' ? bus.stopNameBack : '',
          },
          stopNameThere: {
            cs: activeLanguageCode === 'cs' ? bus.stopNameThere : '',
            en: activeLanguageCode === 'en' ? bus.stopNameThere : '',
          },
          routeName: {
            cs: activeLanguageCode === 'cs' ? bus.routeName : '',
            en: activeLanguageCode === 'en' ? bus.routeName : '',
          },
        });
      }
    });
    return busInfoDetail;
  }

  getParkingInfoObj(): ITransportParkingInfo[] {
    const activeLanguageCode = this.languageService.getActiveLanguage();
    const parkingInfo = this.formGroup.get('parkingInfo')
      .value as ITransportParkingInfoTranslate[];

    const parkingInfoDetail: ITransportParkingInfo[] = [];

    this.parkingInfoDetails.forEach((parkingValue) => {
      const value = parkingInfo.find(
        (info) => info.id === parkingValue.id && info.id !== 0
      );

      if (value) {
        parkingInfoDetail.push({
          id: parkingValue.id,
          inserted: parkingValue.inserted,
          updated: parkingValue.updated,
          description: {
            cs:
              activeLanguageCode === 'cs'
                ? value.description
                : parkingValue.description.cs,
            en:
              activeLanguageCode === 'en'
                ? value.description
                : parkingValue.description.en,
          },
          name: {
            cs: activeLanguageCode === 'cs' ? value.name : parkingValue.name.cs,
            en: activeLanguageCode === 'en' ? value.name : parkingValue.name.en,
          },
          warning: {
            cs:
              activeLanguageCode === 'cs'
                ? value.warning
                : parkingValue.warning.cs,
            en:
              activeLanguageCode === 'en'
                ? value.warning
                : parkingValue.warning.en,
          },
        });
      }
    });

    parkingInfo.forEach((info) => {
      if (info.id === 0) {
        parkingInfoDetail.push({
          id: info.id,
          inserted: info.inserted,
          updated: info.updated,
          description: {
            cs: activeLanguageCode === 'cs' ? info.description : '',
            en: activeLanguageCode === 'en' ? info.description : '',
          },
          name: {
            cs: activeLanguageCode === 'cs' ? info.name : '',
            en: activeLanguageCode === 'en' ? info.name : '',
          },
          warning: {
            cs: activeLanguageCode === 'cs' ? info.warning : '',
            en: activeLanguageCode === 'en' ? info.warning : '',
          },
        });
      }
    });

    return parkingInfoDetail;
  }

  getParkingObj(): ITransportParkingDetail[] {
    const activeLanguageCode = this.languageService.getActiveLanguage();
    const parking = this.formGroup.get('parking')
      .value as ITransportParkingDetailTranslate[];

    const parkingDetail: ITransportParkingDetail[] = [];

    this.parkingDetails.forEach((parkingValue) => {
      const value = parking.find(
        (park) => park.id === parkingValue.id && park.id !== 0
      );
      if (value) {
        parkingDetail.push({
          description: {
            cs:
              activeLanguageCode === 'cs'
                ? value.description
                : parkingValue.description.cs,
            en:
              activeLanguageCode === 'en'
                ? value.description
                : parkingValue.description.en,
          },
          gpsLat: value.gpsLat ? value.gpsLat : '',
          gpsLon: value.gpsLon ? value.gpsLon : '',
          id: parkingValue.id,
          inserted: parkingValue.inserted,
          link: {
            cs: activeLanguageCode === 'cs' ? value.link : parkingValue.link.cs,
            en: activeLanguageCode === 'en' ? value.link : parkingValue.link.en,
          },
          name: {
            cs: activeLanguageCode === 'cs' ? value.name : parkingValue.name.cs,
            en: activeLanguageCode === 'en' ? value.name : parkingValue.name.en,
          },
          updated: parkingValue.updated,
        });
      }
    });

    parking.forEach((parkingValue) => {
      if (parkingValue.id === 0) {
        parkingDetail.push({
          description: {
            cs: activeLanguageCode === 'cs' ? parkingValue.description : '',
            en: activeLanguageCode === 'en' ? parkingValue.description : '',
          },
          gpsLat: parkingValue.gpsLat,
          gpsLon: parkingValue.gpsLon,
          id: parkingValue.id,
          inserted: parkingValue.inserted,
          link: {
            cs: activeLanguageCode === 'cs' ? parkingValue.link : '',
            en: activeLanguageCode === 'en' ? parkingValue.link : '',
          },
          name: {
            cs: activeLanguageCode === 'cs' ? parkingValue.name : '',
            en: activeLanguageCode === 'en' ? parkingValue.name : '',
          },
          updated: parkingValue.updated,
        });
      }
    });

    return parkingDetail;
  }

  getTaxiObj(): ITransportTaxi[] {
    const activeLanguageCode = this.languageService.getActiveLanguage();
    const taxi = this.formGroup.get('taxi').value as ITransportTaxiTranslate[];
    const taxiDetail: ITransportTaxi[] = [];

    this.taxiDetails.forEach((taxiValue) => {
      const tax = taxi.find(
        (value) => value.id === taxiValue.id && value.id !== 0
      );

      if (tax) {
        taxiDetail.push({
          id: tax.id,
          inserted: tax.inserted,
          updated: tax.updated,
          mobile: {
            cs: activeLanguageCode === 'cs' ? tax.mobile : taxiValue.mobile.cs,
            en: activeLanguageCode === 'en' ? tax.mobile : taxiValue.mobile.en,
          },
          name: {
            cs: activeLanguageCode === 'cs' ? tax.name : taxiValue.name.cs,
            en: activeLanguageCode === 'en' ? tax.name : taxiValue.name.en,
          },
          phone: {
            cs: activeLanguageCode === 'cs' ? tax.phone : taxiValue.phone.cs,
            en: activeLanguageCode === 'en' ? tax.phone : taxiValue.phone.en,
          },
          web: {
            cs: activeLanguageCode === 'cs' ? tax.web : taxiValue.web.cs,
            en: activeLanguageCode === 'en' ? tax.web : taxiValue.web.en,
          },
        });
      }
    });

    taxi.forEach((value) => {
      if (value.id === 0) {
        taxiDetail.push({
          id: value.id,
          inserted: value.inserted,
          updated: value.updated,
          mobile: {
            cs: activeLanguageCode === 'cs' ? value.mobile : '',
            en: activeLanguageCode === 'en' ? value.mobile : '',
          },
          name: {
            cs: activeLanguageCode === 'cs' ? value.name : '',
            en: activeLanguageCode === 'en' ? value.name : '',
          },
          phone: {
            cs: activeLanguageCode === 'cs' ? value.phone : '',
            en: activeLanguageCode === 'en' ? value.phone : '',
          },
          web: {
            cs: activeLanguageCode === 'cs' ? value.web : '',
            en: activeLanguageCode === 'en' ? value.web : '',
          },
        });
      }
    });

    return taxiDetail;
  }

  changeDataState(): void {
    this.data = !this.data;
  }

  initParking(): void {
    const activeLanguageCode = this.languageService.getActiveLanguage();
    this.transportService
      .getTransportParking(this.EVENT_ID)
      .subscribe((parking) => {
        this.parkingDetails = parking.parking || [];
        const translatedParking: ITransportParkingDetailTranslate[] = [];

        parking.parking.forEach((parkingValue) => {
          translatedParking.push({
            description: parkingValue.description[activeLanguageCode],
            gpsLat: parkingValue.gpsLat,
            gpsLon: parkingValue.gpsLon,
            id: parkingValue.id,
            inserted: parkingValue.inserted,
            link: parkingValue.link[activeLanguageCode],
            name: parkingValue.name[activeLanguageCode],
            updated: parkingValue.updated,
          });
        });

        this.formGroup.patchValue(
          this.formsService.initFormGroup(
            translatedParking,
            'parking',
            this.formGroup
          )
        );

        if (parking?.image) {
          const foundImage = parking.image.find(
            (image) => image.lang === activeLanguageCode
          );

          if (foundImage) {
            this.formGroup.get('imageParking').setValue(foundImage.image);
          }
        }
        this.changeDataState();
      });
  }

  initTaxi(): void {
    const activeLanguageCode = this.languageService.getActiveLanguage();
    this.transportService.getTransportTaxi(this.EVENT_ID).subscribe((taxi) => {
      this.taxiDetails = taxi;
      const translatedTaxi: ITransportTaxiTranslate[] = [];

      taxi.forEach((taxiValue) => {
        translatedTaxi.push({
          id: taxiValue.id,
          inserted: taxiValue.inserted,
          updated: taxiValue.updated,
          mobile: taxiValue.mobile[activeLanguageCode],
          name: taxiValue.name[activeLanguageCode],
          phone: taxiValue.phone[activeLanguageCode],
          web: taxiValue.web[activeLanguageCode],
        });
      });
      this.formGroup.patchValue(
        this.formsService.initFormGroup(translatedTaxi, 'taxi', this.formGroup)
      );
    });
  }

  initParkingInfo(): void {
    const activeLanguageCode = this.languageService.getActiveLanguage();
    this.transportService
      .getTransportParkingInfo(this.EVENT_ID)
      .subscribe((parkingInfos) => {
        this.parkingInfoDetails = parkingInfos;
        const translatedInfosObj: ITransportParkingInfoTranslate[] = [];

        parkingInfos.forEach((info) => {
          translatedInfosObj.push({
            id: info.id,
            inserted: info.inserted,
            updated: info.updated,
            description: info.description[activeLanguageCode],
            name: info.name[activeLanguageCode],
            warning: info.warning[activeLanguageCode],
          });
        });

        this.formGroup.patchValue(
          this.formsService.initFormGroup(
            translatedInfosObj,
            'parkingInfo',
            this.formGroup
          )
        );
      });
  }

  initBus(): void {
    const activeLanguageCode = this.languageService.getActiveLanguage();
    this.transportService.getTransportBus(this.EVENT_ID).subscribe((buses) => {
      this.busInfoDetails = buses;
      const translatedBusesObj: ITransportBusTranslate[] = [];

      buses.forEach((bus) => {
        translatedBusesObj.push({
          connectionsBack: bus.connectionsBack,
          connectionsThere: bus.connectionsThere,
          gpsLatBack: bus.gpsLatBack,
          gpsLatThere: bus.gpsLatThere,
          gpsLonBack: bus.gpsLonBack,
          gpsLonThere: bus.gpsLonThere,
          id: bus.id,
          inserted: bus.inserted,
          linkBack: bus.linkBack[activeLanguageCode],
          linkThere: bus.linkThere[activeLanguageCode],
          name: bus.name[activeLanguageCode],
          nameBack: bus.nameBack[activeLanguageCode],
          nameThere: bus.nameThere[activeLanguageCode],
          stopNameBack: bus.stopNameBack[activeLanguageCode],
          stopNameThere: bus.stopNameThere[activeLanguageCode],
          updated: bus.updated,
          routeName: bus.routeName[activeLanguageCode],
        });
      });

      this.formGroup.patchValue(
        this.formsService.initBusesFormGroup(translatedBusesObj, this.formGroup)
      );
    });
  }

  createBusLinesFormArray(connections: string[]): FormArray {
    const connectionsFormArray: FormArray = this.formBuilder.array([]);

    connections.forEach((connection: string) => {
      connectionsFormArray.push(this.createBusLineFormGroup(connection));
    });

    return connectionsFormArray;
  }

  addConnectionThere(index: number): void {
    (
      (this.formGroup.get('bus') as FormArray)
        .at(index)
        .get('connectionsThere') as FormArray
    ).push(this.createDefaultBusLineFormGroup());
  }

  deleteConnectionThere(
    busConnectionGroupIndex: IBusConnectionGroupIndex
  ): void {
    (
      (this.formGroup.get('bus') as FormArray)
        .at(busConnectionGroupIndex.busIndex)
        .get('connectionsThere') as FormArray
    ).removeAt(busConnectionGroupIndex.connectionIndex);
  }

  deleteConnectionBack(
    busConnectionGroupIndex: IBusConnectionGroupIndex
  ): void {
    (
      (this.formGroup.get('bus') as FormArray)
        .at(busConnectionGroupIndex.busIndex)
        .get('connectionsBack') as FormArray
    ).removeAt(busConnectionGroupIndex.connectionIndex);
  }

  addConnectionBack(index: number): void {
    (
      (this.formGroup.get('bus') as FormArray)
        .at(index)
        .get('connectionsBack') as FormArray
    ).push(this.createDefaultBusLineFormGroup());
  }

  createBusLineFormGroup(dateTime: string): FormGroup {
    return this.formBuilder.group({
      date: [dateTime, [Validators.required]],
      time: [
        timeFormat(new Date(dateTime)),
        [Validators.pattern(TIME_REGEX), Validators.required],
      ],
    });
  }

  createDefaultBusLineFormGroup(): FormGroup {
    return this.formBuilder.group({
      date: [new Date(), [Validators.required]],
      time: [
        timeFormat(new Date()),
        [Validators.pattern(TIME_REGEX), Validators.required],
      ],
    });
  }

  navigate(): void {
    this.router.navigate(['other-pages', 'list']);
  }

  addBusFormGroup(): void {
    this.formGroup.patchValue(
      this.formsService.addBusFormGroup(this.formGroup)
    );
  }

  addFormGroup(type: string): void {
    this.formGroup.patchValue(
      this.formsService.addFormGroup(type, this.formGroup)
    );
  }

  getFormArray(type: string): FormArray {
    return this.formsService.getFormArray(type, this.formGroup);
  }

  deleteIndex(index: number, type: string): void {
    const result = this.formGroup.get(type).value;
    this.addDeleteIdByType(result[index]?.id, type);
    this.formsService.deleteIndex(index, type, this.formGroup);
  }

  addDeleteIdByType(id: number, type: string): void {
    this.deletedItems.forEach((item) => {
      if (item.name === type) {
        item.deletedId.push(id);
      }
    });
  }
}
