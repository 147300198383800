import { Injectable } from '@angular/core';
import { IType } from 'src/app/api/model/news-type.interface';
import { marker } from '@biesbjerg/ngx-translate-extract-marker';

@Injectable({
  providedIn: 'root',
})
export class TypesService {
  private readonly types: IType[] = [
    {
      id: 0,
      value: marker('NEWS.TYPES.ALL'),
    },
    {
      id: 1,
      value: marker('NEWS.TYPES.NEW'),
    },
    {
      id: 2,
      value: marker('NEWS.TYPES.ACTUALITY'),
    },
  ];

  constructor() {}

  get(): IType[] {
    return this.types;
  }
}
