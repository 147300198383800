import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { OtherPagesDetailComponent } from './components/other-pages-detail/other-pages-detail.component';
import { OtherPagesListComponent } from './components/other-pages-list/other-pages-list.component';
import { BaseMapComponent } from './components/base-map/base-map.component';
import { TransportComponent } from './components/transport/transport.component';
import { EnervitComponent } from './components/enervit/enervit.component';
import { WaxesComponent } from './components/waxes/waxes.component';

const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'list',
  },
  {
    path: 'list',
    pathMatch: 'full',
    component: OtherPagesListComponent,
  },
  {
    path: 'detail/baseMap',
    pathMatch: 'full',
    component: BaseMapComponent,
  },
  {
    path: 'detail/transport',
    pathMatch: 'full',
    component: TransportComponent,
  },
  {
    path: 'detail/enervit',
    pathMatch: 'full',
    component: EnervitComponent,
  },
  {
    path: 'detail/wax_skate',
    pathMatch: 'full',
    component: WaxesComponent,
  },
  {
    path: 'detail/wax_classic',
    pathMatch: 'full',
    component: WaxesComponent,
  },
  {
    path: 'detail/:id',
    pathMatch: 'full',
    component: OtherPagesDetailComponent,
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class OtherPagesRoutingModule {}
