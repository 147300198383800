<div class="mb4">
  <div class="flex justify-between">
    <div class="mb2 app-other-pages-title">
      {{ "OTHER-PAGES.TRANSPORT.TAXI.SECTION" | translate }}
      {{ index + 1 }}
    </div>
    <div>
      <app-icon-title
        (click)="getIndex.emit(index)"
        icon="delete"
      ></app-icon-title>
    </div>
  </div>

  <div class="mt2">
    <div class="mb3" [formGroup]="formGroup">
      <mat-form-field appearance="fill" class="app-form-field-full-width">
        <mat-label>{{
          "OTHER-PAGES.TRANSPORT.TAXI.TITLE" | translate
        }}</mat-label>
        <input formControlName="name" matInput />
      </mat-form-field>
    </div>
  </div>

  <div class="mt2">
    <div class="mb3" [formGroup]="formGroup">
      <mat-form-field appearance="fill" class="app-form-field-full-width">
        <mat-label>{{
          "OTHER-PAGES.TRANSPORT.TAXI.PHONE" | translate
        }}</mat-label>
        <input formControlName="phone" matInput />
      </mat-form-field>
    </div>
  </div>

  <div class="mt2">
    <div class="mb3" [formGroup]="formGroup">
      <mat-form-field appearance="fill" class="app-form-field-full-width">
        <mat-label>{{
          "OTHER-PAGES.TRANSPORT.TAXI.MOBILE" | translate
        }}</mat-label>
        <input formControlName="mobile" matInput />
      </mat-form-field>
    </div>
  </div>

  <div class="mt2">
    <div class="mb3" [formGroup]="formGroup">
      <mat-form-field appearance="fill" class="app-form-field-full-width">
        <mat-label>{{
          "OTHER-PAGES.TRANSPORT.TAXI.WEB" | translate
        }}</mat-label>
        <input formControlName="web" matInput />
      </mat-form-field>
    </div>
  </div>
</div>
