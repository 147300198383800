<div>
  <app-list-top-card [title]="'SCHEDULE.TITLE' | translate">
    <app-filter-panel
      [title]="'SCHEDULE.FILTERS.SEARCH' | translate"
      (search)="onSearch($event)"
    ></app-filter-panel>
  </app-list-top-card>

  <app-dynamic-table
    [data]="data"
    [type]="'other-pages'"
    [columns]="columns"
  ></app-dynamic-table>
</div>
<!-- <app-spinner *ngIf="data.length === 0"></app-spinner> -->
