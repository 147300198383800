<div>
  <app-back-panel [title]="'NEW.DETAIL.BACK' | translate"></app-back-panel>
  <app-list-top-card [title]="newDetail.title"> </app-list-top-card>
  <app-detail-card>
    <form [formGroup]="formGroup">
      <div>
        <b> {{ "NEWS.DETAIL.TYPE" | translate }}</b>
        <mat-radio-group class="app-news-detail-radio-buttons">
          <mat-radio-button
            *ngFor="let type of types"
            [value]="type.id"
            (change)="onChangeType(type)"
            class="app-news-detail-radio-buttons-button"
            >{{ type.value | translate }}</mat-radio-button
          >
        </mat-radio-group>

        <mat-form-field appearance="fill">
          <mat-label>{{ "NEWS.DETAIL.TITLE" | translate }}</mat-label>
          <textarea
            formControlName="title"
            class="app-textarea-title"
            matInput
            md-no-autogrow
          ></textarea>
        </mat-form-field>

        <div class="app-news-detail-image">
          <div class="app-news-detail-image-title">
            {{ "NEWS.DETAIL.IMAGE" | translate }} (375x150px)
            <mat-icon class="app-back-panel-icon">edit</mat-icon>
          </div>
          <div class="app-news-detail-image">
            <img [src]="imageURL" />
          </div>
        </div>

        <mat-form-field appearance="fill">
          <mat-label>{{ "NEWS.DETAIL.TEXT" | translate }}</mat-label>
          <textarea
            formControlName="text"
            class="app-textarea-text"
            matInput
            md-no-autogrow
          ></textarea>
        </mat-form-field>
      </div>
    </form>
  </app-detail-card>
  <app-footer (save)="save()"></app-footer>
</div>
