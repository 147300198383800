export enum OtherPages {
  CHARITY = 'charity',
  EXHIBITION = 'exhibition',
  SPECTATORS_SERVICE = 'spectators_service',
  SPONSOR = 'sponsor',
  HYGIENE_MEASURES = 'hygiene_measures',
  RACE_HISTORY = 'race_history',
  SOUVENIRS = 'souvenirs',
  EXTRAORDINARY_EVENTS = 'extraordinary_events',
  TEST_CENTER_KASTLE = 'test_center_kastle',
  BASE_MAP = 'baseMap',
  TRANSPORT = 'transport',
  ENERVIT = 'enervit',
  WAXES_CLASSIC = 'wax_classic',
  WAXES_SKATE = 'wax_skate',
  VIRTUAL_RACE = 'virtual_race',
  J50_NEWS = 'j50_news',
  SKITOUR_NEWS = 'skitour_news',
  CROSS_COUNTRY_SKI_TRAIL = 'cross_country_ski_trial',
}
