import { Injectable } from '@angular/core';
import { forkJoin, Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { IInfo } from '../../model/other-pages.model';
import { ImagesService } from 'src/app/api/service/images/images.service';
import { mergeMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class InfoService {
  private readonly basePath = environment.apiUrl;
  constructor(
    private httpClient: HttpClient,
    private imagesService: ImagesService
  ) {}

  getInfoPages(eventId: number): Observable<IInfo[]> {
    return this.httpClient.get<IInfo[]>(
      `${this.basePath}/event/${eventId}/pages`
    );
  }

  createOrUpdate(
    eventId: number,
    info: IInfo,
    infoType: string,
    galleryFiles: File[],
    type: string,
    deletedImagesId: number[],
    activeLanguageCode: string
  ): Observable<any> {
    return this.createOrUpdateInfo(eventId, info, infoType).pipe(
      mergeMap((response) => {
        return forkJoin([
          this.imagesService.uploadImages(
            eventId,
            response.id,
            type,
            galleryFiles,
            activeLanguageCode
          ),
          this.imagesService.deleteImages(eventId, deletedImagesId),
        ]);
      })
    );
  }

  createOrUpdateInfo(
    eventId: number,
    info: IInfo,
    infoType: string
  ): Observable<IInfo> {
    return this.httpClient.post<IInfo>(
      `${this.basePath}/event/${eventId}/page`,
      info,
      {
        params: {
          type: infoType.toUpperCase(),
        },
      }
    );
  }
}
