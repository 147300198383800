<div class="mb4">
  <app-detail-card *ngIf="!loading">
    <div class="mb3" [formGroup]="formGroup.get('event')">
      <mat-form-field appearance="fill" class="app-detail-race-form-field">
        <mat-label>{{ "PROGRAM.EVENT.TITLE" | translate }}</mat-label>
        <input formControlName="title" matInput />
      </mat-form-field>
    </div>

    <div class="mb3" [formGroup]="formGroup.get('event')">
      <app-image-input
        [control]="formGroup.get('event').get('sponsorImg')"
        [width]="24"
        [height]="24"
        [title]="'PROGRAM.RACE.IMAGE.SPONSOR' | translate"
      ></app-image-input>
    </div>

    <div class="mb4 mt4">
      <app-image-gallery
        *ngFor="let gallery of getFormArray('gallery').controls; let i = index"
        [formGroup]="gallery"
        [index]="i"
        (add)="addFormGroup('gallery')"
        (deleteImage)="deleteIndex($event, 'gallery')"
      ></app-image-gallery>
      <div>
        <app-add-section-wrapper>
          <div
            class="flex items-center app-gallery-add"
            (click)="addFormGroup('gallery')"
          >
            <mat-icon class="mr1">add</mat-icon>
            {{ "PROGRAM.RACE.GALLERY.ADD" | translate }}
          </div>
        </app-add-section-wrapper>
      </div>
    </div>

    <div class="mb3 flex fit justify-between">
      <div [formGroup]="formGroup.get('event')">
        <mat-form-field appearance="fill">
          <mat-label>{{ "PROGRAM.EVENT.DATE_FROM" | translate }}</mat-label>
          <input
            matInput
            [matDatepicker]="picker"
            formControlName="dateTimeFrom"
          />
          <mat-datepicker-toggle matSuffix [for]="picker">
            <mat-icon matDatepickerToggleIcon>date_range</mat-icon>
          </mat-datepicker-toggle>
          <mat-datepicker #picker></mat-datepicker>
        </mat-form-field>
      </div>

      <div [formGroup]="formGroup.get('time')">
        <mat-form-field appearance="fill">
          <mat-label>{{ "PROGRAM.EVENT.TIME" | translate }}</mat-label>
          <input formControlName="timeFrom" matInput type="time" />
        </mat-form-field>
        <div class="pl1 mb1">
          <mat-error *ngIf="formGroup.get('time').hasError('timeFrom')">
            {{ "CORE.ERROR.TIME-FORMAT" | translate }}
          </mat-error>
        </div>
      </div>
    </div>

    <div class="mb3 flex fit justify-between">
      <div [formGroup]="formGroup.get('event')">
        <mat-form-field appearance="fill">
          <mat-label>{{ "PROGRAM.EVENT.DATE_TO" | translate }}</mat-label>
          <input
            matInput
            [matDatepicker]="dateTimeTo"
            formControlName="dateTimeTo"
          />
          <mat-datepicker-toggle matSuffix [for]="dateTimeTo">
            <mat-icon matDatepickerToggleIcon>date_range</mat-icon>
          </mat-datepicker-toggle>
          <mat-datepicker #dateTimeTo></mat-datepicker>
        </mat-form-field>
      </div>

      <div [formGroup]="formGroup.get('time')">
        <mat-form-field appearance="fill">
          <mat-label>{{ "PROGRAM.EVENT.TIME" | translate }}</mat-label>
          <input formControlName="timeTo" matInput type="time" />
        </mat-form-field>
        <div class="pl1 mb1">
          <mat-error *ngIf="formGroup.get('time').hasError('timeTo')">
            {{ "CORE.ERROR.TIME-FORMAT" | translate }}
          </mat-error>
        </div>
      </div>
    </div>

    <div
      class="mb3 flex fit justify-between"
      [formGroup]="formGroup.get('event')"
    >
      <mat-form-field appearance="fill" class="app-detail-race-form-field">
        <mat-label>{{ "PROGRAM.EVENT.PLACE" | translate }}</mat-label>
        <input formControlName="place" matInput />
      </mat-form-field>
    </div>

    <div class="mb4">
      <mat-label>{{ "PROGRAM.EVENT.ABOUT" | translate }}</mat-label>
      <app-text-editor
        [formGroup]="formGroup.get('event')"
        editorControl="about"
      ></app-text-editor>
    </div>

    <div class="mb2 mt4" [formGroup]="formGroup.get('event')">
      <mat-form-field appearance="fill" class="app-detail-race-form-field">
        <mat-label>{{ "CORE.FACEBOOK.LINK" | translate }}</mat-label>
        <input formControlName="fbLink" matInput />
      </mat-form-field>
    </div>

    <div class="mb2" [formGroup]="formGroup.get('event')">
      <mat-form-field appearance="fill" class="app-detail-race-form-field">
        <mat-label>{{ "CORE.IMPORTANT_INFO.LINK" | translate }}</mat-label>
        <input formControlName="importantInfoUrl" matInput />
      </mat-form-field>
    </div>

    <div class="mb2" [formGroup]="formGroup.get('event')">
      <mat-form-field appearance="fill" class="app-detail-race-form-field">
        <mat-label>{{ "CORE.INSTAGRAM.LINK" | translate }}</mat-label>
        <input formControlName="igLink" matInput />
      </mat-form-field>
    </div>

    <div class="mb2" [formGroup]="formGroup.get('event')">
      <mat-form-field appearance="fill" class="app-detail-race-form-field">
        <mat-label>{{ "CORE.DIET.LINK" | translate }}</mat-label>
        <input formControlName="stravaLink" matInput />
      </mat-form-field>
    </div>

    <div class="mb2" [formGroup]="formGroup.get('event')">
      <mat-form-field appearance="fill" class="app-detail-race-form-field">
        <mat-label>{{ "CORE.MAPS.LINK" | translate }}</mat-label>
        <input formControlName="mapsLink" matInput />
      </mat-form-field>
    </div>
  </app-detail-card>

  <app-footer
    [disabled]="formGroup.invalid"
    (save)="onSaveClick()"
    (cancel)="onCancelClick()"
  ></app-footer>
</div>
