import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { IEvent } from 'src/app/api/model/events.model';
import { IEventVisibility } from '../../model/event.model';

@Component({
  selector: 'app-sorting-settings',
  templateUrl: './sorting-settings.component.html',
  styleUrls: ['./sorting-settings.component.scss'],
})
export class SortingSettingsComponent implements OnInit {
  @Input() events: IEvent[];
  @Output() eventsChange = new EventEmitter<IEvent[]>();
  @Output() visibilityChange = new EventEmitter<IEventVisibility>();

  sortedEvents: IEvent[];

  constructor() {}

  ngOnInit(): void {
    this.sortedEvents = [...this.events];
  }

  drop(dragDropEvent: CdkDragDrop<string[]>): void {
    this.reorderResults(dragDropEvent);
  }

  reorderResults(dragDropEvent: CdkDragDrop<string[]>): void {
    moveItemInArray(
      this.sortedEvents,
      dragDropEvent.previousIndex,
      dragDropEvent.currentIndex
    );

    this.eventsChange.emit(this.sortedEvents);
  }

  onVisibilityChange(isVisible: boolean, event: IEvent): void {
    this.visibilityChange.emit({
      isVisible,
      event,
    });
  }

  trackResults(index: number, event: IEvent): unknown {
    return event.id;
  }
}
