<app-dialog-layout [showActions]="true" [showClose]="data.showClose">
  <ng-container content>
    <div class="pb3">
      <app-simple-dialog-content
        [mainText]="data.mainText"
        [imgPath]="data.imgPath"
        [subText]="data.subText"
      ></app-simple-dialog-content>
    </div>
  </ng-container>

  <ng-container actions>
    <app-prompt-buttons
      [cancelText]="data.cancelText || ''"
      [confirmText]="data.confirmText || ''"
      [danger]="data.danger"
      (cancel)="dialogRef.close(false)"
      (confirm)="dialogRef.close(true)"
    >
    </app-prompt-buttons>
  </ng-container>
</app-dialog-layout>
