import { Component, OnInit, OnDestroy } from '@angular/core';
import { ProgramService } from '../../services/program.service';
import {
  IProgramEventPost,
  IProgramRacePost,
  IType,
  Program,
} from '../../models/program.model';
import { ActivatedRoute } from '@angular/router';
import {
  getDefaultProgramByType,
  prefillProgramAttributes,
} from '../../utils/default-program.util';
import { LanguageService } from 'src/app/shared/services/language/language.service';
import { PROGRAM_TYPES } from '../../constants/program.constants';
import { FormGroup } from '@angular/forms';
import { ProgramFormGroupBuildersService } from 'src/app/shared/services/form-group-builders/program-form-group-builders.service';
import { IComponentCanDeactivate } from 'src/app/core/model/can-deactive-guard.model';
import { Observable, Subscription, forkJoin } from 'rxjs';
import { mergeMap, tap } from 'rxjs/operators';
import { IProgramAttributes } from '../../../../shared/model/program-event-attributes.model';
import { EventService } from 'src/app/api/services/event/event.service';

@Component({
  selector: 'app-program-detail',
  templateUrl: './program-detail.component.html',
  styleUrls: ['./program-detail.component.scss'],
})
export class ProgramDetailComponent
  implements OnInit, IComponentCanDeactivate, OnDestroy
{
  types: IType[];
  programType: number;
  program: IProgramEventPost | IProgramRacePost;
  private readonly EVENT_ID = parseInt(localStorage.getItem('eventId'), 10);
  private scheduleType: string;
  private programId: number;
  eventFormGroup: FormGroup;
  raceFormGroup: FormGroup;
  private subs = new Subscription();

  constructor(
    private programService: ProgramService,
    private activatedRoute: ActivatedRoute,
    private languageService: LanguageService,
    private programFormGroupBuildersService: ProgramFormGroupBuildersService,
    private eventsService: EventService
  ) {}

  ngOnInit(): void {
    this.initFormGroup();
    this.scheduleType =
      this.activatedRoute.snapshot.queryParamMap.get('scheduleType');
    this.initProgramDetail();
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  canDeactivate(): boolean {
    return this.eventFormGroup.untouched && this.raceFormGroup.untouched;
  }

  getProgramType(): string {
    return this.programType === 1
      ? Program.EVENT_SCHEDULE
      : Program.RACE_SCHEDULE;
  }

  initFormGroup(): void {
    this.eventFormGroup =
      this.programFormGroupBuildersService.createProgramEventDetailFormGroup();

    this.raceFormGroup =
      this.programFormGroupBuildersService.createProgramRaceDetailFormGroup();
  }

  getProgramTitle(): string {
    const activeLanguageCode = this.languageService.getActiveLanguage();
    return this.program.title[activeLanguageCode];
  }

  initProgram(activeLanguageCode: string): void {
    this.subs.add(
      this.activatedRoute.queryParams
        .pipe(
          tap((data) => {
            this.programType = parseInt(data?.type, 10);
            this.types = PROGRAM_TYPES.filter((type) => type.id !== 0);
          }),
          mergeMap(() =>
            forkJoin([
              this.getProgramDetail(activeLanguageCode),
              this.eventsService.getEventSharedPreferences(this.EVENT_ID),
            ])
          )
        )
        .subscribe({
          next: ([program, programAttributes]) => {
            this.program =
              program[0] ||
              this.getPrefilledProgramAttributes(programAttributes);
          },
        })
    );
  }

  private getPrefilledProgramAttributes(
    programAttributes: IProgramAttributes
  ): IProgramEventPost | IProgramRacePost {
    const newProgram = getDefaultProgramByType(
      this.getProgramType(),
      this.scheduleType.toLowerCase()
    );

    return prefillProgramAttributes(newProgram, programAttributes);
  }

  getProgramDetail(
    activeLanguageCode: string
  ): Observable<IProgramRacePost | IProgramEventPost> {
    return this.programService.getProgramDetail(
      this.EVENT_ID,
      this.programId,
      this.getProgramType(),
      this.scheduleType,
      activeLanguageCode
    );
  }

  initProgramDetail(): void {
    const activeLanguageCode = this.languageService.getActiveLanguage();
    this.programId = parseInt(
      this.activatedRoute.snapshot.paramMap.get('programId'),
      10
    );
    this.initProgram(activeLanguageCode);
  }
}
