import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormGroup, FormBuilder, FormArray } from '@angular/forms';
import { EnervitService } from '../../services/enervit/enervit.service';
import {
  IEnervitInfo,
  IEnervitInfoTranslate,
  IEnervitProduct,
  IEnervitProductTranslation,
} from '../../model/other-pages.model';
import { LanguageService } from 'src/app/shared/services/language/language.service';
import { forkJoin, Observable } from 'rxjs';
import { IComponentCanDeactivate } from 'src/app/core/model/can-deactive-guard.model';
import { IEnvervitImage, EnervitImageType } from '../../model/enervit.model';
import { TranslateService } from '@ngx-translate/core';
import { PROGRESS_DIALOG_ACTION_SAVING } from 'src/app/shared/constants/dialog.constants';
import { ApiCallExtensionService } from 'src/app/shared/services/api-call-extension/api-call-extension.service';

@Component({
  selector: 'app-enervit',
  templateUrl: './enervit.component.html',
  styleUrls: ['./enervit.component.scss'],
})
export class EnervitComponent implements OnInit, IComponentCanDeactivate {
  private readonly EVENT_ID = parseInt(localStorage.getItem('eventId'), 10);
  loading = false;
  formGroup: FormGroup;
  deletedProductsId: number[] = [];
  deletedInfosId: number[] = [];

  products: IEnervitProduct[] = [];
  infos: IEnervitInfo[] = [];

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private enervitService: EnervitService,
    private languageService: LanguageService,
    private translate: TranslateService,
    private apiCallExtensionService: ApiCallExtensionService
  ) {
    this.formGroup = this.formBuilder.group({
      products: this.formBuilder.array([]),
      WEEK_BEFORE: this.formBuilder.array([]),
      JUST_BEFORE: this.formBuilder.array([]),
      DURING_AND_AFTER: this.formBuilder.array([]),
      week_before_image: [''],
      just_before_image: [''],
      during_and_after: [''],
    });
  }

  ngOnInit(): void {
    this.initEnvervitProducts();
    this.initEnvervitInfo();
  }

  canDeactivate(): boolean {
    return this.formGroup.untouched;
  }

  updateEnervit(): Observable<unknown> {
    const activeLanguageCode = this.languageService.getActiveLanguage();
    const enervitImages = this.getEnervitImages();
    const info = this.getInfosObj();
    const products = this.getProductsObj();
    this.formGroup.markAsUntouched();

    return forkJoin([
      this.enervitService.deleteProductsWithImages(
        this.deletedProductsId,
        activeLanguageCode,
        this.products
      ),
      this.enervitService.deleteEvenrvitInfo(this.deletedInfosId),
      this.enervitService.saveEnervit(
        products,
        info,
        enervitImages,
        activeLanguageCode
      ),
    ]);
  }

  onSaveClick(): void {
    this.apiCallExtensionService
      .extendApiCall(
        () => this.updateEnervit(),
        this.translate.instant(PROGRESS_DIALOG_ACTION_SAVING)
      )
      .subscribe({
        next: () => {
          this.navigate();
        },
      });
  }

  onCancelClick(): void {
    this.navigate();
  }

  getEnervitImages(): IEnvervitImage[] {
    return [
      {
        type: EnervitImageType.WeekBefore,
        file: this.formGroup.get('week_before_image').value,
      },
      {
        type: EnervitImageType.JustBefore,
        file: this.formGroup.get('just_before_image').value,
      },
      {
        type: EnervitImageType.DuringAndAfter,
        file: this.formGroup.get('during_and_after').value,
      },
    ];
  }

  navigate(): void {
    this.router.navigate(['other-pages', 'list']);
  }

  getInfosObj(): IEnervitInfo[] {
    const activeLanguageCode = this.languageService.getActiveLanguage();
    const infos = this.formGroup
      .get('WEEK_BEFORE')
      .value.concat(this.formGroup.get('JUST_BEFORE').value)
      .concat(
        this.formGroup.get('DURING_AND_AFTER').value
      ) as IEnervitInfoTranslate[];

    const infosObj: IEnervitInfo[] = [];

    this.infos.forEach((info) => {
      const existsInfo = infos.find(
        (value) => value.id === info.id && value.id !== 0
      );

      if (existsInfo) {
        infosObj.push({
          id: existsInfo.id,
          inserted: existsInfo.inserted,
          type: existsInfo.type,
          updated: existsInfo.updated,
          description: {
            cs:
              activeLanguageCode === 'cs'
                ? existsInfo.description
                : info.description.cs,
            en:
              activeLanguageCode === 'en'
                ? existsInfo.description
                : info.description.en,
          },
          subtitle: {
            cs:
              activeLanguageCode === 'cs'
                ? existsInfo.subtitle
                : info.subtitle.cs,
            en:
              activeLanguageCode === 'en'
                ? existsInfo.subtitle
                : info.subtitle.en,
          },
          title: {
            cs: activeLanguageCode === 'cs' ? existsInfo.title : info.title.cs,
            en: activeLanguageCode === 'en' ? existsInfo.title : info.title.en,
          },
        });
      }
    });

    infos.forEach((info) => {
      if (info.id === 0) {
        infosObj.push({
          id: info.id,
          inserted: info.inserted,
          type: info.type,
          updated: info.updated,
          description: {
            cs: activeLanguageCode === 'cs' ? info.description : '',
            en: activeLanguageCode === 'en' ? info.description : '',
          },
          subtitle: {
            cs: activeLanguageCode === 'cs' ? info.subtitle : '',
            en: activeLanguageCode === 'en' ? info.subtitle : '',
          },
          title: {
            cs: activeLanguageCode === 'cs' ? info.title : '',
            en: activeLanguageCode === 'en' ? info.title : '',
          },
        });
      }
    });

    return infosObj;
  }

  getProductsObj(): IEnervitProduct[] {
    const activeLanguageCode = this.languageService.getActiveLanguage();
    const products = this.formGroup.get('products')
      .value as IEnervitProductTranslation[];
    const productObjs: IEnervitProduct[] = [];

    this.products.forEach((product) => {
      const existsProduct = products.find(
        (value) => value.id === product.id && value.id !== 0
      );

      if (existsProduct) {
        productObjs.push({
          id: existsProduct.id,
          inserted: existsProduct.inserted,
          updated: existsProduct.updated,
          type: existsProduct.type,
          description: {
            cs:
              activeLanguageCode === 'cs'
                ? existsProduct.description
                : product.description.cs,
            en:
              activeLanguageCode === 'en'
                ? existsProduct.description
                : product.description.en,
          },
          image: {
            cs:
              activeLanguageCode === 'cs'
                ? existsProduct.image
                : product.image.cs,
            en:
              activeLanguageCode === 'en'
                ? existsProduct.image
                : product.image.en,
          },
          link: {
            cs:
              activeLanguageCode === 'cs'
                ? existsProduct.link
                : product.link.cs,
            en:
              activeLanguageCode === 'en'
                ? existsProduct.link
                : product.link.en,
          },
          name: {
            cs:
              activeLanguageCode === 'cs'
                ? existsProduct.name
                : product.name.cs,
            en:
              activeLanguageCode === 'en'
                ? existsProduct.name
                : product.name.en,
          },
        });
      }
    });

    products.forEach((product) => {
      if (product.id === 0) {
        productObjs.push({
          id: product.id,
          inserted: product.inserted,
          updated: product.updated,
          type: product.type,
          description: {
            cs: activeLanguageCode === 'cs' ? product.description : '',
            en: activeLanguageCode === 'en' ? product.description : '',
          },
          image: {
            cs: activeLanguageCode === 'cs' ? product.image : '',
            en: activeLanguageCode === 'en' ? product.image : '',
          },
          link: {
            cs: activeLanguageCode === 'cs' ? product.link : '',
            en: activeLanguageCode === 'en' ? product.link : '',
          },
          name: {
            cs: activeLanguageCode === 'cs' ? product.name : '',
            en: activeLanguageCode === 'en' ? product.name : '',
          },
        });
      }
    });

    return productObjs;
  }

  initEnvervitProducts(): void {
    const activeLanguageCode = this.languageService.getActiveLanguage();
    this.enervitService.getEnervit().subscribe((products) => {
      products.forEach(() => {
        this.addProductFormGroup();
      });

      this.products = products;
      const translatedProducts: IEnervitProductTranslation[] = [];

      products.forEach((product) => {
        translatedProducts.push({
          id: product.id,
          updated: product.updated,
          inserted: product.inserted,
          type: product.type,
          description: product.description[activeLanguageCode],
          image: product.image[activeLanguageCode] ?? '',
          link: product.link[activeLanguageCode],
          name: product.name[activeLanguageCode],
        });
      });

      this.formGroup.get('products').setValue(translatedProducts);
    });
  }

  initEnvervitInfo(): void {
    const activeLanguageCode = this.languageService.getActiveLanguage();
    this.enervitService
      .getEnervitInfo(activeLanguageCode)
      .subscribe((infos) => {
        let before = [];
        let during = [];
        let just = [];

        this.formGroup
          .get('week_before_image')
          .setValue(
            infos.images.find(
              (image) =>
                image.type === 'week_before' &&
                image.lang === activeLanguageCode
            )?.image || ''
          );

        this.formGroup
          .get('just_before_image')
          .setValue(
            infos.images.find(
              (image) =>
                image.type === 'just_before' &&
                image.lang === activeLanguageCode
            )?.image || ''
          );

        this.formGroup
          .get('during_and_after')
          .setValue(
            infos.images.find(
              (image) =>
                image.type === 'during_and_after' &&
                image.lang === activeLanguageCode
            )?.image || ''
          );

        infos.infos.forEach((info) => {
          this.addInfoFormGroup(info.type);
        });

        this.infos = infos.infos.slice();

        const translatedInfos: IEnervitInfoTranslate[] =
          this.transformToInfoTranslateObj(this.infos);

        before = translatedInfos.filter((info) => info.type === 'WEEK_BEFORE');
        just = translatedInfos.filter((info) => info.type === 'JUST_BEFORE');
        during = translatedInfos.filter(
          (info) => info.type === 'DURING_AND_AFTER'
        );

        this.formGroup.get('WEEK_BEFORE').setValue(before);
        this.formGroup.get('JUST_BEFORE').setValue(just);
        this.formGroup.get('DURING_AND_AFTER').setValue(during);
        this.loading = true;
      });
  }

  transformToInfoTranslateObj(infos: IEnervitInfo[]): IEnervitInfoTranslate[] {
    const activeLanguageCode = this.languageService.getActiveLanguage();
    const translatedInfos: IEnervitInfoTranslate[] = [];

    infos.forEach((info) => {
      translatedInfos.push({
        id: info.id,
        inserted: info.inserted,
        updated: info.updated,
        type: info.type,
        description: info.description[activeLanguageCode],
        subtitle: info.subtitle[activeLanguageCode],
        title: info.title[activeLanguageCode],
      });
    });
    return translatedInfos;
  }

  addProductFormGroup(): void {
    (this.formGroup.get('products') as FormArray).push(
      this.createProductGroup()
    );
  }

  addInfoFormGroup(type: string): void {
    (this.formGroup.get(type) as FormArray).push(this.createInfoGroup(type));
  }

  deleteProductFormGroup(index: number): void {
    (this.formGroup.get('products') as FormArray).removeAt(index);
  }

  deleteInfoFormGroup(type: string, index: number): void {
    (this.formGroup.get(type) as FormArray).removeAt(index);
  }

  changeLoadingState(): void {
    this.loading = !this.loading;
  }

  deleteIndex(index: number): void {
    const products = this.formGroup.get('products').value;
    const filteredProducts = products.filter(
      (product) => product !== products[index]
    );

    this.deletedProductsId.push(products[index]?.id);
    this.deleteProductFormGroup(index);
    this.formGroup.get('products').setValue(filteredProducts);
  }

  deleteInfoIndex(index: number, type: string): void {
    const infos = this.formGroup.get(type).value;
    const filteredInfos = infos.filter((info) => info !== infos[index]);
    this.deletedInfosId.push(infos[index]?.id);
    this.deleteInfoFormGroup(type, index);
    this.formGroup.get(type).setValue(filteredInfos);
  }

  createProductGroup(): FormGroup {
    return this.formBuilder.group({
      description: [''],
      id: [0],
      image: [''],
      inserted: [''],
      name: [''],
      type: [''],
      updated: [''],
      link: [''],
    });
  }

  createInfoGroup(type = ''): FormGroup {
    return this.formBuilder.group({
      description: [''],
      id: [0],
      inserted: [''],
      subtitle: [''],
      title: [''],
      type: [type],
      updated: [''],
    });
  }

  getInfo(type: string): FormArray {
    return this.formGroup.get(type) as FormArray;
  }

  getProducts(): FormArray {
    return this.formGroup.get('products') as FormArray;
  }
}
