import {
  Component,
  OnInit,
  AfterViewInit,
  Output,
  EventEmitter,
  ComponentFactoryResolver,
  Inject,
  ViewChild,
  ViewContainerRef,
  ComponentRef,
  ChangeDetectorRef,
} from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-dialog-window',
  templateUrl: './dialog-window.component.html',
  styleUrls: ['./dialog-window.component.scss'],
})
export class DialogWindowComponent implements OnInit, AfterViewInit {
  // tslint:disable-next-line: no-output-native
  @Output() submit = new EventEmitter<void>();
  @Output() cancel = new EventEmitter<void>();
  text: string;
  @ViewChild('formContainer', { read: ViewContainerRef, static: false })
  child: ViewContainerRef;
  formRef: ComponentRef<any>;

  constructor(
    private resolver: ComponentFactoryResolver,
    private changeDetection: ChangeDetectorRef,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit(): void {}

  ngAfterViewInit(): void {
    const factory = this.resolver.resolveComponentFactory<any>(
      this.data.component
    );
    this.formRef = this.child.createComponent<any>(factory);

    if (this.data && this.data.componentData) {
      for (const inputKey of Object.keys(this.data.componentData)) {
        this.formRef.instance[inputKey] = this.data.componentData[inputKey];
      }
    }

    this.changeDetection.detectChanges();
  }
}
