<div *ngIf="!loading">
  <app-back-panel
    [title]="'OTHER-PAGES.PAGES.BACK' | translate"
  ></app-back-panel>

  <app-list-top-card [title]="'PAGES.BASEMAP' | translate"> </app-list-top-card>

  <app-detail-card class="mb3">
    <div class="mb4">
      <app-image-input
        [control]="formGroup.get('baseMap')"
        [width]="375"
        [height]="1203"
        [title]="'OTHER-PAGES.PAGES.BASEMAP' | translate"
      ></app-image-input>
    </div>
  </app-detail-card>

  <app-footer (save)="onSaveClick()" (cancel)="onCancelClick()"></app-footer>
</div>

<app-spinner *ngIf="loading"></app-spinner>
