<div *ngIf="programList">
  <app-list-top-card [title]="'PROGRAM.TITLE' | translate">
    <div class="mr2" [formGroup]="formGroup">
      <mat-form-field
        [class.mat-form-field-responsive]="isSmall"
        appearance="fill"
      >
        <mat-label>{{ "PROGRAM.EVENT.DATE" | translate }}</mat-label>
        <input
          matInput
          [matDatepicker]="picker"
          formControlName="programDate"
        />

        <mat-datepicker-toggle matSuffix [for]="picker">
          <mat-icon matDatepickerToggleIcon>date_range</mat-icon>
        </mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
      </mat-form-field>
    </div>

    <app-search-box
      class="mr2"
      [label]="'PROGRAM.FILTERS.SEARCH' | translate"
      [value]="searchValue"
      (search)="onSearch($event)"
    ></app-search-box>

    <app-button
      [title]="'PROGRAM.ADD' | translate | uppercase"
      [icon]="'add'"
      (click)="createProgram(1)"
    ></app-button>

    <app-button
      *ngIf="hasPermission()"
      [title]="'PROGRAM.RACE.ADD' | translate | uppercase"
      [icon]="'add'"
      class="app-element-margin"
      (click)="createProgram(2)"
    ></app-button>

    <div class="ml2">
      <button
        mat-icon-button
        matTooltip="{{ 'PROGRAM.SORTING.TOOLTIP' | translate }}"
        matTooltipPosition="below"
        matTooltipHideDelay="250"
        (click)="onSortingListClick()"
      >
        <mat-icon>phonelink_setup</mat-icon>
      </button>
    </div>
  </app-list-top-card>

  <app-program-list-table
    [program]="programList"
    [type]="'program'"
    [types]="types"
    [scheduleType]="scheduleType"
    (delete)="onDeleteProgramClick($event)"
  ></app-program-list-table>
</div>

<app-spinner *ngIf="!programList"></app-spinner>
