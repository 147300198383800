<div *ngIf="data" class="mb4">
  <app-back-panel
    [title]="'OTHER-PAGES.PAGES.BACK' | translate"
  ></app-back-panel>
  <app-list-top-card [title]="'OTHER-PAGES.PAGES.TRANSPORT' | translate">
  </app-list-top-card>
  <div class="mb4">
    <app-detail-card class="mb4">
      <mat-tab-group class="app-tab-group">
        <div class="mb4">
          <mat-tab
            class="app-tab-group"
            [label]="'OTHER-PAGES.TRANSPORT.TABS.BUS' | translate | uppercase"
          >
            <div class="mb4 mt4">
              <app-transport-bus
                *ngFor="let bus of getFormArray('bus').controls; let i = index"
                [formGroup]="bus"
                [index]="i"
                (add)="addBusFormGroup()"
                (getIndex)="deleteIndex($event, 'bus')"
                (addConnectionThere)="addConnectionThere($event)"
                (addConnectionBack)="addConnectionBack($event)"
                (deleteConnectionThere)="deleteConnectionThere($event)"
                (deleteConnectionBack)="deleteConnectionBack($event)"
              ></app-transport-bus>
            </div>

            <div (click)="addFormGroup('bus')">
              <app-icon-title
                [title]="'OTHER-PAGES.TRANSPORT.BUS.ADD' | translate"
                icon="add"
              >
              </app-icon-title>
            </div>
          </mat-tab>

          <mat-tab
            class="app-tab-group"
            [label]="
              'OTHER-PAGES.TRANSPORT.TABS.PARKING' | translate | uppercase
            "
          >
            <div class="mb4 mt4">
              <div>
                <app-image-input
                  [control]="formGroup.get('imageParking')"
                  [width]="110"
                  [height]="180"
                  [title]="'OTHER-PAGES.TRANSPORT.PARKING.MAP' | translate"
                ></app-image-input>
              </div>
              <app-transport-parking
                *ngFor="
                  let parking of getFormArray('parking').controls;
                  let i = index
                "
                [formGroup]="parking"
                [index]="i"
                (add)="addFormGroup('parking')"
                (getIndex)="deleteIndex($event, 'parking')"
              ></app-transport-parking>
            </div>

            <div (click)="addFormGroup('parking')">
              <app-icon-title
                [title]="'OTHER-PAGES.TRANSPORT.PARKING.ADD' | translate"
                icon="add"
              >
              </app-icon-title>
            </div>
          </mat-tab>

          <mat-tab
            class="app-tab-group"
            [label]="'OTHER-PAGES.TRANSPORT.TABS.TAXI' | translate | uppercase"
          >
            <div class="mb4 mt4">
              <app-transport-taxi
                *ngFor="
                  let taxi of getFormArray('taxi').controls;
                  let i = index
                "
                [formGroup]="taxi"
                [index]="i"
                (add)="addFormGroup('taxi')"
                (getIndex)="deleteIndex($event, 'taxi')"
              ></app-transport-taxi>
            </div>

            <div (click)="addFormGroup('taxi')">
              <app-icon-title
                [title]="'OTHER-PAGES.TRANSPORT.TAXI.ADD' | translate"
                icon="add"
              >
              </app-icon-title>
            </div>
          </mat-tab>

          <mat-tab
            class="app-tab-group"
            [label]="
              'OTHER-PAGES.TRANSPORT.TABS.PARKING-INFO' | translate | uppercase
            "
          >
            <div class="mb4 mt4">
              <app-transport-parking-info
                *ngFor="
                  let parking of getFormArray('parkingInfo').controls;
                  let i = index
                "
                [formGroup]="parking"
                [index]="i"
                (add)="addFormGroup('parkingInfo')"
                (getIndex)="deleteIndex($event, 'parkingInfo')"
              ></app-transport-parking-info>
            </div>

            <div (click)="addFormGroup('parkingInfo')">
              <app-icon-title
                [title]="'OTHER-PAGES.TRANSPORT.PARKING-INFO.ADD' | translate"
                icon="add"
              >
              </app-icon-title>
            </div>
          </mat-tab>
        </div>
      </mat-tab-group>
    </app-detail-card>
  </div>

  <app-footer
    class="mt4"
    [disabled]="formGroup.invalid"
    (save)="onSaveClick()"
    (cancel)="onCancelClick()"
  ></app-footer>
</div>

<app-spinner *ngIf="!data"></app-spinner>
