<div
  class="app-snackbar full-width-height flex items-center"
  [class.app-snackbar--success]="data?.success"
  [class.app-snackbar--error]="!data?.success"
>
  <div class="mx2">
    <mat-icon
      [class.app-snackbar-success--icon]="data?.success"
      [class.app-snackbar-error--icon]="!data?.success"
      >{{ data.icon }}</mat-icon
    >
  </div>

  <div>{{ data.mainText }}</div>
</div>
