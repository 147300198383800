import { Component, OnInit } from '@angular/core';
import { filterDataBySearchInput } from 'src/app/shared/utils/filter.util';
import { NotificationsService } from '../../services/notifications/notifications.service';
import { INotification } from '../../model/notification/notification.model';
import { Router } from '@angular/router';
import { LanguageService } from 'src/app/shared/services/language/language.service';
import { PROGRESS_DIALOG_ACTION_DELETING } from 'src/app/shared/constants/dialog.constants';
import { ApiCallExtensionService } from 'src/app/shared/services/api-call-extension/api-call-extension.service';
import { TranslateService } from '@ngx-translate/core';
import { marker } from '@biesbjerg/ngx-translate-extract-marker';

@Component({
  selector: 'app-notifications-list',
  templateUrl: './notifications-list.component.html',
  styleUrls: ['./notifications-list.component.scss'],
})
export class NotificationsListComponent implements OnInit {
  notifications: INotification[] = [];
  loading = false;
  searchValue = '';
  readonly columns = ['title', 'dateTime', 'eventTitle', 'edit', 'delete'];

  constructor(
    private notificationsService: NotificationsService,
    private router: Router,
    private languageService: LanguageService,
    private apiCallExtensionService: ApiCallExtensionService,
    private translate: TranslateService
  ) {}

  onSearch(searchInput: string): void {
    this.searchValue = searchInput;
    const activeLanguageCode = this.languageService.getActiveLanguage();
    if (searchInput) {
      this.notificationsService
        .getNotificationList(activeLanguageCode)
        .subscribe((notifications) => {
          this.notifications = filterDataBySearchInput(
            notifications,
            'title',
            searchInput
          );
        });
    } else {
      this.init();
    }
  }

  ngOnInit(): void {
    this.init();
  }

  private init(): void {
    const activeLanguageCode = this.languageService.getActiveLanguage();
    this.loading = true;

    this.notificationsService
      .getNotificationList(activeLanguageCode)
      .subscribe({
        next: (notifications) => {
          this.notifications = notifications;
          this.loading = false;
        },
      });
  }

  createNotification(): void {
    this.router.navigate(['notifications', 'detail', 0]);
  }

  onDeleteNotificationClick(notification: INotification): void {
    if (notification) {
      this.apiCallExtensionService
        .extendApiCall(
          () =>
            this.notificationsService.deleteNotification([notification?.id]),
          this.translate.instant(marker(PROGRESS_DIALOG_ACTION_DELETING))
        )
        .subscribe({
          next: () => {
            this.init();
          },
        });
    }
  }
}
