<div class="app-empty-result-wrapper full-width flex items-center">
  <div class="full-width flex justify-center">
    <div class="">
      <div class="flex justify-center">
        <img class="mb2" src="assets/image/empty.svg" />
      </div>

      <div class="text-large bold">{{ "CORE.EMPTY-RESULT" | translate }}</div>
    </div>
  </div>
</div>
