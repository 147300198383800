<div
  *ngIf="sortedResults.length"
  cdkDropList
  class="app-sorting-list-items"
  (cdkDropListDropped)="drop($event)"
>
  <div
    *ngFor="let result of sortedResults; trackBy: trackResults; index as i"
    class="app-sorting-list-item mb2 p2"
    cdkDrag
  >
    <div class="flex justify-between mb1">
      <div class="flex items-center">
        <mat-icon class="app-sorting-event-result-drag-indicator mr2"
          >drag_indicator</mat-icon
        >

        <div class="bold mr1">{{ i + 1 }}.</div>

        <div class="bold">{{ result.race.name }}</div>
      </div>

      <div>{{ result.race.date | date: "dd.MM.yyyy" }}</div>
    </div>

    <div class="app-sorting-event-result-delimiter"></div>
  </div>
</div>

<div *ngIf="!sortedResults.length">
  <app-empty-result></app-empty-result>
</div>
