import { Component, OnInit } from '@angular/core';
import { GameService } from '../../service/game.service';
import { IGame } from '../../model/game.model';

@Component({
  selector: 'app-game',
  templateUrl: './game.component.html',
  styleUrls: ['./game.component.scss'],
})
export class GameComponent implements OnInit {
  // tslint:disable-next-line: quotemark
  readonly backgroungImagePath = "url('assets/image/game.jpg')";
  game: IGame;
  games: IGame[];
  index = 0;

  settings = {
    start: true,
    question: false,
    answer: false,
  };

  constructor(private gameService: GameService) {}

  ngOnInit(): void {
    this.gameService.getGameItem().subscribe((gameItem) => {
      this.games = gameItem;
      this.game = gameItem[0];
    });
  }

  getAnswerImage(): string {
    return `../../assets/image/${this.game.sport}.svg`;
  }

  change(): void {
    if (this.settings.answer || this.settings.start) {
      if (this.index === this.games.length && this.index !== 0) {
        this.index = 0;
        this.ngOnInit();
      }
      this.game = this.games[this.index++];
    }
    this.settings.start = false;
    this.settings.answer =
      this.settings.question && !this.settings.answer ? true : false;
    this.settings.question =
      !this.settings.question && !this.settings.answer ? true : false;
  }

  cancelGame(): void {
    this.settings.start = true;
    this.settings.question = false;
    this.settings.answer = false;
  }
}
