import { IEvent } from 'src/app/api/model/events.model';

export enum EventCode {
  BedrichovskyNlm = 'BNLM',
  Zadovska30 = 'Z30',
  OrlickyMaraton = 'OM',
  Jizerska50 = 'J50',
  Jelyman = 'JM',
  Krusnohorska30 = 'K30',
  Default = 'default',
}

export interface IEventLogo {
  code: string;
  path: string;
}

export interface IEventVisibility {
  isVisible: boolean;
  event: IEvent;
}
