<div
  *ngIf="events.length"
  cdkDropList
  class="app-sorting-list-items"
  (cdkDropListDropped)="drop($event)"
>
  <div
    *ngFor="let event of events; trackBy: trackResults; index as i"
    class="app-sorting-list-item mb2 p2"
    cdkDrag
  >
    <div class="flex justify-between mb1">
      <div class="flex justify-between full-width">
        <div class="flex items-center">
          <mat-icon class="app-settings-drag-indicator mr2"
            >drag_indicator</mat-icon
          >

          <div class="bold mr1">{{ i + 1 }}.</div>

          <div class="bold">{{ event.name }}</div>
        </div>

        <div>
          <mat-checkbox
            matTooltip="{{ 'SORTING_SETTINGS.SHOW_EVENT.TOOLTIP' | translate }}"
            matTooltipPosition="below"
            matTooltipHideDelay="250"
            [checked]="event.showEvent"
            (click)="$event.stopPropagation()"
            (change)="onVisibilityChange($event.checked, event)"
          >
          </mat-checkbox>
        </div>
      </div>
    </div>

    <div class="app-settings-delimiter"></div>
  </div>
</div>

<div *ngIf="!events.length">
  <app-empty-result></app-empty-result>
</div>
