import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { IEventResult } from 'src/app/api/model/events.model';

@Injectable({
  providedIn: 'root',
})
export class SortingListService {
  private changeEventResultSubject = new Subject<IEventResult[]>();
  changeEventResult = this.changeEventResultSubject.asObservable();

  constructor() {}

  onChangeEventResult(eventResults: IEventResult[]): void {
    this.changeEventResultSubject.next(eventResults);
  }
}
