<div class="mb1">
  <div class="mb1" [formGroup]="formGroup">
    <app-image-input
      [control]="formGroup.get('image')"
      [title]="'PROGRAM.RACE.GALLERY.IMAGE' | translate"
      [width]="375"
      (delete)="deleteImage.emit(index)"
    ></app-image-input>
  </div>
</div>
