import {
  IProgramEventPost,
  IProgramRacePost,
  Program,
} from '../models/program.model';
import { IProgramAttributes } from '../../../shared/model/program-event-attributes.model';

export function prefillProgramAttributes(
  program: IProgramEventPost | IProgramRacePost,
  programAttributes: IProgramAttributes
): IProgramEventPost | IProgramRacePost {
  return programAttributes
    ? {
        ...program,
        stravaLink: {
          ...program.stravaLink,
          cs: programAttributes.stravaLink,
          en: programAttributes.stravaLink,
        },
        fbLink: {
          ...program.fbLink,
          cs: programAttributes.fbLink,
          en: programAttributes.fbLink,
        },

        igLink: {
          ...program.igLink,
          cs: programAttributes.igLink,
          en: programAttributes.igLink,
        },

        mapsLink: {
          ...program.mapsLink,
          cs: programAttributes.mapsLink,
          en: programAttributes.mapsLink,
        },
        dateTimeFrom: new Date(programAttributes.dateTimeFrom),
        dateTimeTo: new Date(programAttributes.dateTimeTo),
      }
    : program;
}

export function getDefaultProgramByType(
  programType: string,
  personType: string
): IProgramEventPost | IProgramRacePost {
  switch (programType) {
    case Program.EVENT_SCHEDULE:
      return {
        about: {
          cs: '',
          en: '',
        },
        active: true,
        dateTimeFrom: new Date(),
        dateTimeTo: new Date(),
        eventId: 0,
        fbLink: {
          cs: '',
          en: '',
        },
        igLink: {
          cs: '',
          en: '',
        },
        stravaLink: {
          cs: '',
          en: '',
        },
        mapsLink: {
          cs: '',
          en: '',
        },
        importantInfoUrl: {
          cs: '',
          en: '',
        },
        id: 0,
        inserted: new Date(),
        place: {
          cs: '',
          en: '',
        },
        sponsorImg: {
          cs: '',
          en: '',
        },
        title: {
          cs: '',
          en: '',
        },
        updated: new Date(),
        type: personType,
      };
    case Program.RACE_SCHEDULE:
      return {
        active: true,
        dateTimeFrom: new Date(),
        dateTimeTo: new Date(),
        eventId: 0,
        fbLink: {
          cs: '',
          en: '',
        },
        igLink: {
          cs: '',
          en: '',
        },
        stravaLink: {
          cs: '',
          en: '',
        },
        mapsLink: {
          cs: '',
          en: '',
        },
        heightProfileImg: {
          cs: '',
          en: '',
        },
        id: 0,
        inserted: new Date(),
        place: {
          cs: '',
          en: '',
        },
        sponsorImg: {
          cs: '',
          en: '',
        },
        title: {
          cs: '',
          en: '',
        },
        updated: new Date(),
        mapImg: {
          cs: '',
          en: '',
        },
        startNumEmission: {
          cs: '',
          en: '',
        },
        startType: {
          cs: '',
          en: '',
        },
        startWaves: {
          cs: '',
          en: '',
        },
        technique: {
          cs: '',
          en: '',
        },
        raceCode: {
          cs: '',
          en: '',
        },
        type: personType,
        urlInfoNumRel: {
          cs: '',
          en: '',
        },
        importantInfoUrl: {
          cs: '',
          en: '',
        },
      };
    default:
      throw new Error(`Not supported type: ${programType}`);
  }
}
