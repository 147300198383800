import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { IEventResult } from 'src/app/api/model/events.model';
import { DialogComponent } from 'src/app/shared/component/dialogs/dialog/dialog.component';
import { ISortingEventResultsDialogData } from '../../models/event-result.model';
import { J50_YEAR_OF_BEGINNING } from '../../constants/event-result.constants';

@Component({
  selector: 'app-sorting-event-results-dialog',
  templateUrl: './sorting-event-results-dialog.component.html',
  styleUrls: ['./sorting-event-results-dialog.component.scss'],
})
export class SortingEventResultsDialogComponent implements OnInit {
  results: IEventResult[] = [];

  constructor(
    public dialogRef: MatDialogRef<DialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ISortingEventResultsDialogData
  ) {}

  ngOnInit(): void {
    this.results = [...this.data.results];
  }

  onResultChange(reorderedResults: IEventResult[]): void {
    this.results = [...reorderedResults];
  }

  onClose(isCanceled = false): void {
    this.dialogRef.close(isCanceled ? null : this.results);
  }

  generateSortingListYearOptions(): number[] {
    const yearOptions: number[] = [];
    const actualYear = new Date().getFullYear();
    for (let i = J50_YEAR_OF_BEGINNING; i <= actualYear; i++) {
      yearOptions.push(i);
    }

    return yearOptions;
  }
}
