<div class="mb4">
  <app-detail-card class="mb4" [formGroup]="formGroup.get('basicContent')">
    <div class="mt2">
      <div class="mb3">
        <mat-form-field appearance="fill" class="app-form-field-full-width">
          <mat-label>{{
            "OTHER-PAGES.ENERVIT.INFO.SUBTITLE" | translate
          }}</mat-label>
          <input formControlName="title" matInput />
        </mat-form-field>
      </div>
    </div>

    <div class="mb4 mt4">
      <app-image-gallery
        *ngFor="let gallery of galleryFormArray.controls; let i = index"
        [formGroup]="gallery"
        [index]="i"
        (add)="addGalleryImage.emit()"
        (deleteImage)="deleteGalleryImage.emit($event)"
      ></app-image-gallery>

      <div>
        <app-add-section-wrapper>
          <div
            class="flex items-center app-gallery-add app-cursor-pointer"
            (click)="addGalleryImage.emit()"
          >
            <mat-icon class="mr1">add</mat-icon>
            {{ "PROGRAM.RACE.GALLERY.ADD" | translate }}
          </div>
        </app-add-section-wrapper>
      </div>
    </div>

    <div class="mb4">
      <mat-label>{{ "PROGRAM.EVENT.ABOUT" | translate }}</mat-label>
      <app-text-editor
        [formGroup]="formGroup.get('basicContent')"
        editorControl="description"
      ></app-text-editor>
    </div>

    <div class="mt4 mb4">
      <div class="mb3">
        <mat-form-field appearance="fill" class="app-form-field-full-width">
          <mat-label>{{ "OTHER-PAGES.EVENT.LINK" | translate }}</mat-label>
          <input formControlName="link" matInput />
        </mat-form-field>
      </div>
    </div>
  </app-detail-card>
</div>
