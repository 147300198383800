import {
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { debounceTime } from 'rxjs/operators';
import { BreakpointObserver } from '@angular/cdk/layout';

@Component({
  selector: 'app-filter-panel',
  templateUrl: './filter-panel.component.html',
  styleUrls: ['./filter-panel.component.scss'],
})
export class FilterPanelComponent implements OnInit {
  searchControl = new FormControl('');
  @Input() title: string;
  @Output() search = new EventEmitter<string>();
  isSmall = false;
  constructor(private breakpointObserver: BreakpointObserver) {}

  ngOnInit(): void {
    this.isSmall = this.breakpointObserver.isMatched('(max-width: 1400px)');
    this.searchControl.valueChanges
      .pipe(debounceTime(500))
      .subscribe((value) => {
        this.search.emit(value);
      });
  }

  @HostListener('window:resize', ['$event'])
  onResize(): void {
    this.isSmall = this.breakpointObserver.isMatched('(max-width: 1400px)');
  }

  restartInput(): void {
    this.searchControl.setValue('');
  }
}
