import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
  OnDestroy,
  AfterViewInit,
  HostListener,
} from '@angular/core';
import { fromEvent, Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { DEBOUNCE_MS } from '../../constants/common.constants';
import { BreakpointObserver } from '@angular/cdk/layout';

@Component({
  selector: 'app-search-box',
  templateUrl: './search-box.component.html',
  styleUrls: ['./search-box.component.scss'],
})
export class SearchBoxComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild('searchInput') inputRef: ElementRef;
  @Input() value: string;
  @Input() label: string;
  @Input() placeholder: string;

  isScreenSmall = false;

  @Output() search = new EventEmitter<string>();

  private subs = new Subscription();

  constructor(private breakpointObserver: BreakpointObserver) {}

  ngOnInit(): void {
    // empty
  }

  ngAfterViewInit(): void {
    this.subs.add(
      fromEvent(this.inputRef.nativeElement, 'input')
        .pipe(debounceTime(DEBOUNCE_MS))
        .subscribe({
          next: (event: Event) => {
            this.onSearchChange(event);
          },
          error: (error: unknown) => {
            console.error(error);
          },
        })
    );
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  @HostListener('window:resize', ['$event'])
  onResize(): void {
    this.isScreenSmall = this.breakpointObserver.isMatched(
      '(max-width: 1400px)'
    );
  }

  onSearchChange(event: Event): void {
    const value = (event.target as HTMLInputElement).value;
    this.search.emit(value);
  }

  clearSearchValue(): void {
    this.search.emit('');
  }
}
