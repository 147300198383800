import { Injectable } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TIME_REGEX } from '../../constants/time.constants';

@Injectable({
  providedIn: 'root',
})
export class ProgramFormGroupBuildersService {
  constructor(private formBuilder: FormBuilder) {}

  createProgramEventDetailImageGalleryFormGroup(): FormGroup {
    return this.formBuilder.group({
      inserted: [''],
      updated: [''],
      id: [''],
      lang: [''],
      orderBy: [''],
      type: [''],
      image: [''],
    });
  }

  createProgramEventDetailFormGroup(): FormGroup {
    return this.formBuilder.group({
      time: this.formBuilder.group({
        timeFrom: ['', [Validators.pattern(TIME_REGEX), Validators.required]],
        timeTo: ['', [Validators.pattern(TIME_REGEX), Validators.required]],
      }),
      gallery: this.formBuilder.array([]),
      event: this.formBuilder.group({
        about: [''],
        active: [''],
        dateTimeFrom: ['', [Validators.required]],
        dateTimeTo: ['', [Validators.required]],
        eventId: [''],
        fbLink: [''],
        importantInfoUrl: [''],
        igLink: [''],
        stravaLink: [''],
        mapsLink: [''],
        id: [''],
        inserted: [''],
        place: [''],
        sponsorImg: [''],
        title: [''],
        updated: [''],
        gpsLat: [''],
        gpsLon: [''],
        type: [''],
      }),
    });
  }

  createProgramRaceDetailFormGroup(): FormGroup {
    return this.formBuilder.group({
      time: this.formBuilder.group({
        timeFrom: ['', [Validators.pattern(TIME_REGEX), Validators.required]],
        timeTo: ['', [Validators.pattern(TIME_REGEX), Validators.required]],
      }),

      gallery: this.formBuilder.array([]),
      basicContentGallery: this.formBuilder.array([]),
      basicContent: this.formBuilder.group({
        inserted: [''],
        updated: [''],
        title: [''],
        id: [0],
        description: [''],
        link: [''],
        raceCode: [''],
        raceScheduleId: [''],
      }),

      race: this.formBuilder.group({
        active: [''],
        dateTimeFrom: ['', [Validators.required]],
        dateTimeTo: ['', [Validators.required]],
        eventId: [''],
        fbLink: [''],
        igLink: [''],
        stravaLink: [''],
        mapsLink: [''],
        heightProfileImg: [''],
        id: [''],
        inserted: [''],
        mapImg: [''],
        place: [''],
        sponsorImg: [''],
        startNumEmission: [''],
        startType: [''],
        startWaves: [''],
        technique: [''],
        title: [''],
        updated: [''],
        raceCode: [''],
        type: [''],
        urlInfoNumRel: [''],
        importantInfoUrl: [''],
      }),
    });
  }
}
