<div *ngIf="!loading">
  <app-back-panel
    [title]="'OTHER-PAGES.PAGES.BACK' | translate"
  ></app-back-panel>
  <app-list-top-card [title]="getPageTitle() | translate"> </app-list-top-card>
  <div class="mb4">
    <app-detail-card class="mb4">
      <div class="mt2">
        <div class="mb3" [formGroup]="formGroup.get('page')">
          <mat-form-field appearance="fill" class="app-form-field-full-width">
            <mat-label>{{
              "OTHER-PAGES.ENERVIT.INFO.SUBTITLE" | translate
            }}</mat-label>
            <input formControlName="title" matInput />
          </mat-form-field>
        </div>
      </div>

      <div class="mb4 mt4">
        <app-image-gallery
          *ngFor="
            let gallery of getFormArray('gallery').controls;
            let i = index
          "
          [formGroup]="gallery"
          [index]="i"
          (add)="addFormGroup('gallery')"
          (deleteImage)="deleteIndex($event, 'gallery')"
        ></app-image-gallery>
        <div>
          <app-add-section-wrapper>
            <div
              class="flex items-center app-gallery-add app-cursor-pointer"
              (click)="addFormGroup('gallery')"
            >
              <mat-icon class="mr1">add</mat-icon>
              {{ "PROGRAM.RACE.GALLERY.ADD" | translate }}
            </div>
          </app-add-section-wrapper>
        </div>
      </div>

      <div class="mb4">
        <mat-label>{{ "PROGRAM.EVENT.ABOUT" | translate }}</mat-label>
        <app-text-editor
          [formGroup]="formGroup.get('page')"
          editorControl="description"
        ></app-text-editor>
      </div>

      <div class="mt4 mb4">
        <div class="mb3" [formGroup]="formGroup.get('page')">
          <mat-form-field appearance="fill" class="app-form-field-full-width">
            <mat-label>{{ "OTHER-PAGES.EVENT.LINK" | translate }}</mat-label>
            <input formControlName="link" matInput />
          </mat-form-field>
        </div>
      </div>
    </app-detail-card>
  </div>

  <app-footer class="mt4" [loading]="loading" (save)="save()"></app-footer>
</div>
<app-spinner *ngIf="loading"></app-spinner>
